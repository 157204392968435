import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  TablePagination,
  FormControl,
  Divider,
} from "@mui/material";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";

import axios from "axios";
import Dialog from "../Components/Dialog/Dialog";

import _ from "lodash";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import SearchIcon from "@mui/icons-material/Search";
import "./TreeviewStyles.css";
import { InputAdornment } from "@mui/material";
import StaticVar from "../../Config/StaticVar";
import { DefaultOption } from "../../Utils/slugify";
import slugify from "slugify";

export default function Sitemap() {
  var classes = useStyles();

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [data, setData] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [title, settitle] = React.useState("");
  const [titleEN, settitleEN] = React.useState("");
  const [baseUrl, setbaseUrl] = React.useState("");
  const [link, setLink] = React.useState("");
  const [category, setcategory] = React.useState("");
  const [index, setIndex] = React.useState(0);
  const [position, setposition] = React.useState("");
  const [parent, setparent] = React.useState("");
  const [dataPages, setDataPages] = React.useState([]);
  const [dataDocument, setDataDocument] = React.useState([]);
  const [dataParent, setDataParent] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  var [openAlert, setopenAlert] = useState(false);
  var [titleAlert, setTitleAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  var [idSiteMap, setIdSiteMap] = useState("");

  const handleClickOpenAdd = () => {
    clearText();
    setOpenModalAdd(true);
  };

  const clearText = () => {
    setIdSiteMap("");
    settitle("");
    settitleEN("");
    setcategory("");
    setLink("");
    setIndex("");
    setbaseUrl("");
    setposition("");
    setparent("");
  };

  function handleClickOpenEdit(
    id,
    title,
    titleEN,
    category,
    parent,
    link,
    baseurl,
    position,
    index,
  ) {
    setIdSiteMap(id);
    settitle(title);
    settitleEN(titleEN);
    setcategory(category);
    setparent(parent);
    setLink(link);
    setbaseUrl(baseurl);
    setposition(position);
    setIndex(index);
    setOpenModalEdit(true);
  }

  function handleClickOpenDelete(id, title) {
    // if (
    //   title === "Beranda" ||
    //   title === "Berita & Galeri" ||
    //   title === "Berita" ||
    //   title === "Galeri Foto" ||
    //   title === "Video dan Live Streming" ||
    //   title === "Pertandingan" ||
    //   title === "Layanan & Dukungan"
    // ) {
    //   setTitleAlert("Maaf Halaman Ini Tidak Bisa Di Hapus !!");
    //   setmessageError("Halaman " + title + " ini adalah halaman default");
    //   setopenAlert(true);
    // } else {
    setIdSiteMap(id);
    settitle(title);
    settitleEN(titleEN);
    setOpenModalDelete(true);
    // }
  }

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalAdd(false);
    setOpenModalEdit(false);
  };

  const getData = async () => {
    let res = await axios.get(url + "/private/sitemap", { headers });
    let response = await res.data;
    setRows(response);
    setData(response);
  };

  const getDataParent = async () => {
    let res = await axios.get(url + "/private/sitemap", { headers });
    let response = await res.data;

    var dataparent = _.filter(response, function (item) {
      return item.parent == "Tanpa Parent";
    });
    setDataParent(dataparent);
  };

  const getDataPages = async () => {
    let res = await axios.get(url + "/private/pages", { headers });
    let response = await res.data;
    setDataPages(response);
  };

  const getDataDocument = async () => {
    let res = await axios.get(url + "/private/document", { headers });
    let response = await res.data;
    setDataDocument(response);
  };

  function postData() {
    let sendData = {
      title: title,
      titleEN: titleEN,
      category: category,
      link: category === "Redirect" ? link : "",
      setDropDown: false,
      base_URL:
        category === "Laman"
          ? "/pages/" + baseUrl
          : category === "Document"
          ? "/document/" + baseUrl
          : category === "Hanya Menu"
          ? baseUrl
          : "",
      parent: parent,
      position: position,
      index: index,
      fullWidth: fullWidth,
    };
    axios
      .post(url + "/private/sitemap/create", sendData, { headers })
      .then((res) => {
        getData();
        setOpenModalAdd(false);
        getDataParent();
        getDataPages();
      });
  }

  //for reload page javascript  window.location.reload();

  function editData(id) {
    let editdata = {
      title: title,
      titleEN,
      category: category,
      link: category === "Redirect" ? link : "",
      setDropDown: false,
      base_URL:
        category === "Laman"
          ? baseUrl
          : category === "Document"
          ? "/document/" + baseUrl
          : category === "Hanya Menu"
          ? baseUrl
          : "",
      parent: parent,
      position: position,
      index: index,
      fullWidth: fullWidth,
    };

    axios
      .put(url + "/private/sitemap/" + id, editdata, { headers })
      .then(() => {
        getData();
        setOpenModalEdit(false);
        getDataParent();
        getDataPages();
      });
  }

  function deleteData(id, title) {
    axios.delete(url + "/private/sitemap/" + id, { headers }).then((res) => {
      getData();
      setOpenModalDelete(false);
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      await getData();
      await getDataParent();
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getDataPages();
      // await getDataDocument();
    };
    fetchData();
  }, []);

  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    const result = data.filter((e) => {
      return e.title.toString().toLowerCase().indexOf(searchQuery) !== -1;
    });
    setRows(result);
    setSearch(searchQuery);
  };

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const klikitem = (id) => {
    const getButton = document.getElementById("btn" + id);
    const getChild = document.getElementById("child" + id);
    console.log(getChild.className);
    getButton.innerText = getButton.innerText === "HIDE" ? "EXPAND" : "HIDE";
    getChild.style =
      getButton.innerText === "HIDE" ? "display:block" : "display:none";
  };

  return (
    <>
      <PageTitle
        title="Peta Situs"
        button="Tambah Peta Situs"
        click={handleClickOpenAdd}
        filter={
          <TextField
            variant="outlined"
            onChange={handleSearch}
            value={search}
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              classes: { input: classes.textSearch },
            }}
          />
        }
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper sx={{ overflowX: "auto" }}>
            <List
              component="nav"
              className={classes.root}
              sx={{ minWidth: "min-content" }}
            >
              {rows
                .filter((x) => x.parent === "Tanpa Parent")
                .map((item) => {
                  var treeDetail = rows.filter((x) => x.parent === item._id);
                  return (
                    <>
                      <ListItem button>
                        {/* <ListItemIcon>
                                  <SendIcon />
                              </ListItemIcon> */}
                        <ListItemText
                          primary={
                            <Box sx={{ mr: 6 }}>
                              <p style={{ marginBottom: 0 }}>
                                Judul : {item.title}
                              </p>
                              <small>Title : {item.titleEN}</small>
                            </Box>
                          }
                        />
                        <ListItemText
                          secondary={
                            <Box sx={{ mr: 6 }}>
                              <p style={{ marginBottom: 0 }}>
                                Posisi : {item.position}
                              </p>
                              <p>Kategori : {item.category}</p>
                            </Box>
                          }
                        />
                        {treeDetail.length > 0 ? (
                          <Button
                            id={"btn" + item._id}
                            onClick={() => klikitem(item._id)}
                          >
                            {" "}
                            <Typography className={classes.txtAction}>
                              Expand
                            </Typography>
                          </Button>
                        ) : null}
                        <Button
                          variant="contained"
                          color="warning"
                          sx={{ ml: 1.5 }}
                          onClick={() =>
                            handleClickOpenEdit(
                              item._id,
                              item.title,
                              item.titleEN,
                              item.category,
                              item.parent,
                              item.link,
                              item.base_URL,
                              item.position,
                              item.index,
                            )
                          }
                        >
                          <Typography className={classes.txtAction}>
                            Edit
                          </Typography>
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ ml: 1.5 }}
                          onClick={() =>
                            handleClickOpenDelete(item._id, item.title)
                          }
                        >
                          <Typography className={classes.txtAction}>
                            Hapus
                          </Typography>
                        </Button>
                      </ListItem>
                      <Divider />
                      {/* <Collapse  timeout="auto" unmountOnExit> */}
                      <div id={"child" + item._id} style={{ display: "none" }}>
                        {treeDetail.map((item2) => {
                          return (
                            <>
                              <List
                                component="div"
                                disablePadding
                                style={{ backgroundColor: "#EFEFEF" }}
                              >
                                <ListItem button className={classes.nested}>
                                  <ListItemIcon>
                                    <StarBorder />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <>
                                        <p style={{ marginBottom: 0 }}>
                                          Judul : {item2.title}
                                        </p>
                                        <small>Title : {item2.titleEN}</small>
                                      </>
                                    }
                                  />

                                  <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() =>
                                      handleClickOpenEdit(
                                        item2._id,
                                        item2.title,
                                        item2.titleEN,
                                        item2.category,
                                        item2.parent,
                                        item2.link,
                                        item2.base_URL,
                                        item2.position,
                                        item2.index,
                                      )
                                    }
                                  >
                                    <Typography className={classes.txtAction}>
                                      Edit
                                    </Typography>
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    sx={{ ml: 1.5 }}
                                    onClick={() =>
                                      handleClickOpenDelete(
                                        item2._id,
                                        item2.title,
                                      )
                                    }
                                  >
                                    <Typography className={classes.txtAction}>
                                      Hapus
                                    </Typography>
                                  </Button>
                                </ListItem>
                              </List>
                              <Divider />
                            </>
                          );
                        })}
                      </div>
                      {/* </Collapse> */}
                    </>
                  );
                })}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* modal add */}
      <Dialog
        open={openModalAdd}
        close={handleClose}
        title={"Form Tambah Peta Situs"}
        content={
          <div style={{ width: 450, height: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Kategori</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={category}
                  onChange={(event) => {
                    setcategory(event.target.value);
                  }}
                >
                  <MenuItem value={"Hanya Menu"}>Hanya Menu</MenuItem>
                  <MenuItem value={"Laman"}>Laman</MenuItem>
                  <MenuItem value={"Redirect"}>Redirect</MenuItem>
                  {/* <MenuItem value={"Document"}>Document</MenuItem> */}
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Parent</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={parent}
                  onChange={(event) => {
                    setparent(event.target.value);
                  }}
                >
                  <MenuItem value={"Tanpa Parent"}>Tanpa Parent</MenuItem>
                  {dataParent.map((item) => (
                    <MenuItem value={item._id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Judul"
                  className={classes.formContainer}
                  margin="normal"
                  value={title}
                  onChange={(event) => {
                    settitle(event.target.value);
                  }}
                  disabled={category === "Laman" ? true : false}
                  name="name"
                />
              </Grid>
              {category === "Laman" ? (
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <InputLabel htmlFor="contributor-helper">
                    Pilih Laman
                  </InputLabel>
                  <Select
                    className={classes.formContainer}
                    value={title}
                    onChange={(event) => {
                      settitle(event.target.value);
                      setbaseUrl(slugify(event.target.value, DefaultOption));
                    }}
                  >
                    {dataPages.map((item) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              ) : null}
              {category === "Document" ? (
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <InputLabel htmlFor="contributor-helper">
                      Pilih Document
                    </InputLabel>
                    <Select
                      className={classes.formContainer}
                      value={baseUrl}
                      onChange={(event) => {
                        setbaseUrl(event.target.value);
                      }}
                    >
                      {dataDocument.map((item) => (
                        <MenuItem value={item.title}>{item.title}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              ) : null}
              {category === "Redirect" ? (
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <TextField
                    label="Base URL atau Link"
                    className={classes.formContainer}
                    margin="normal"
                    value={link}
                    onChange={(event) => {
                      setLink(event.target.value);
                    }}
                    name="name"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <TextField
                    label="Base URL atau Link"
                    className={classes.formContainer}
                    margin="normal"
                    value={baseUrl}
                    onChange={(event) => {
                      setbaseUrl(event.target.value);
                    }}
                    name="name"
                    disabled={category === "Laman" ? true : false}
                  />
                </Grid>
              )}
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Posisi</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={position}
                  onChange={(event) => {
                    setposition(event.target.value);
                  }}
                >
                  <MenuItem value={"Header"}>Header</MenuItem>
                  <MenuItem value={"Top Header"}>Top Header</MenuItem>
                  <MenuItem value={"Footer"}>Footer</MenuItem>
                  <MenuItem value={"Header & Footer"}>Header & Footer</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Urutan"
                  className={classes.formContainer}
                  margin="normal"
                  type="number"
                  value={index}
                  onChange={(event) => {
                    setIndex(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
            </Grid>
          </div>
        }
        cancel={handleClose}
        confirm={() => postData(category)}
        valueConfirm={"Tambah"}
        valueCancel={"Batalkan"}
      />

      {/* modal edit*/}
      <Dialog
        open={openModalEdit}
        close={handleClose}
        title={"Form Edit Peta Situs"}
        content={
          <div style={{ width: 450, height: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Kategori</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={category}
                  onChange={(event) => {
                    setcategory(event.target.value);
                  }}
                >
                  <MenuItem value={"Hanya Menu"}>Hanya Menu</MenuItem>
                  <MenuItem value={"Laman"}>Laman</MenuItem>
                  <MenuItem value={"Redirect"}>Redirect</MenuItem>
                  {/* <MenuItem value={"Document"}>Document</MenuItem> */}
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Parent</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={parent}
                  onChange={(event) => {
                    setparent(event.target.value);
                  }}
                >
                  <MenuItem value={"Tanpa Parent"}>Tanpa Parent</MenuItem>
                  {dataParent.map((item) => (
                    <MenuItem value={item._id}>{item.title}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Judul"
                  className={classes.formContainer}
                  margin="normal"
                  value={title}
                  onChange={(event) => {
                    settitle(event.target.value);
                  }}
                  name="name"
                  disabled={category === "Laman" ? true : false}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Title"
                  className={classes.formContainer}
                  margin="normal"
                  value={titleEN}
                  onChange={(event) => {
                    settitleEN(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
              {category === "Laman" ? (
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <InputLabel htmlFor="contributor-helper">
                    Pilih Laman
                  </InputLabel>
                  <Select
                    className={classes.formContainer}
                    value={title}
                    onChange={(event) => {
                      settitle(event.target.value);
                      setbaseUrl(
                        "/pages/" + slugify(event.target.value, DefaultOption),
                      );
                    }}
                  >
                    {dataPages.map((item) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              ) : null}
              {category === "Document" ? (
                <Grid container>
                  <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <InputLabel htmlFor="contributor-helper">
                      Pilih Document
                    </InputLabel>
                    <Select
                      className={classes.formContainer}
                      value={baseUrl}
                      onChange={(event) => {
                        setbaseUrl(event.target.value);
                      }}
                    >
                      {dataDocument.map((item) => (
                        <MenuItem value={item.title}>{item.title}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              ) : null}
              {category === "Redirect" ? (
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <TextField
                    label="Base URL atau Link"
                    className={classes.formContainer}
                    margin="normal"
                    value={link}
                    onChange={(event) => {
                      setLink(event.target.value);
                    }}
                    name="name"
                  />
                </Grid>
              ) : (
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <TextField
                    label="Base URL atau Link"
                    className={classes.formContainer}
                    margin="normal"
                    value={baseUrl}
                    onChange={(event) => {
                      setbaseUrl(event.target.value);
                    }}
                    name="name"
                    disabled={category === "Laman" ? true : false}
                  />
                </Grid>
              )}
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">Posisi</InputLabel>
                <Select
                  className={classes.formContainer}
                  value={position}
                  onChange={(event) => {
                    setposition(event.target.value);
                  }}
                >
                  <MenuItem value={"Header"}>Header</MenuItem>
                  <MenuItem value={"Footer"}>Footer</MenuItem>
                  <MenuItem value={"Header & Footer"}>Header & Footer</MenuItem>
                  <MenuItem value={"Top Header"}>Top Header</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Urutan"
                  className={classes.formContainer}
                  margin="normal"
                  type="number"
                  value={index}
                  onChange={(event) => {
                    setIndex(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
            </Grid>
          </div>
        }
        cancel={handleClose}
        confirm={() => editData(idSiteMap)}
        valueConfirm={"Edit"}
        valueCancel={"Batalkan"}
      />

      {/* modal delete */}
      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Grid container>
            <Grid item lg={12}>
              <Typography>Halaman dengan Judul : {title}</Typography>
            </Grid>
          </Grid>
        }
        cancel={handleClose}
        confirm={() => deleteData(idSiteMap, title)}
        valueConfirm={"Hapus"}
      />

      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={titleAlert}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
    </>
  );
}
