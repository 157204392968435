import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  formContainer: {
    margin: 0,
    width: "100%",
    marginBottom: 20,
  },
  dense: {
    marginTop: 19,
  },
  txtContentTable: {
    fontWeight: 300,
    fontSize: 15,
    textAlign: "center",
  },
  txtContentTable1: {
    fontWeight: 300,
    fontSize: 15,
    marginLeft: 10,
    marginRight: 10,
  },
  txtAction: {
    textTransform: "none",
    fontWeight: 200,
    fontSize: 15,
  },
  btnAction: {
    width: "100%",
    marginBottom: 10,
  },
  input: {
    display: "none",
  },
  editorToolbar: {
    marginTop: 10,
    width: "-webkit-fill-available",
    backgroundColor: "#ffffff",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    border: "1px solid #a8a8a8",
  },
  containerEditor: {
    backgroundColor: "#ffffff",
  },
  btnUpload: {
    backgroundColor: "gray",
    textTransform: "none",
  },
  containerCard: {
    paddingLeft: 10,
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 20,
  },
  listUser: {
    backgroundColor: "#dbdbdb",
    padding: 0,
    width: 300,
  },
  chooseUserBtn: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "#ffffff",
    border: "1px solid #a2aab8",
  },

  chooseUserTxt: {
    textTransform: "none",
    fontSize: 14,
  },
  txtTitleTable: {
    fontSize: 15,
    fontWeight: 250,
    color: "#545a63",
  },

  loginLoader: {
    marginLeft: theme.spacing(1),
  },

  sizePicture: {
    width: "100%",
  },

  contentPhotos: {
    backgroundColor: "#ffffff",
  },
}));
