import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Box,
  TextField,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
} from "@mui/material";
import Reply from "@mui/icons-material/Reply";
import axios from "axios";

import useStyles from "../Sitemap/styles";
import "../Sitemap/TreeviewStyles.css";

import PageTitle from "../Components/PageTitle/PageTitle";
import Dialog from "../Components/Dialog/Dialog";

import StaticVar from "../../Config/StaticVar";

export default function Sitemap() {
  var classes = useStyles();

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [commentIdsToDelete, setCommentIdsToDelete] = useState({
    lvl1CommentId: null,
    lvl2CommentId: null,
    lvl3CommentId: null,
  });
  const [commentIdsToReply, setCommentIdsToReply] = useState({
    lvl1CommentId: null,
    lvl2CommentId: null,
  });
  const [commentToReply, setCommentToReply] = useState(null);
  const [replyValue, setReplyValue] = useState("");
  const [isFetching, setIsFetching] = useState(true);
  const [isReplying, setIsReplying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalReply, setOpenModalReply] = useState(false);
  const [alert, setAlert] = useState(null);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      await getData();
      setIsFetching(false);
    };
    fetchData();
  }, []);

  function handleClickOpenDelete({
    lvl1CommentId,
    lvl2CommentId,
    lvl3CommentId,
  }) {
    setCommentIdsToDelete({ lvl1CommentId, lvl2CommentId, lvl3CommentId });
    setOpenModalDelete(true);
  }

  function handleClickOpenReply({
    lvl1CommentId,
    lvl2CommentId,
    commentToReply,
  }) {
    setCommentIdsToReply({ lvl1CommentId, lvl2CommentId });
    setOpenModalReply(true);
    setCommentToReply(commentToReply);
  }

  async function getData() {
    let res = await axios.get(url + "/comment", { headers });
    let response = await res.data;
    setRows(response.data);
  }

  async function replyComment({ lvl1CommentId, lvl2CommentId }) {
    setIsReplying(true);

    try {
      if (lvl2CommentId) {
        await axios.post(
          url + "/private/comment/reply_reply",
          {
            id_comment: lvl1CommentId,
            id_reply: lvl2CommentId,
            comment: replyValue,
          },
          { headers },
        );
        await getData();
        return;
      }

      await axios.post(
        url + "/private/comment/reply",
        {
          id_comment: lvl1CommentId,
          comment: replyValue,
        },
        { headers },
      );
      await getData();
    } catch (err) {
      console.log(err);
      setAlert({ title: "Gagal membalas komentar", message: err.message });
    } finally {
      setCommentIdsToReply(null);
      setCommentToReply(null);
      setReplyValue(null);
      setOpenModalReply(false);
      setIsReplying(false);
    }
  }

  async function deleteComment({
    lvl1CommentId,
    lvl2CommentId,
    lvl3CommentId,
  }) {
    setIsDeleting(true);

    try {
      if (lvl3CommentId) {
        await axios.post(
          url + "/private/comment/delete_reply_reply",
          {
            id_comment: lvl1CommentId,
            id_reply: lvl2CommentId,
            id_reply_reply: lvl3CommentId,
          },
          { headers },
        );
        await getData();
        return;
      }

      if (lvl2CommentId) {
        await axios.post(
          url + "/private/comment/delete_reply",
          {
            id_comment: lvl1CommentId,
            id_reply: lvl2CommentId,
          },
          { headers },
        );
        await getData();
        return;
      }

      await axios.post(
        url + "/private/comment/delete",
        {
          id_comment: lvl1CommentId,
        },
        { headers },
      );
      await getData();
    } catch (err) {
      console.log(err);
      setAlert({ title: "Gagal hapus komen", message: err.message });
    } finally {
      setCommentIdsToDelete(null);
      setOpenModalDelete(false);
      setIsDeleting(false);
    }
  }

  function klikitem(id) {
    const getButton = document.getElementById("btn" + id);
    const getChild = document.getElementById("child" + id);
    console.log(getChild.className);
    getButton.innerText = getButton.innerText === "HIDE" ? "EXPAND" : "HIDE";
    getChild.style =
      getButton.innerText === "HIDE" ? "display:block" : "display:none";
  }

  if (isFetching) {
    return (
      <>
        <PageTitle title="Komen" />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
      <PageTitle title="Komen" />

      <Paper sx={{ overflowX: "auto" }}>
        <List className={classes.root} sx={{ minWidth: "min-content", py: 0 }}>
          {rows.map((comment) => {
            return (
              <>
                <ListItem
                  button
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ mr: 6, display: "flex" }}>
                    <ListItemText
                      sx={{ maxWidth: "300px", minWidth: "auto" }}
                      primary={
                        <Box sx={{ mr: 6 }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "grey.500" }}
                          >
                            Judul Berita
                          </Typography>
                          <Typography variant="body1">
                            {comment.newsDetails.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ mt: 2, color: "grey.500" }}
                          >
                            Komentar dari
                          </Typography>
                          <Typography variant="body1">
                            {comment.userDetails.name}
                          </Typography>
                          <Typography variant="body1">
                            {comment.userDetails.email}
                          </Typography>
                        </Box>
                      }
                    />
                    <ListItemText
                      sx={{ maxWidth: "600px", minWidth: "auto" }}
                      primary={
                        <Box sx={{ mr: 6 }}>
                          <Typography
                            variant="body1"
                            sx={{ color: "grey.500" }}
                          >
                            Isi komen
                          </Typography>
                          <Typography variant="body1">
                            {comment.comment}
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {comment.replies.length > 0 ? (
                          <Button
                            id={"btn" + comment._id}
                            onClick={() => klikitem(comment._id)}
                          >
                            <Typography className={classes.txtAction}>
                              Expand
                            </Typography>
                          </Button>
                        ) : null}
                        <Button
                          variant="contained"
                          sx={{ ml: 1.5 }}
                          onClick={() =>
                            handleClickOpenReply({
                              lvl1CommentId: comment._id,
                              commentToReply: comment,
                            })
                          }
                        >
                          <Typography className={classes.txtAction}>
                            Balas
                          </Typography>
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ ml: 1.5 }}
                          onClick={() =>
                            handleClickOpenDelete({
                              lvl1CommentId: comment._id,
                            })
                          }
                        >
                          <Typography className={classes.txtAction}>
                            Hapus
                          </Typography>
                        </Button>
                      </Box>
                    }
                  />
                </ListItem>
                <Divider />
                <div id={"child" + comment._id} style={{ display: "none" }}>
                  {comment.replies.map((commentReply) => {
                    return (
                      <List
                        component="div"
                        disablePadding
                        style={{ backgroundColor: "#EFEFEF" }}
                      >
                        <ListItem
                          button
                          sx={{
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                          }}
                        >
                          <ListItemIcon>
                            <Reply />
                          </ListItemIcon>
                          <Box sx={{ mr: 6, display: "flex" }}>
                            <ListItemText
                              sx={{ maxWidth: "300px", minWidth: "auto" }}
                              primary={
                                <Box sx={{ mr: 6 }}>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "grey.600" }}
                                  >
                                    Balasan dari
                                  </Typography>
                                  <Typography variant="body1">
                                    {commentReply.userDetails.name}
                                  </Typography>
                                  <Typography variant="body1">
                                    {commentReply.userDetails.email}
                                  </Typography>
                                </Box>
                              }
                            />
                            <ListItemText
                              sx={{ maxWidth: "600px", minWidth: "auto" }}
                              primary={
                                <Box sx={{ mr: 6 }}>
                                  <Typography
                                    variant="body1"
                                    sx={{ color: "grey.600" }}
                                  >
                                    Isi komen
                                  </Typography>
                                  <Typography variant="body1">
                                    {commentReply.comment}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Box>
                          <ListItemText
                            primary={
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {commentReply.replies.length > 0 ? (
                                  <Button
                                    id={"btn" + commentReply._id}
                                    onClick={() => klikitem(commentReply._id)}
                                  >
                                    <Typography className={classes.txtAction}>
                                      Expand
                                    </Typography>
                                  </Button>
                                ) : null}
                                <Button
                                  variant="contained"
                                  sx={{ ml: 1.5 }}
                                  onClick={() =>
                                    handleClickOpenReply({
                                      lvl1CommentId: comment._id,
                                      lvl2CommentId: commentReply._id,
                                      commentToReply: commentReply,
                                    })
                                  }
                                >
                                  <Typography className={classes.txtAction}>
                                    Balas
                                  </Typography>
                                </Button>
                                <Button
                                  variant="contained"
                                  color="error"
                                  sx={{ ml: 1.5 }}
                                  onClick={() =>
                                    handleClickOpenDelete({
                                      lvl1CommentId: comment._id,
                                      lvl2CommentId: commentReply._id,
                                    })
                                  }
                                >
                                  <Typography className={classes.txtAction}>
                                    Hapus
                                  </Typography>
                                </Button>
                              </Box>
                            }
                          />
                        </ListItem>
                        <Divider />
                        <div
                          id={"child" + commentReply._id}
                          style={{ display: "none" }}
                        >
                          {commentReply.replies.map((commentReplyReply) => {
                            return (
                              <List
                                component="div"
                                disablePadding
                                sx={{ backgroundColor: "white" }}
                              >
                                <ListItem
                                  button
                                  sx={{
                                    alignItems: "flex-start",
                                    justifyContent: "space-between",
                                    paddingLeft: 9,
                                  }}
                                >
                                  <ListItemIcon>
                                    <Reply />
                                  </ListItemIcon>
                                  <Box sx={{ mr: 6, display: "flex" }}>
                                    <ListItemText
                                      sx={{
                                        maxWidth: "300px",
                                        minWidth: "auto",
                                      }}
                                      primary={
                                        <Box sx={{ mr: 6 }}>
                                          <Typography
                                            variant="body1"
                                            sx={{ color: "grey.500" }}
                                          >
                                            Balasan dari
                                          </Typography>
                                          <Typography variant="body1">
                                            {commentReplyReply.userDetails.name}
                                          </Typography>
                                          <Typography variant="body1">
                                            {
                                              commentReplyReply.userDetails
                                                .email
                                            }
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                    <ListItemText
                                      sx={{
                                        maxWidth: "600px",
                                        minWidth: "auto",
                                      }}
                                      primary={
                                        <Box sx={{ mr: 6 }}>
                                          <Typography
                                            variant="body1"
                                            sx={{ color: "grey.500" }}
                                          >
                                            Isi komen
                                          </Typography>
                                          <Typography variant="body1">
                                            {commentReplyReply.comment}
                                          </Typography>
                                        </Box>
                                      }
                                    />
                                  </Box>
                                  <ListItemText
                                    primary={
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          color="error"
                                          sx={{ ml: 1.5 }}
                                          onClick={() =>
                                            handleClickOpenDelete({
                                              lvl1CommentId: comment._id,
                                              lvl2CommentId: commentReply._id,
                                              lvl3CommentId:
                                                commentReplyReply._id,
                                            })
                                          }
                                        >
                                          <Typography
                                            className={classes.txtAction}
                                          >
                                            Hapus
                                          </Typography>
                                        </Button>
                                      </Box>
                                    }
                                  />
                                </ListItem>
                                <Divider />
                              </List>
                            );
                          })}
                        </div>
                      </List>
                    );
                  })}
                </div>
              </>
            );
          })}
        </List>
      </Paper>

      <Dialog
        open={openModalReply}
        close={() => {
          setOpenModalReply(false);
          setReplyValue("");
        }}
        title={"Balas komentar"}
        content={
          <Box sx={{ width: { xs: "auto", lg: "450px" } }}>
            <Typography sx={{ color: "grey.500" }}>
              Komentar dari {commentToReply?.userDetails.name}
            </Typography>
            <Typography>{commentToReply?.comment}</Typography>
            <TextField
              id="reply-comment-field"
              label="Balasan anda"
              multiline
              rows={4}
              variant="filled"
              fullWidth
              value={replyValue}
              onChange={(e) => setReplyValue(e.target.value)}
              sx={{ mt: 3 }}
            />
          </Box>
        }
        cancel={() => {
          setOpenModalReply(false);
          setReplyValue("");
        }}
        confirm={() => replyComment(commentIdsToReply)}
        valueConfirm={"Balas"}
        valueCancel={"Batal"}
        isConfirming={isReplying}
      />

      <Dialog
        open={openModalDelete}
        close={() => setOpenModalDelete(false)}
        title={"Hapus komen"}
        content={
          <Grid container>
            <Grid item lg={12}>
              <Typography>Apakah anda yakin menghapus komen ini?</Typography>
            </Grid>
          </Grid>
        }
        cancel={() => setOpenModalDelete(false)}
        confirm={() => deleteComment(commentIdsToDelete)}
        valueCancel={"Batal"}
        valueConfirm={"Hapus"}
        isConfirming={isDeleting}
      />

      <Dialog
        open={!!alert}
        close={() => setAlert(null)}
        title={alert?.title}
        content={<Typography>{alert?.message}</Typography>}
        cancel={() => setAlert(null)}
        confirm={() => setAlert(null)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
    </>
  );
}
