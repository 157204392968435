import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  Box,
  CircularProgress,
  Select,
  MenuItem,
  Divider,
  useMediaQuery,
  InputLabel,
  FormControl,
} from "@mui/material";

import TablePagination from "@mui/material/TablePagination";
import SearchIcon from "@mui/icons-material/Search";

// styles
import { useTheme } from "@mui/material/styles";
import useStyles from "./styles";

import { NavLink } from "react-router-dom";
import axios from "axios";

import PageTitle from "../Components/PageTitle/PageTitle";
import checklist from "../../Assets/Images/checklist.png";
import none from "../../Assets/Images/none.png";

import Dialog from "../Components/Dialog/Dialog";
import { InputAdornment } from "@mui/material";
import moment from "moment";
import StaticVar from "../../Config/StaticVar";
import { NewsContext } from "../../Context/NewsContext";

export default function Content(props) {
  const theme = useTheme();
  const { getDataNewsOnPage, newsOnPage } = useContext(NewsContext);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const url = StaticVar.URL_API; //"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [data, setData] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [idNews, setIdNews] = useState("");
  const [titleNews, setTitleNews] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFiltered, setFiltered] = useState(false);
  const [search, setSearch] = useState({ value: "" });
  /** Table Pagination */
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [users, setusers] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [creator, setCreator] = useState("0");

  const [openModalChooseAddNewsType, setOpenModalChooseAddNewsType] =
    useState(false);

  const filterData = (result) => {
    const date = Date.parse(result.created.create_date);
    const inputStart = Date.parse(startDate);
    const inputEnd = Date.parse(endDate);

    if (creator === "1") {
      return date >= inputStart && date <= inputEnd;
    } else {
      return (
        result.created.create_by === creator &&
        date >= inputStart &&
        date <= inputEnd
      );
    }
  };

  const getDataFilter = () => {
    let query = {
      limit: rowsPerPage,
      page: page + 1,
      startDate,
      endDate,
      search: search.value,
    };
    setLoading(true);
    setFiltered(true);
    setPage(0);
    if (creator !== "0") {
      query = {
        ...query,
        created_by: creator,
      };
    }
    getDataNewsOnPage(query);
  };

  useEffect(() => {
    if (isFiltered || search.value !== "") {
      setTotalData(newsOnPage?.totalData);
      setRowsPerPage(newsOnPage?.limit);
      setPage(newsOnPage?.currentPage - 1);
      setLoading(false);
      setRows(newsOnPage?.data);
    }
  }, [newsOnPage]);

  const handleDeletFilter = () => {
    setFiltered(false);
    setCreator("0");
    setEndDate("");
    setStartDate("");
    setPage(0);
    if (search.value !== "") {
      getDataNewsOnPage({
        limit: rowsPerPage,
        page: page + 1,
        search: search.value,
      });
    } else {
      getData();
    }
  };

  function getData(page, limit) {
    setLoading(true);
    var urlnews = page
      ? url + "/private/news?page=" + page + "&limit=" + limit
      : url + "/private/news";
    axios
      .get(urlnews, { headers })
      .then((res) => {
        let response = res.data.data;
        setTotalData(res.data.totalData);
        setRowsPerPage(res.data?.limit);
        setPage(res.data?.currentPage - 1);
        setLoading(false);
        var user_access = localStorage.getItem("user_access");
        var user_id = localStorage.getItem("id");
        var data_response = response;
        var data_table = [];

        if (user_access === "kontributor") {
          data_response.forEach((element) => {
            if (element.created.create_by === user_id) {
              data_table.push(element);
            }
          });
          setRows(data_table);
          setData(data_table);
        } else if (user_access === "editor") {
          axios
            .get(url + "/private/news_categories/editor/" + user_id, {
              headers,
            })
            .then((res) => {
              // console.log("category", res.data);
              var dataCategory = res.data;
              data_response.forEach((element) => {
                dataCategory.forEach((elementCategory) => {
                  if (element.category === elementCategory._id) {
                    data_table.push(element);
                  }
                });
              });
              // console.log('dataTable',JSON.stringify(data_table))
              setRows(data_table);
              setData(data_table);
            });
        } else {
          data_table = data_response;
          // console.log('dataTable',JSON.stringify(data_table))
          setRows(data_table);
          setData(data_table);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getData();
    axios
      .get(url + "/private/users", { headers })
      .then((res) => {
        setusers(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClose = () => {
    setOpenModalDelete(false);
  };
  var classes = useStyles();

  var titleTable = [
    { name: "No" },
    { name: "Foto" },
    { name: "Title" },
    { name: "Kategori" },
    { name: "Redaksi" },
    { name: "Status" },
    { name: "Action" },
  ];

  const handleClickOpen = () => {
    setOpenModalChooseAddNewsType(true);
  };

  function deleteData(id) {
    axios
      .delete(url + "/private/news/" + id, { headers })
      .then(() => {
        setOpenModalDelete(false);
        setRows(rows.filter((item) => item._id !== id));
      })
      .catch((err) => {
        console.log("error delete news", err);
      });
  }

  function handleClickOpenDelete(id, title) {
    setIdNews(id);
    setTitleNews(title);
    setOpenModalDelete(true);
  }

  useEffect(() => {
    setLoading(true);
    if (search.value !== "") {
      setTimeout(() => {
        setPage(0);
        if (isFiltered) {
          getDataNewsOnPage({
            limit: rowsPerPage,
            page: page + 1,
            search: search.value,
            created_by: creator,
            endDate,
            startDate,
          });
        } else {
          getDataNewsOnPage({
            limit: rowsPerPage,
            page: page + 1,
            search: search.value,
          });
        }
      }, 1000);
    } else {
      setPage(0);
      getData();
      setLoading(false);
    }
  }, [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isFiltered && search.value !== "") {
      getDataNewsOnPage({
        limit: rowsPerPage,
        created_by: creator,
        startDate,
        endDate,
        page: newPage + 1,
        search: search.value,
      });
    } else if (!isFiltered && search.value !== "") {
      getDataNewsOnPage({
        limit: rowsPerPage,
        startDate,
        endDate,
        page: newPage + 1,
        search: search.value,
      });
    } else {
      getData(newPage + 1, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (isFiltered && search.value !== "") {
      getDataNewsOnPage({
        limit: +event.target.value,
        created_by: creator,
        startDate,
        endDate,
        page: 1,
        search: search.value,
      });
    } else if (!isFiltered && search.value !== "") {
      getDataNewsOnPage({
        limit: +event.target.value,
        startDate,
        endDate,
        page: 1,
        search: search.value,
      });
    } else {
      getData(1, +event.target.value);
    }
  };

  return (
    <>
      <PageTitle
        title="Konten Berita"
        button="Tambah Konten"
        click={handleClickOpen}
        filter={
          <TextField
            variant="outlined"
            onChange={(e) =>
              setSearch({
                ...search,
                value: (search.value = e.target.value.toString().toLowerCase()),
              })
            }
            value={search.value}
            size="small"
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              classes: { input: classes.textSearch },
            }}
          />
        }
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography>Cari Berdasarkan:</Typography>
          <Box
            sx={{
              mt: { xs: 0, md: 1.5 },
              mb: 3,
              display: "flex",
              alignItems: { md: "center" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography sx={{ mt: { xs: "10px", md: 0 }, mr: { md: 1.5 } }}>
              Dari Tgl
            </Typography>
            {/* <Select style={{marginRight:5}} value={filterdate} onChange={(e)=>setfilterdate(e.target.value)}>
            <MenuItem>Pilih Tanggal</MenuItem>
          </Select> */}
            <TextField
              size="small"
              sx={{ mt: { xs: "10px", md: 0 }, mr: { md: 1.5 } }}
              type="Date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Typography sx={{ mt: { xs: "10px", md: 0 }, mr: { md: 1.5 } }}>
              sampai
            </Typography>
            <TextField
              size="small"
              sx={{ mt: { xs: "10px", md: 0 }, mr: { md: 1.5 } }}
              type="Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <FormControl sx={{ mt: { xs: "10px", md: 0 }, mr: { md: 1.5 } }}>
              <InputLabel id="demo-simple-select-label">
                Pilih Kontributor
              </InputLabel>
              <Select
                disable={
                  localStorage.user_access === "admin" ? "false" : "true"
                }
                value={creator}
                size="small"
                defaultValue="0"
                label="Pilih Kontributor"
                onChange={(e) => setCreator(e.target.value)}
                style={{ minWidth: "250px" }}
              >
                <MenuItem value="0">Semua</MenuItem>
                {users.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.email}>
                      {item?.email}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button
              sx={{ mt: { xs: "10px", md: 0 } }}
              variant="contained"
              color="primary"
              onClick={() => {
                getDataFilter();
              }}
            >
              Cari
            </Button>
            {isFiltered && (
              <Button
                sx={{ mt: { xs: "10px", md: 0 } }}
                variant="text"
                color="error"
                onClick={() => {
                  handleDeletFilter();
                }}
              >
                Hapus Filter
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isSmall ? (
            <Table>
              <TableBody>
                {rows.map((item, index) => {
                  let creator_id =
                    item?.creator.length > 0 ? item?.creator[0]._id : "";
                  return (
                    <TableRow key={index}>
                      <Paper sx={{ padding: "15px", mb: 3 }}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography
                              sx={{ color: "#b1b7b7", fontSize: "11pt" }}
                            >
                              {moment(item.created.create_date).format(
                                "DD-MM-YYYY",
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              align="right"
                              sx={{ color: "#b1b7b7", fontSize: "11pt" }}
                            >
                              {moment(item.created.create_date).format(
                                "HH:mm:ss",
                              )}
                            </Typography>
                          </Grid>
                          <Divider sx={{ width: "100%", my: 1 }} />
                          <Grid item xs={8.5} sx={{ mb: 2 }}>
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: "12pt" }}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              sx={{ color: "#b1b7b7", fontSize: "11pt" }}
                            >
                              {item.news_category[0]?.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={3.5}>
                            <Box
                              sx={{
                                float: "right",
                                padding: "2px 5px",
                                backgroundColor: `${
                                  item.news_status === "Konten Baru"
                                    ? "#caf0f8"
                                    : item.news_status === "Publish"
                                    ? "#d8f3dc"
                                    : "#FFF0F3"
                                }`,
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "8.5pt",
                                  color: `${
                                    item.news_status === "Konten Baru"
                                      ? "#00b4d8"
                                      : item.news_status === "Publish"
                                      ? "#52b788"
                                      : "#FF4D6D"
                                  }`,
                                }}
                              >
                                {item.news_status}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                backgroundColor: "#f8f8f9",
                                display: "flex",
                                justifyContent: "space-between",
                                borderRadius: 2.5,
                                padding: "8px 15px",
                              }}
                            >
                              <Typography
                                sx={{ color: "#9fa6a6", fontSize: "10pt" }}
                              >
                                Kontributor:{" "}
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "inherit",
                                    fontWeight: "bold",
                                    color: "#000000DE",
                                  }}
                                >
                                  {item?.creator?.length > 0
                                    ? item?.creator[0]?.email
                                    : ""}
                                </Typography>
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "8px 15px",
                              }}
                            >
                              <Typography
                                sx={{ color: "#9fa6a6", fontSize: "10pt" }}
                              >
                                Editor:{" "}
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "inherit",
                                    fontWeight: "bold",
                                    color: "#000000DE",
                                  }}
                                >
                                  {item?.editor?.length > 0
                                    ? item?.editor[0]?.email
                                    : ""}
                                </Typography>
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                backgroundColor: "#f8f8f9",
                                display: "flex",
                                justifyContent: "space-between",
                                borderRadius: 2.5,
                                padding: "8px 15px",
                              }}
                            >
                              <Typography
                                sx={{ color: "#9fa6a6", fontSize: "10pt" }}
                              >
                                Redaktor:{" "}
                                <Typography
                                  component="span"
                                  sx={{
                                    fontSize: "inherit",
                                    fontWeight: "bold",
                                    color: "#000000DE",
                                  }}
                                >
                                  {item?.redaktor?.length > 0
                                    ? item?.redaktor[0]?.email
                                    : ""}
                                </Typography>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider sx={{ width: "100%", my: 2 }} />
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography>Aksi</Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  color="warning"
                                  variant="contained"
                                  sx={{ ml: 1 }}
                                  component={NavLink}
                                  to={`/app/news/content/editor-content/${
                                    item?.video ? "video" : "photo"
                                  }/${item._id}`}
                                >
                                  <Typography className={classes.txtAction}>
                                    Edit
                                  </Typography>
                                </Button>
                                {localStorage.getItem("user_access") ===
                                  "editor" ||
                                localStorage.getItem("user_access") ===
                                  "kontributor" ? null : localStorage.getItem(
                                    "user_access",
                                  ) === "redaktor" &&
                                  localStorage.getItem("id") !==
                                    creator_id ? null : (
                                  <Button
                                    color="error"
                                    variant="contained"
                                    sx={{ ml: 1 }}
                                    onClick={() =>
                                      handleClickOpenDelete(
                                        item._id,
                                        item.title,
                                      )
                                    }
                                  >
                                    <Typography className={classes.txtAction}>
                                      Hapus
                                    </Typography>
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Paper sx={{ overflowX: "auto" }}>
              {loading ? (
                <center style={{ padding: 50 }}>
                  <CircularProgress />
                  <Typography>Sedang memuat data...</Typography>
                </center>
              ) : (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: "center", width: "3%" }}>
                          No
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", width: "20%" }}
                        >
                          Media
                        </TableCell>
                        <TableCell style={{ textAlign: "left", width: "40%" }}>
                          Judul
                        </TableCell>
                        <TableCell style={{ textAlign: "left", width: "20%" }}>
                          Redaksi
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", width: "10%" }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center", width: "10%" }}
                        >
                          Aksi
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((item, index) => {
                        let creator_id =
                          item.creator.length > 0 ? item.creator[0]._id : "";
                        return (
                          <TableRow key={index}>
                            <TableCell
                              style={{ textAlign: "center", width: "3%" }}
                            >
                              <Typography className={classes.txtContentTable}>
                                {page * rowsPerPage + (index + 1)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{ textAlign: "center", width: "15%" }}
                            >
                              <img
                                src={url + "/repo/" + item.picture}
                                style={{ width: 160 }}
                              />
                              <br />
                              <small style={{ fontSize: 8 }}>
                                <i>{item.caption}</i>
                              </small>
                            </TableCell>
                            <TableCell style={{ width: "40%" }}>
                              <Typography style={{ fontSize: 20 }}>
                                Judul : {item.title}
                              </Typography>
                              <Typography style={{ fontSize: 16 }}>
                                {item.video ? "Berita Video" : "Berita Foto"}
                              </Typography>
                              <Typography style={{ fontSize: 16 }}>
                                {item.news_category?.name}
                              </Typography>
                              <Typography style={{ fontSize: 12 }}>
                                Dibuat Tgl :{" "}
                                {moment(item.created.create_date).format(
                                  "DD-MM-YYYY HH:mm:ss",
                                )}
                              </Typography>
                            </TableCell>

                            <TableCell style={{ width: "20%" }}>
                              <Typography style={{ fontSize: 13 }}>
                                <small>
                                  Kontributor :{" "}
                                  {item?.creator?.length > 0
                                    ? item?.creator[0]?.email
                                    : ""}
                                </small>{" "}
                              </Typography>
                              {item?.editor ? (
                                <Typography style={{ fontSize: 13 }}>
                                  <small>
                                    Editor :{" "}
                                    {item?.editor?.length > 0
                                      ? item?.editor[0]?.email
                                      : ""}{" "}
                                  </small>
                                </Typography>
                              ) : null}
                              {item?.redaktor ? (
                                <Typography style={{ fontSize: 13 }}>
                                  <small>
                                    Redaktor :{" "}
                                    {item?.redaktor?.length > 0
                                      ? item.redaktor[0]?.email
                                      : ""}
                                  </small>
                                </Typography>
                              ) : null}
                            </TableCell>
                            <TableCell
                              style={{ textAlign: "center", width: "10%" }}
                            >
                              <Typography className={classes.txtContentTable}>
                                {item.news_status}
                              </Typography>
                              {item.news_status === "Highlight" && (
                                <>
                                  <Typography style={{ fontSize: 13 }}>
                                    <small>
                                      <b>Publish: </b>{" "}
                                      {moment(
                                        item.highlighted?.highlight_start,
                                      ).format("lll")}
                                    </small>
                                  </Typography>
                                  <Typography style={{ fontSize: 13 }}>
                                    <small>
                                      <b>unPublish: </b>{" "}
                                      {moment(
                                        item.highlighted?.highlight_end,
                                      ).format("lll")}
                                    </small>
                                  </Typography>
                                </>
                              )}
                            </TableCell>
                            <TableCell
                              style={{ textAlign: "center", width: "10%" }}
                            >
                              {/* <Button className={classes.btnAction} component={NavLink} to={{pathname:"/app/news/content/view-content/"+item._id}}><Typography className={classes.txtAction}>View</Typography></Button> */}
                              <Button
                                color="warning"
                                variant="contained"
                                sx={{ mb: 2, width: 80 }}
                                component={NavLink}
                                to={`/app/news/content/editor-content/${
                                  item?.video ? "video" : "photo"
                                }/${item._id}`}
                              >
                                <Typography className={classes.txtAction}>
                                  Edit
                                </Typography>
                              </Button>
                              {localStorage.getItem("user_access") ===
                                "editor" ||
                              localStorage.getItem("user_access") ===
                                "kontributor" ? null : localStorage.getItem(
                                  "user_access",
                                ) === "redaktor" &&
                                localStorage.getItem("id") !==
                                  creator_id ? null : (
                                <Button
                                  color="error"
                                  variant="contained"
                                  sx={{ width: 80 }}
                                  onClick={() =>
                                    handleClickOpenDelete(item._id, item.title)
                                  }
                                >
                                  <Typography className={classes.txtAction}>
                                    Hapus
                                  </Typography>
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
            </Paper>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalData}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>

      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={<Typography>Berita dengan Judul : {titleNews}</Typography>}
        cancel={handleClose}
        confirm={() => deleteData(idNews)}
        valueConfirm={"Hapus"}
        valueCancel={"Batalkan"}
      />

      {/* modal, choose berita type to add  */}
      <Dialog
        open={openModalChooseAddNewsType}
        close={() => setOpenModalChooseAddNewsType(false)}
        title={"Pilih Tipe Berita"}
        content={
          <Box sx={{ mb: 3 }}>
            <Button
              variant="contained"
              onClick={() =>
                props.history.push("/app/news/content/form-content/photo")
              }
              sx={{ width: "100%", textTransform: "none" }}
            >
              Berita dengan Foto
            </Button>
            <Button
              variant="contained"
              sx={{
                mt: 1.5,
                display: "block",
                width: "100%",
                textTransform: "none",
              }}
              onClick={() =>
                props.history.push("/app/news/content/form-content/video")
              }
            >
              Berita dengan Video
            </Button>
          </Box>
        }
        valueCancel={"Batalkan"}
        cancel={() => setOpenModalChooseAddNewsType(false)}
      />
    </>
  );
}
