import React, { useState, useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";
import { Editor } from "@tinymce/tinymce-react";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  Input,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import axios from "axios";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
// import { Editor } from "react-draft-wysiwyg";
import NoImg from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";
import Api from "../../Services/Api";

export default function Note(props) {
  var classes = useStyles();

  const url = StaticVar.URL_API;

  let token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };
  const [title, settitle] = useState("");
  const [link, setlink] = useState("");
  const [imgPreview, setImgPreview] = useState(NoImg);
  const [img, setImg] = useState("");
  const [value, setValue] = useState([]);
  const [id, setId] = useState({});
  const [isImgValid, setIsImgValid] = useState("");
  const [imgErrorMsg, setImgErrorMsg] = useState("");
  var [editorState, seteditorState] = useState(``);
  var [successAlert, setSuccessAlert] = useState(false);
  var [errorAlert, setErrorAlert] = useState(false);
  var [openAlert, setopenAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      Api.getNote()
        .then((res) => {
          setValue(res.data);
          setId(res.data[0]._id);
          settitle(res.data[0]?.title);
          setlink(res.data[0]?.link);
          seteditorState(res.data[0].content);
          if (res.data[0]?.picture) {
            setImgPreview(url + "/repo/" + res.data[0]?.picture);
            setImg(res.data[0]?.picture);
          }
        })
        .catch((err) => console.log("err", err));
    };
    fetchData();
  }, []);

  function handleEditorChange(value) {
    seteditorState(value);
    console.log("Content was updated:", editorState);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    var options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      reader.onloadend = () => {
        setImg(compressedFile);
        setImgPreview(reader.result);
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <PageTitle title="Catatan" />
      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
      <Snackbar
        open={successAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Data Berhasil disimpan
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Data gagal disimpan
        </Alert>
      </Snackbar>
      <Grid container style={{ paddingLeft: 5, paddingRight: 20 }}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid
              item
              xs={12}
              md={4}
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              <InputLabel htmlFor="contributor-helper">Foto Catatan</InputLabel>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                // ref={imgRef}
                type="file"
                onChange={(e) => handleImage(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="raised"
                  component="span"
                  className={classes.btnUpload}
                  // onClick={handleInputFile}
                >
                  <Typography style={{ color: "#ffffff" }}>
                    File Upload
                  </Typography>
                </Button>
              </label>
              <br />
              <Box
                component="img"
                src={imgPreview}
                alt=""
                sx={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={8} sx={{ mt: { xs: 0, md: 12 } }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    className={classes.formContainer}
                    margin="normal"
                    label="Judul"
                    value={title}
                    name="title"
                    variant="outlined"
                    onChange={(event) => settitle(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="link-field">Link</InputLabel>
                    <OutlinedInput
                      label="Tag"
                      id="link-field"
                      value={link}
                      onChange={(event) => {
                        setlink(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={8} style={{ marginBottom: 20 }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography>Isi Catatan</Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="file-editor"
                multiple
                type="file"
                // onChange={e => handleImage(e)}
              />
              <Box className={classes.editorToolbar}>
                <Editor
                  apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
                  value={editorState}
                  init={{
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype == "image") {
                        var input = document.getElementById("file-editor");
                        input.click();
                        input.onchange = function () {
                          var reader = new FileReader();
                          var file = input.files[0];
                          var options = {
                            maxSizeMB: 0.3,
                            maxWidthOrHeight: 3000,
                            useWebWorker: true,
                          };
                          imageCompression(file, options).then(function (
                            compressedFile,
                          ) {
                            reader.onload = function (e) {
                              callback(e.target.result, {
                                alt: compressedFile.name,
                              });
                            };
                            reader.readAsDataURL(compressedFile);
                          });
                        };
                      }
                    },
                    paste_data_images: true,
                    mobile: true,
                    images_upload_handler: function (
                      blobInfo,
                      success,
                      failure,
                    ) {
                      success(
                        "data:" +
                          blobInfo.blob().type +
                          ";base64," +
                          blobInfo.base64(),
                      );
                    },
                    branding: false,
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                      "searchreplace wordcount visualblocks visualchars code fullscreen",
                      "insertdatetime media table paste code help fullpage",
                      "directionality",
                      "image",
                    ],
                    toolbar:
                      "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help ",
                  }}
                  // onDragDrop={true}
                  // onChange={(e) => handleEditorChange(e)}
                  onEditorChange={(newValue, editor) =>
                    handleEditorChange(newValue, editor)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div style={{ justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              sx={{
                width: 120,
              }}
              onClick={() => {
                props.history.push("/app/news/content");
              }}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              sx={{
                width: 120,
                marginLeft: 1.25,
              }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("title", title);
                sendData.append("picture", img);
                sendData.append("link", link);
                sendData.append("content", editorState);
                if (value?.length > 0) {
                  sendData.append("edited", {
                    _id: localStorage.id,
                    name: localStorage.name,
                    create_date: new Date(),
                  });
                  sendData.append("status", "Telah diedit");
                } else {
                  sendData.append("created", {
                    _id: localStorage.id,
                    name: localStorage.name,
                    create_date: new Date(),
                  });
                  sendData.append("status", "Konten Baru");
                }
                if (title === "") {
                  setopenAlert(true);
                  setmessageError("Data Title wajib diisikan");
                } else if (img === "") {
                  setopenAlert(true);
                  setmessageError("Data Image Foto wajib dimasukkan");
                } else {
                  if (value?.length > 0) {
                    Api.putNote(id, sendData)
                      .then(() => {
                        setSuccessAlert(true);
                      })
                      .catch((err) => {
                        setopenAlert(true);
                        setmessageError(err);
                      });
                  } else {
                    Api.postNote(sendData)
                      .then(() => {
                        setSuccessAlert(true);
                      })
                      .catch((err) => {
                        setopenAlert(true);
                        setmessageError(err);
                      });
                  }
                }
              }}
            >
              Simpan
            </Button>
            {localStorage.getItem("user_access") === "kontributor" ||
            localStorage.getItem("user_access") ===
              "editor" ? null : localStorage.getItem("user_access") ===
                "admin" ||
              localStorage.getItem("user_access") === "redaktor" ? (
              <Button
                variant="contained"
                color="success"
                sx={{
                  width: 120,
                  marginLeft: 1.25,
                }}
                onClick={() => {
                  let sendData = new FormData();
                  sendData.append("title", title);
                  sendData.append("picture", img);
                  sendData.append("link", link);
                  sendData.append("content", editorState);
                  if (value?.length > 0) {
                    sendData.append("edited", {
                      _id: localStorage.id,
                      name: localStorage.name,
                      edit_date: new Date(),
                    });
                    sendData.append("approved", {
                      _id: localStorage.id,
                      name: localStorage.name,
                      approve_date: new Date(),
                    });
                    sendData.append("status", "Publish");
                  } else {
                    sendData.append("created", {
                      _id: localStorage.id,
                      name: localStorage.name,
                      create_date: new Date(),
                    });
                    sendData.append("status", "Konten Baru");
                  }
                  if (title === "") {
                    setopenAlert(true);
                    setmessageError("Data Title wajib diisikan");
                  } else if (img === "") {
                    setopenAlert(true);
                    setmessageError("Data Image Foto wajib dimasukkan");
                  } else {
                    if (value?.length > 0) {
                      Api.putNote(id, sendData)
                        .then(() => {
                          setSuccessAlert(true);
                        })
                        .catch((err) => {
                          setopenAlert(true);
                          setmessageError(err);
                        });
                    } else {
                      Api.postNote(sendData)
                        .then(() => {
                          setSuccessAlert(true);
                        })
                        .catch((err) => {
                          setopenAlert(true);
                          setmessageError(err);
                        });
                    }
                  }
                }}
              >
                Publish
              </Button>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
