import React, { useState, useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  LinearProgress,
  Box,
} from "@mui/material";

import { useDropzone } from "react-dropzone";

import VideoThumbnail from "react-video-thumbnail";
import Dialog from "../Components/Dialog/Dialog";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";

import axios from "axios";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";

import NoImg from "../../Assets/Images/no-image.png";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import StaticVar from "../../Config/StaticVar";

export default function AddVideos(props) {
  var classes = useStyles();

  var [titleVideos, setTitleVideos] = useState("");
  var [caption, setcaption] = useState("");
  var [categoryVideos, setCategoryVideos] = useState("");
  var [linkVideos, setLinkVideos] = useState("");

  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  var [openAlert, setopenAlert] = useState(false);
  var [loading, setLoading] = useState(false);
  var [messageError, setmessageError] = useState("");
  const [type, setType] = React.useState("Link");

  var [imgPreview, setImgPreview] = useState(NoImg);
  var [openModalProgress, setOpenModalProgress] = useState(false);
  var [contentModal, setContentModal] = useState("");
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");

  var [videoPreview, setVideoPreview] = useState(NoImg);
  var [video, setVideo] = useState("");

  const [files, setFiles] = useState([]);

  const [savebutton, setsavebutton] = useState(false);
  const [dataupload, setdataupload] = useState({});

  useEffect(() => {
    getDataCategoryVideos();
    return () => {
      getDataCategoryVideos();
    };
  }, []);

  const url = StaticVar.URL_API; // "http://localhost:301"//"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const getDataCategoryVideos = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== "admin") {
      var status = "contributor";
      if (user_access === "editor") {
        status = "editor";
      }
      if (user_access === "redaktor") {
        status = "redactor";
      }
      let res = await axios.get(
        url + "/private/videos_categories/" + status + "/" + user_id,
        { headers },
      );
      let response = await res.data;
      setDataCategory(response);
    } else {
      let res = await axios.get(url + "/private/videos_categories", {
        headers,
      });
      let response = await res.data;
      setDataCategory(response);
    }
  };

  const [completed, setCompleted] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const [uploadProgress, setUploadProgress] = React.useState(0);

  const progress = React.useRef(() => {});

  function postData() {
    if (titleVideos === "") {
      setopenAlert(true);
      setmessageError("Data Title wajib diisikan");
    } else if (categoryVideos === "") {
      setopenAlert(true);
      setmessageError("Data Kategori wajib diisikan");
    } else if (img === "") {
      setopenAlert(true);
      setmessageError("Data Image Foto wajib dimasukkan");
    } else {
      var data = {
        title: titleVideos,
        link: linkVideos,
        caption: caption,
        thumbnail: dataupload.thumbnail,
        video: dataupload.video,
        category: categoryVideos,
        create_by: localStorage.id,
        approved: [],
        video_status: "Video Baru",
      };
      axios
        .post(url + "/private/videos/create", data, { headers })
        .then(async (res) => {
          console.log(res);
          props.history.push("/app/gallery/videos");
        });
    }
  }

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    var options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result);
      };
      reader.readAsDataURL(compressedFile);

      const formData = new FormData();
      formData.append(
        "thumbnail",
        compressedFile,
        titleVideos.concat("thumbnail.png"),
      );

      axios({
        method: "post",
        url: url + "/private/videos/upload_thumbnail",
        data: formData,
        headers: headers,
        onUploadProgress: function (progressEvent) {
          var dataUploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          console.log("test progress upload", dataUploadProgress);
          setUploadProgress(dataUploadProgress);
        },
      }).then((res) => {
        if (res.data.status === "success") {
          setsavebutton(true);
          setdataupload(res.data.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleClose = () => {
    setOpenModalProgress(false);
  };

  const cancelUpload = () => {
    setFiles([]);
    setOpenModalProgress(false);
  };

  function handleImageThumbnail(e) {
    setOpenModalProgress(false);
    setContentModal("");
    setsavebutton(false);

    var byteString;
    if (e.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(e.split(",")[1]);
    else byteString = unescape(e.split(",")[1]);
    var mimeString = e.split(",")[0].split(":")[1].split(";")[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var data = new Blob([ia], { type: mimeString });
    console.log("data", data);
    setImg(data);

    setOpenModalProgress(true);
    setContentModal("Sedang mengirim data video ke server");

    files.map((file) => {
      const formData = new FormData();
      formData.append("video", file);
      formData.append("thumbnail", data, titleVideos.concat("thumbnail.png"));

      axios({
        method: "post",
        url: url + "/private/videos/upload",
        data: formData,
        headers: headers,
        onUploadProgress: function (progressEvent) {
          var dataUploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          console.log("test progress upload", dataUploadProgress);
          setUploadProgress(dataUploadProgress);
        },
      }).then((res) => {
        if (res.data.status === "success") {
          setContentModal("Upload Selesai");
          setsavebutton(true);
          setdataupload(res.data.data);
        }
      });
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: "video/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      var data = acceptedFiles.map((file) => Object.assign(file));
      console.log("value : ", data[0]);
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  React.useEffect(() => {
    progress.current = () => {
      if (uploadProgress > 100) {
        setCompleted(0);
        setBuffer(0);
      } else {
        const diff = Math.random() * 1;
        const diff2 = Math.random() * 1;
        setCompleted(uploadProgress + diff);
        setBuffer(uploadProgress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    function tick() {
      progress.current();
    }
    const timer = setInterval(tick, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const thumbs = files.map((file, index) => {
    if (file.title !== null && file.category !== null) {
      return (
        <div key={index}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Grid container style={thumb}>
                <Grid item xs={11} style={{ marginBottom: 20 }}>
                  <Typography>Video : </Typography>
                </Grid>
                <Grid item xs={12}>
                  <video controls width="100%">
                    <source src={file.preview} type="video/mp4" />
                    <source src={file.preview} type="video/webm" />
                  </video>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Grid container style={{ marginTop: 10 }}>
                <Grid item xs={10}>
                  <Typography>Thumbnail : </Typography>
                </Grid>
                <Grid item xs={2}>
                  <div style={{ float: "right" }}>
                    <Button
                      onClick={() => setFiles([])}
                      style={{ height: "20%", minWidth: 60, width: 60 }}
                    >
                      <HighlightOffIcon
                        style={{ fontSize: 40, color: "red" }}
                      />
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={thumbnail}>
                    <VideoThumbnail
                      videoUrl={file.preview}
                      thumbnailHandler={(thumbnail) =>
                        handleImageThumbnail(thumbnail)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container>
            {savebutton ? (
              <Grid item xs={3}>
                <div style={{ float: "left" }}>
                  <Button
                    style={{
                      width: 120,
                      border: "2px solid gray",
                      marginBottom: 10,
                      marginRight: 10,
                    }}
                    onClick={() => {
                      props.history.push("/app/videos");
                    }}
                  >
                    Batal
                  </Button>
                  <Button
                    style={{
                      width: 120,
                      border: "2px solid #bf272b",
                      color: "#bf272b",
                      marginBottom: 10,
                    }}
                    onClick={() => postData()}
                  >
                    <Typography className={classes.txtAction}>
                      Tambah
                    </Typography>
                  </Button>
                </div>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <LinearProgress
                  variant="buffer"
                  value={uploadProgress}
                  valueBuffer={buffer}
                  style={{ marginBottom: 10 }}
                />
                <LinearProgress
                  variant="buffer"
                  value={uploadProgress}
                  valueBuffer={buffer}
                />
              </Grid>
            )}
          </Grid> */}
          {/* <CircularProgress size={26} className={classes.loginLoader} /> */}
        </div>
      );
    } else {
      return null;
    }
  });

  return (
    <>
      <PageTitle title="Tambah Video" status={data.news_status} />
      <Grid spacing={4} style={{ paddingBottom: 10 }}>
        <Grid item xs={11} style={{ padding: 0 }}>
          <TextField
            label="Judul"
            className={classes.formContainer}
            margin="normal"
            value={titleVideos}
            onChange={(event) => setTitleVideos(event.target.value)}
            name="titleVideos"
          />
        </Grid>
        <Grid item xs={11} style={{ padding: 0 }}>
          <TextField
            label="Caption"
            className={classes.formContainer}
            margin="normal"
            value={caption}
            onChange={(event) => setcaption(event.target.value)}
            name="Caption"
          />
        </Grid>
        <Grid item xs={11} style={{ padding: 0, marginBottom: "20px" }}>
          <FormControl style={{ width: "100%", marginRight: 10 }}>
            <InputLabel htmlFor="outlined-age-simple">
              Pilih Kategori Video
            </InputLabel>
            <Select
              name="categoryVideos"
              value={categoryVideos}
              onChange={(event) => setCategoryVideos(event.target.value)}
            >
              {/* <MenuItem value={'0'}>Semua</MenuItem> */}
              {dataCategory.map((item) => {
                return <MenuItem value={item._id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>

        {/* =======  */}
        {/* <Grid item xs={11} style={{ padding: 0 }}>
          <FormControl style={{ width: "100%", marginRight: 10 }}>
            <InputLabel htmlFor="contributor-helper">Jenis</InputLabel>
            <Select
              className={classes.formContainer}
              value={type}
              onChange={event => {
                setType(event.target.value);
              }}
            >
              <MenuItem value={"Link"}>Link Video</MenuItem>
              <MenuItem value={"Upload"}>Upload Video</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}
        {type == "Link" ? (
          <>
            <Grid item xs={11}>
              <TextField
                label="Link"
                className={classes.formContainer}
                margin="normal"
                value={linkVideos}
                onChange={(event) => setLinkVideos(event.target.value)}
                name="linkVideos"
              />
            </Grid>
            <Grid item xs={4} style={{ marginTop: 10, marginBottom: 15 }}>
              <InputLabel htmlFor="contributor-helper" sx={{ mb: "4px" }}>
                Thumbnail{" "}
              </InputLabel>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => handleImage(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="raised"
                  component="span"
                  className={classes.btnUpload}
                >
                  <Typography style={{ color: "#ffffff" }}>
                    File Upload
                  </Typography>
                </Button>
              </label>
              <br />
              <Box
                component="img"
                src={imgPreview}
                alt=""
                sx={{ maxWidth: "100%", height: "auto" }}
                mt={2}
              />
            </Grid>
            <Grid item xs={12} mt={6}>
              <div style={{ float: "left" }}>
                <Button
                  style={{
                    width: 120,
                    border: "2px solid gray",
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  onClick={() => {
                    props.history.push("/app/videos");
                  }}
                >
                  Batal
                </Button>
                <Button
                  style={{
                    width: 120,
                    border: "2px solid #bf272b",
                    color: "#bf272b",
                    marginBottom: 10,
                  }}
                  onClick={() => postData()}
                >
                  <Typography className={classes.txtAction}>Tambah</Typography>
                </Button>
              </div>
            </Grid>
          </>
        ) : null}
        {type == "Upload" ? (
          <Grid item xs={11} style={{ padding: 0 }}>
            <Grid container style={{ flexDirection: "row" }} spacing={4}>
              <Grid item xs={12}>
                <div
                  {...getRootProps({ className: "dropzone" })}
                  style={{
                    flexDirection: "row",
                    cursor: "pointer",
                    width: "100%",
                    height: 30,
                    border: "2px solid #e0e0e0",
                    borderRadius: 5,
                  }}
                >
                  <input {...getInputProps()} />
                  <Grid container style={{ paddingLeft: 5 }}>
                    <SaveAltOutlinedIcon />
                    <Typography style={{ marginTop: 2 }}> UPLOAD</Typography>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                {thumbs}
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        <Dialog
          open={openModalProgress}
          // close={handleClose}
          title={"Upload Loading Progress"}
          content={
            <div style={{ width: 350, height: "auto" }}>
              <Grid container>
                {savebutton ? (
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography>{contentModal}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <div style={{ float: "right" }}>
                        <Button
                          style={{
                            width: 80,
                            border: "2px solid gray",
                            marginBottom: 10,
                            marginRight: 10,
                          }}
                          onClick={() => cancelUpload()}
                        >
                          Batal
                        </Button>
                        <Button
                          style={{
                            width: 100,
                            border: "2px solid #bf272b",
                            color: "#bf272b",
                            marginBottom: 10,
                          }}
                          onClick={() => postData()}
                        >
                          <Typography className={classes.txtAction}>
                            Tambah
                          </Typography>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item>
                      <Typography>{contentModal}</Typography>
                    </Grid>
                    <Grid item>
                      <CircularProgress
                        size={30}
                        className={classes.loginLoader}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          }
        />
      </Grid>
    </>
  );
}

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  marginBottom: 8,
  marginTop: 15,
  marginRight: 8,
  flexDirection: "row",
  width: "100%",
};

const thumbnail = {
  borderRadius: 2,
  marginBottom: 20,
  marginRight: 8,
  flexDirection: "row",
  width: "100%",
};
