import React, { useState, useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";
import { Editor } from "@tinymce/tinymce-react";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  Input,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Stack
} from "@mui/material";
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers';
import axios from "axios";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
// import { Editor } from "react-draft-wysiwyg";
import NoImg from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";

export default function AddContent(props) {
  var classes = useStyles();
  const { type } = props.match.params;

  const url = StaticVar.URL_API;

  let token = localStorage.getItem("token");
  const userAcceess = localStorage.getItem("user_access");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [data, setData] = useState([]);
  const [title, settitle] = useState("");
  const [titleEN, settitleEN] = useState("");
  const [dataCategory, setDataCategory] = useState([]);
  const [dataClassification, setDataClassification] = useState([]);
  const [classification, setclassification] = useState("");
  const [highlight, sethighlight] = useState(false);
  const [category, setcategory] = useState("");
  const [tag, settag] = useState("");
  const [imgPreview, setImgPreview] = useState(NoImg);
  const [img, setImg] = useState("");
  const [linkVideo, setLinkVideo] = useState("");
  const [highlightStart, setHighlightStart] = useState(moment(new Date()));
  const [highlightEnd, setHighlightEnd] = useState(moment(new Date()));
  const [isImgValid, setIsImgValid] = useState("");
  const [imgErrorMsg, setImgErrorMsg] = useState("");

  const getDataCategory = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== "admin") {
      var status = "contributor";
      if (user_access === "editor") {
        status = "editor";
      }
      if (user_access === "redaktor") {
        status = "redactor";
      }
      let res = await axios.get(
        url + "/private/news_categories/" + status + "/" + user_id,
        { headers },
      );
      let response = await res.data;
      console.log("response category", response);
      setDataCategory(response);
    } else {
      let res = await axios.get(url + "/private/news_categories", { headers });
      let response = await res.data;
      setDataCategory(response);
    }
  };

  const getDataClassification = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== "admin") {
      var status = "contributor";
      if (user_access === "editor") {
        status = "editor";
      }
      if (user_access === "redaktor") {
        status = "redactor";
      }
      let res = await axios.get(
        url + "/private/news_classification/" + status + "/" + user_id,
        { headers },
      );
      let response = await res.data;
      setDataClassification(response);
    } else {
      let res = await axios.get(url + "/private/news_classification", {
        headers,
      });
      let response = await res.data;
      setDataClassification(response);
    }
  };

  var [editorState, seteditorState] = useState(`<p><span style="font-weight: 400; color: #e03e2d;"><strong>Kabarin.news</strong></span>,&nbsp;</p>`);
  var [editorENState, seteditorENState] = useState("");
  var [openAlert, setopenAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  var [create_date, setcreate_date] = useState("");
  var [caption, setcaption] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      await getDataCategory();
      await getDataClassification();
    };
    fetchData();
  }, []);

  function handleEditorChange(value) {
    seteditorState(value);
    console.log("Content was updated:", editorState);
  }

  function handleEditorENChange(value) {
    seteditorENState(value);
    console.log("Content was updated:", editorENState);
  }

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    var options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result);
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <PageTitle title="Konten Editor" status={"Status : Konten Baru"} />
      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
      <Grid container style={{ paddingLeft: 5, paddingRight: 20 }}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={5} style={{ marginBottom: 10 }}>
            <Grid item xs={12}>
              <TextField
                className={classes.formContainer}
                margin="normal"
                label="Judul Berita"
                value={title}
                name="title"
                variant="outlined"
                onChange={(event) => settitle(event.target.value)}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                className={classes.formContainer}
                margin="normal"
                label="News Title"
                value={titleEN}
                name="title"
                onChange={event => settitleEN(event.target.value)}
              />
            </Grid> */}
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: 40 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="category-field">Kategori</InputLabel>
                <Select
                  label="Kategori"
                  id="category-field"
                  value={category}
                  onChange={(event) => {
                    setcategory(event.target.value);
                  }}
                >
                  <MenuItem value="">Pilih Kategori..</MenuItem>
                  {dataCategory.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="classification-field">
                  Klasifikasi
                </InputLabel>
                <Select
                  label="Klasifikasi"
                  id="classification-field"
                  value={classification}
                  onChange={(event) => {
                    setclassification(event.target.value);
                  }}
                >
                  <MenuItem value="">Pilih Klasifikasi..</MenuItem>
                  {dataClassification.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="highlight-field">
                  Tampil Highlight
                </InputLabel>
                <Select
                  label="Klasifikasi"
                  id="highlight-field"
                  value={highlight}
                  onChange={(event) => {
                    sethighlight(event.target.value);
                  }}
                >
                  <MenuItem value={true}>Ya</MenuItem>
                  <MenuItem value={false}>Tidak</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            {/* {
              highlight && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Stack spacing={3}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="Waktu Publish"
                          value={highlightStart}
                          onChange={(newValue) => {
                            setHighlightStart(newValue);
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4}>
                      <Stack spacing={3}>
                        <DateTimePicker
                          renderInput={(props) => <TextField {...props} />}
                          label="Waktu unPublish"
                          value={highlightEnd}
                          onChange={(newValue) => {
                            setHighlightEnd(newValue);
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              )
            } */}

            {
              type === "video" && (
                <Grid item xs={12}>
                  <TextField
                    className={classes.formContainer}
                    margin="normal"
                    label="Link Video"
                    value={linkVideo}
                    name="link-video"
                    variant="outlined"
                    onChange={(event) => setLinkVideo(event.target.value)}
                  />
                </Grid>
              )
            }
          </Grid>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid
              item
              xs={12}
              md={4}
              style={{ marginTop: 15, marginBottom: 15 }}
            >
              <InputLabel htmlFor="contributor-helper">{{ "photo": "Foto Berita", "video": "Thumbnail Video" }[type]}</InputLabel>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                // ref={imgRef}
                type="file"
                onChange={(e) => handleImage(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="raised"
                  component="span"
                  className={classes.btnUpload}
                // onClick={handleInputFile}
                >
                  <Typography style={{ color: "#ffffff" }}>
                    File Upload
                  </Typography>
                </Button>
              </label>
              <br />
              <Box
                component="img"
                src={imgPreview}
                alt=""
                sx={{ maxWidth: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} md={8} sx={{ mt: { xs: 0, md: 12 } }}>
              <Grid container>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <TextField
                    className={classes.formContainer}
                    margin="normal"
                    label="Caption Foto"
                    value={caption}
                    name="Caption Foto"
                    onChange={(event) => setcaption(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="tag-field">Tag</InputLabel>
                    <OutlinedInput
                      label="Tag"
                      id="tag-field"
                      value={tag}
                      onChange={(event) => {
                        settag(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={8} style={{ marginBottom: 20 }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography>Isi Berita</Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="file-editor"
                multiple
                type="file"
              // onChange={e => handleImage(e)}
              />
              <Box className={classes.editorToolbar}>
                <Editor
                  apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
                  value={editorState}
                  init={{
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype == "image") {
                        var input = document.getElementById("file-editor");
                        input.click();
                        input.onchange = function () {
                          var reader = new FileReader();
                          var file = input.files[0];
                          var options = {
                            maxSizeMB: 0.3,
                            maxWidthOrHeight: 3000,
                            useWebWorker: true,
                          };
                          imageCompression(file, options).then(function (
                            compressedFile,
                          ) {
                            reader.onload = function (e) {
                              callback(e.target.result, {
                                alt: compressedFile.name,
                              });
                            };
                            reader.readAsDataURL(compressedFile);
                          });
                        };
                      }
                    },
                    paste_data_images: true,
                    mobile: true,
                    images_upload_handler: function (
                      blobInfo,
                      success,
                      failure,
                    ) {
                      success(
                        "data:" +
                        blobInfo.blob().type +
                        ";base64," +
                        blobInfo.base64(),
                      );
                    },
                    branding: false,
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                      "searchreplace wordcount visualblocks visualchars code fullscreen",
                      "insertdatetime media table paste code help fullpage",
                      "directionality",
                      "image",
                    ],
                    toolbar:
                      "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help ",
                  }}
                  // onDragDrop={true}
                  // onChange={(e) => handleEditorChange(e)}
                  onEditorChange={(newValue, editor) =>
                    handleEditorChange(newValue, editor)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div style={{ float: "right" }}>
            <Button
              variant="outlined"
              sx={{
                width: 120,
              }}
              onClick={() => {
                props.history.push("/app/news/content");
              }}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              sx={{
                width: 120,
                marginLeft: 1.25,
              }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("title", title);
                sendData.append("titleEN", titleEN);
                sendData.append("category", category);
                sendData.append("classification", classification);
                sendData.append("highlight", highlight);
                if (highlight) {
                  sendData.append("highlight_start", highlightStart);
                  sendData.append("highlight_end", highlightEnd);
                }
                sendData.append("caption", caption);
                sendData.append("picture", img);
                sendData.append("news_tag", tag);
                sendData.append("content", editorState);
                sendData.append("contentEN", editorENState);
                sendData.append("news_status", highlight ? "Highlight" : "Konten Baru");
                sendData.append("create_by", localStorage.id);
                if (type === "video") {
                  sendData.append("video", linkVideo);
                }
                if (title === "") {
                  setopenAlert(true);
                  setmessageError("Data Title wajib diisikan");
                } else if (category === "") {
                  setopenAlert(true);
                  setmessageError(
                    "Data Kategori wajib diisikan! \n Jika kategori kosong, harap tambahkan kategori lebih dulu",
                  );
                } else if (img === "") {
                  setopenAlert(true);
                  setmessageError("Data Image Foto wajib dimasukkan");
                } else if (moment(highlightStart).format() >= moment(highlightEnd).format() && highlight) {
                  setopenAlert(true);
                  setmessageError("Waktu publish tidak dapat melebihi / sama dengan waktu unpublish");
                } else {
                  
                console.log(sendData);
                  axios({
                    method: "post",
                    url: url + "/private/news/create",
                    data: sendData,
                    headers: headers,
                    onUploadProgress: function (progressEvent) {
                      var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                      );
                      console.log("test progress upload", percentCompleted);
                    },
                  })
                    .then(() => {
                      props.history.push("/app/news/content");
                    })
                    .catch((err) => {
                      setopenAlert(true);
                      setmessageError(err);
                    });
                }
              }}
            >
              Simpan
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
