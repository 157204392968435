import React, { useState, useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import imageCompression from "browser-image-compression";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Stack,
} from "@mui/material";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import axios from "axios";

import tinymce from "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/code";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
import NoImg from "../../Assets/Images/no-image.png";
// import draftToHtml from 'draftjs-to-html';
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";

export default function EditorContent(props) {
  var classes = useStyles();
  const { type } = props.match.params;
  const url = StaticVar.URL_API;
  const id = props.match.params._id;

  let token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  let [data, setData] = useState([]);
  let [title, settitle] = useState("");
  let [titleEN, settitleEN] = useState("");
  let [status, setStatus] = useState("");
  const [dataCategory, setDataCategory] = useState([]);
  const [dataClassification, setDataClassification] = useState([]);
  var [classification, setclassification] = useState("");
  var [highlight, sethighlight] = useState(false);
  const [highlightStart, setHighlightStart] = useState();
  const [highlightEnd, setHighlightEnd] = useState();
  var [category, setcategory] = useState("");
  var [tag, settag] = useState("");
  var [imgPreview, setImgPreview] = useState(NoImg);
  var [img, setImg] = useState("");
  var [linkVideo, setLinkVideo] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");
  var [openAlert, setopenAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  var [editorState, seteditorState] = useState("");
  var [editorENState, seteditorENState] = useState("");
  var [editorValue, seteditorValue] = useState("");
  var [editorENValue, seteditorENValue] = useState("");
  var [caption, setcaption] = useState("");
  const [testing, setTesting] = useState([]);

  const getDataCategory = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== "admin") {
      var status = "contributor";
      if (user_access === "editor") {
        status = "editor";
      }
      if (user_access === "redaktor") {
        status = "redactor";
      }
      let res = await axios.get(
        url + "/private/news_categories/" + status + "/" + user_id,
        { headers },
      );
      let response = await res.data;
      setDataCategory(response);
    } else {
      let res = await axios.get(url + "/private/news_categories", { headers });
      let response = await res.data;
      setDataCategory(response);
    }
  };

  const getDataClassification = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== "admin") {
      var status = "contributor";
      if (user_access === "editor") {
        status = "editor";
      }
      if (user_access === "redaktor") {
        status = "redactor";
      }
      let res = await axios.get(
        url + "/private/news_classification/" + status + "/" + user_id,
        { headers },
      );
      let response = await res.data;
      setDataClassification(response);
    } else {
      let res = await axios.get(url + "/private/news_classification", {
        headers,
      });
      let response = await res.data;
      setDataClassification(response);
    }
  };

  async function getData() {
    let res = await axios.get(url + "/private/news/" + id, { headers });
    let response = await res.data[0];
    console.log("response", response);
    setcategory(response?.category);
    setclassification(response?.classification);
    sethighlight(response?.highlight);
    setHighlightEnd(
      moment(response?.highlighted?.highlight_end) || moment(new Date()),
    );
    setHighlightStart(
      moment(response?.highlighted?.highlight_start) || moment(new Date()),
    );
    setImgPreview(url + "/repo/" + response?.picture);
    if (type === "video") {
      setLinkVideo(response?.video);
    }
    settitle(response?.title);
    settitleEN(response?.titleEN);
    setcaption(response?.caption);
    setStatus(response?.news_status);
    settag(response?.news_tag.join(" "));

    const datasample = response?.content;
    console.log("content", datasample);
    seteditorState(datasample);
    seteditorValue(datasample);
    const datasampleEN = response?.contentEN;
    console.log("contentEN", datasampleEN);
    seteditorENState(datasampleEN);
    seteditorENValue(datasampleEN);
  }

  useEffect(() => {
    const fetchData = async () => {
      await getDataCategory();
      await getDataClassification();
      await getData();
    };
    fetchData();
  }, []);

  function handleEditorChange(value, editor) {
    seteditorState(value);
    console.log("Content was updated:", editorState);
  }

  function handleEditorENChange(value) {
    seteditorENState(value);
    console.log("Content was updated:", editorState);
  }

  function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    reader.onloadend = () => {
      setIsImgValid(false);
      setImgErrorMsg("");
      setImg(file);
      setImgPreview(reader.result);
    };

    reader.readAsDataURL(file);
  }

  // const refEditor = useRef(null)
  // const refEditor = useRef(null)

  return (
    <>
      <PageTitle title="Konten Editor" status={"Status : " + status} />

      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
      <Grid container style={{ paddingLeft: 5, paddingRight: 20 }}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={5} style={{ marginBottom: 10 }}>
            <Grid item xs={12}>
              <TextField
                className={classes.formContainer}
                margin="normal"
                label="Judul Berita"
                value={title}
                name="title"
                onChange={(event) => settitle(event.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginBottom: 40 }}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="classification-field">
                  Klasifikasi
                </InputLabel>
                <Select
                  label="Klasifikasi"
                  id="classification-field"
                  value={classification}
                  onChange={(event) => {
                    setclassification(event.target.value);
                  }}
                >
                  <MenuItem value="">Pilih Klasifikasi..</MenuItem>
                  {dataClassification.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="category-field">Kategori</InputLabel>
                <Select
                  label="Kategori"
                  id="category-field"
                  value={category}
                  onChange={(event) => {
                    setcategory(event.target.value);
                  }}
                >
                  <MenuItem value="">Pilih Kategori..</MenuItem>
                  {dataCategory.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="highlight-field">
                  Tampil Highlight
                </InputLabel>
                <Select
                  label="Klasifikasi"
                  id="highlight-field"
                  value={highlight}
                  onChange={(event) => {
                    sethighlight(event.target.value);
                  }}
                >
                  <MenuItem value={true}>Ya</MenuItem>
                  <MenuItem value={false}>Tidak</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {highlight && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Stack spacing={3}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Tanggal Mulai Highlight"
                        value={highlightStart}
                        onChange={(newValue) => {
                          setHighlightStart(newValue);
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={3}>
                      <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Tanggal Selesai Highlight"
                        value={highlightEnd}
                        onChange={(newValue) => {
                          setHighlightEnd(newValue);
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            )} */}

            {type === "video" && (
              <Grid item xs={12}>
                <TextField
                  className={classes.formContainer}
                  margin="normal"
                  label="Link Video"
                  value={linkVideo}
                  name="link-video"
                  variant="outlined"
                  onChange={(event) => setLinkVideo(event.target.value)}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={4} style={{ marginTop: 15, marginBottom: 15 }}>
              <InputLabel htmlFor="contributor-helper">
                {{ photo: "Foto Berita", video: "Thumbnail Video" }[type]}
              </InputLabel>
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                // ref={imgRef}
                type="file"
                onChange={(e) => handleImage(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="raised"
                  component="span"
                  className={classes.btnUpload}
                  // onClick={handleInputFile}
                >
                  <Typography style={{ color: "#ffffff" }}>
                    File Upload
                  </Typography>
                </Button>
              </label>
              <br />
              <img src={imgPreview} width="100%" height={200} />
            </Grid>
            <Grid item xs={8} sx={{ mt: 12 }}>
              <Grid container>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <TextField
                    className={classes.formContainer}
                    margin="normal"
                    label="Caption Foto"
                    value={caption}
                    name="Caption Foto"
                    onChange={(event) => setcaption(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="tag-field">Tag</InputLabel>
                    <OutlinedInput
                      label="Tag"
                      id="tag-field"
                      value={tag}
                      onChange={(event) => {
                        settag(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Grid container spacing={5}>
            <Grid item md={12} xs={12}>
              <Typography>Isi Berita</Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="file-editor"
                multiple
                type="file"
                // onChange={e => handleImage(e)}
              />
              <Box className={classes.editorToolbar}>
                <Editor
                  apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
                  value={editorState}
                  init={{
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype == "image") {
                        var input = document.getElementById("file-editor");
                        input.click();
                        input.onchange = function () {
                          var reader = new FileReader();
                          var file = input.files[0];
                          var options = {
                            maxSizeMB: 0.3,
                            maxWidthOrHeight: 3000,
                            useWebWorker: true,
                          };
                          imageCompression(file, options).then(function (
                            compressedFile,
                          ) {
                            reader.onload = function (e) {
                              callback(e.target.result, {
                                alt: compressedFile.name,
                              });
                            };
                            reader.readAsDataURL(compressedFile);
                          });
                        };
                      }
                    },
                    paste_data_images: true,
                    mobile: true,
                    images_upload_handler: function (
                      blobInfo,
                      success,
                      failure,
                    ) {
                      success(
                        "data:" +
                          blobInfo.blob().type +
                          ";base64," +
                          blobInfo.base64(),
                      );
                    },
                    branding: false,
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                      "searchreplace wordcount visualblocks visualchars code fullscreen",
                      "insertdatetime media table paste code help fullpage",
                      "directionality",
                      "image",
                    ],
                    toolbar:
                      "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help ",
                  }}
                  // onDragDrop={true}
                  // onChange={(e) => handleEditorChange(e)}
                  onEditorChange={(newValue, editor) =>
                    handleEditorChange(newValue, editor)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div style={{ float: "right" }}>
            <Button
              variant="outlined"
              sx={{ mr: 1.25, mb: 1.25 }}
              onClick={() => {
                props.history.push("/app/news/content");
              }}
            >
              <Typography className={classes.txtAction}>Batal</Typography>
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 1.25, mb: 1.25 }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("title", title);
                sendData.append("titleEN", titleEN);
                sendData.append("category", category);
                sendData.append("classification", classification);
                sendData.append("highlight", highlight);
                if (highlight) {
                  sendData.append("highlight_start", highlightStart);
                  sendData.append("highlight_end", highlightEnd);
                }
                sendData.append("caption", caption);
                sendData.append("news_tag", tag);
                sendData.append("picture", img);
                sendData.append("content", editorState);
                sendData.append("contentEN", editorENState);
                sendData.append(
                  "news_status",
                  highlight ? "Highlight" : status,
                );
                if (type === "video") {
                  sendData.append("video", linkVideo);
                }

                if (title === "") {
                  setopenAlert(true);
                  setmessageError("Data Title wajib diisikan");
                } else if (
                  moment(highlightStart).format() >=
                    moment(highlightEnd).format() &&
                  highlight
                ) {
                  setopenAlert(true);
                  setmessageError(
                    "Waktu publish tidak dapat melebihi / sama dengan waktu unpublish",
                  );
                } else {
                  axios
                    .put(url + "/private/news/" + id, sendData, { headers })
                    .then(() => {
                      props.history.push("/app/news/content");
                    });
                }
              }}
            >
              <Typography className={classes.txtAction}>
                Simpan Konten
              </Typography>
            </Button>
            {localStorage.getItem("user_access") === "kontributor" ? null : (
              <Button
                variant="contained"
                color="success"
                sx={{ mr: 1.25, mb: 1.25 }}
                onClick={() => {
                  let sendData = new FormData();
                  sendData.append("title", title);
                  sendData.append("titleEN", titleEN);
                  sendData.append("category", category);
                  sendData.append("classification", classification);
                  sendData.append("highlight", highlight);
                  if (highlight) {
                    sendData.append("highlight_start", highlightStart);
                    sendData.append("highlight_end", highlightEnd);
                  }
                  sendData.append("caption", caption);
                  sendData.append("news_tag", tag);
                  sendData.append("picture", img);
                  sendData.append("content", editorState);
                  sendData.append("contentEN", editorENState);
                  sendData.append("edit_by", localStorage.id);
                  sendData.append(
                    "news_status",
                    highlight ? "Highlight" : "Telah diedit",
                  );
                  if (type === "video") {
                    sendData.append("video", linkVideo);
                  }

                  if (title === "") {
                    setopenAlert(true);
                    setmessageError("Data Title wajib diisikan");
                  } else if (
                    moment(highlightStart).format() >=
                      moment(highlightEnd).format() &&
                    highlight
                  ) {
                    setopenAlert(true);
                    setmessageError(
                      "Waktu publish tidak dapat melebihi / sama dengan waktu unpublish",
                    );
                  } else {
                    axios
                      .put(url + "/private/news/" + id, sendData, { headers })
                      .then(() => {
                        props.history.push("/app/news/content");
                      });
                  }
                }}
              >
                <Typography className={classes.txtAction}>
                  Submit Editor
                </Typography>
              </Button>
            )}
            {localStorage.getItem("user_access") === "kontributor" ||
            localStorage.getItem("user_access") ===
              "editor" ? null : (localStorage.getItem("user_access") ===
                "admin" ||
                localStorage.getItem("user_access") === "redaktor") &&
              !highlight ? (
              <Button
                variant="outlined"
                color="success"
                sx={{ mb: 1.25 }}
                onClick={() => {
                  let sendData = new FormData();
                  sendData.append("approve_by", localStorage.id);
                  sendData.append("title", title);
                  sendData.append("titleEN", titleEN);
                  sendData.append("category", category);
                  sendData.append("classification", classification);
                  sendData.append("highlight", highlight);
                  if (highlight) {
                    sendData.append("highlight_start", highlightStart);
                    sendData.append("highlight_end", highlightEnd);
                  }
                  sendData.append("caption", caption);
                  sendData.append("news_tag", tag);
                  sendData.append("content", editorState);
                  sendData.append("contentEN", editorENState);
                  sendData.append("news_status", "Publish");
                  if (type === "video") {
                    sendData.append("video", linkVideo);
                  }
                  if (title === "") {
                    setopenAlert(true);
                    setmessageError("Data Title wajib diisikan");
                  } else if (
                    moment(highlightStart).format() >=
                      moment(highlightEnd).format() &&
                    highlight
                  ) {
                    setopenAlert(true);
                    setmessageError(
                      "Waktu publish tidak dapat melebihi / sama dengan waktu unpublish",
                    );
                  } else {
                    axios
                      .put(url + "/private/news/" + id, sendData, { headers })
                      .then(() => {
                        props.history.push("/app/news/content");
                      });
                  }
                }}
              >
                <Typography className={classes.txtAction}>Publish</Typography>
              </Button>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
