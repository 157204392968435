import React, { useState, useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";
import { Editor } from "@tinymce/tinymce-react";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import axios from "axios";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
// import { Editor } from "react-draft-wysiwyg";
import NoImg from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";

export default function AddContent(props) {
  var classes = useStyles();

  const url = StaticVar.URL_API;

  let token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  let [data, setData] = useState([]);
  let [title, settitle] = useState("");
  let [titleEN, settitleEN] = useState("");
  const [dataCategory, setDataCategory] = useState([]);
  var [category, setcategory] = useState("");
  var [tag, settag] = useState("");
  var [imgPreview, setImgPreview] = useState(NoImg);
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");

  const getDataCategory = async () => {
    var user_access = localStorage.getItem("user_access");
    var user_id = localStorage.getItem("id");
    if (user_access !== "admin") {
      var status = "contributor";
      if (user_access === "editor") {
        status = "editor";
      }
      if (user_access === "redaktor") {
        status = "redactor";
      }
      let res = await axios.get(url + "/private/articles_categories/" + status + "/" + user_id,{ headers },);
      let response = await res.data;
      console.log('response category', response)
      setDataCategory(response);
    } else {
      let res = await axios.get(url + "/private/articles_categories", { headers });
      let response = await res.data;
      setDataCategory(response);
    }
  };

  var [editorState, seteditorState] = useState("");
  var [editorENState, seteditorENState] = useState("");
  var [openAlert, setopenAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  var [caption, setcaption] = useState("");
  useEffect(() => {
    getDataCategory();
    //getData();
    return () => {
      getDataCategory();
      //getData();
    };
  }, []);

  // const imgRef = useRef(null);

  // function handleInputFile() {
  //   imgRef.current.click();
  // }

  function handleEditorChange(value) {
    seteditorState(value);
    console.log("Content was updated:", editorState);
  }

  function handleEditorENChange(value) {
    seteditorENState(value);
    console.log("Content was updated:", editorENState);
  }

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    var options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result);
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  console.log("Data Artikel bidang ", dataCategory)

  return (
    <>
      <PageTitle title="Konten Editor" status={"Status : Konten Baru"} />
      <Dialog
        open={openAlert}
        close={() => setopenAlert(false)}
        title={"Periksa Data isian Anda ??"}
        content={<Typography>{messageError}</Typography>}
        cancel={() => setopenAlert(false)}
        confirm={() => setopenAlert(false)}
        valueConfirm={"Oke"}
        valueCancel={"Tutup"}
      />
      <Grid container style={{ paddingLeft: 5, paddingRight: 20 }}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Grid container spacing={5} style={{ marginBottom: 10 }}>
            <Grid item xs={12}>
              <TextField
                className={classes.formContainer}
                margin="normal"
                label="Judul Artikel"
                value={title}
                name="title"
                onChange={event => settitle(event.target.value)}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <TextField
                className={classes.formContainer}
                margin="normal"
                label="articles Title"
                value={titleEN}
                name="title"
                onChange={event => settitleEN(event.target.value)}
              />
            </Grid> */}
          </Grid>
          <Grid container spacing={4} style={{ marginBottom: 40 }}>
            <Grid item xs={6}>
              {/* <FormControl style={{ width: "100%" }}>
                <InputLabel
                  htmlFor="outlined-age-simple"
                  style={{ marginBottom: 30 }}
                >
                  Bidang
                </InputLabel>
                <Select
                  name="category"
                  value={category}
                  onChange={event => {
                    setcategory(event.target.value);
                  }}
                >
                  {dataCategory.map(item => {
                    return (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="category-field">Kategori</InputLabel>
                <Input 
                  id='category-field' 
                  value={category} 
                  onChange={ event => {setcategory(event.target.value)}}
                  inputProps={{
                    "aria-readonly": true,
                  }}
                  />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="tag-field">Tag</InputLabel>
                <Input 
                  id='tag-field' 
                  value={tag} 
                  onChange={ event => {settag(event.target.value)}}
                  />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={3} style={{ marginTop: 15, marginBottom: 15 }}>
            <InputLabel htmlFor="contributor-helper">Foto Artikel</InputLabel>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              // ref={imgRef}
              type="file"
              onChange={e => handleImage(e)}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                className={classes.btnUpload}
                // onClick={handleInputFile}
              >
                <Typography style={{ color: "#ffffff" }}>
                  File Upload
                </Typography>
              </Button>
            </label>
            <br />
            <img src={imgPreview} width="100%" height={200} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
            <TextField
                className={classes.formContainer}
                margin="normal"
                label="caption"
                value={caption}
                name="caption"
                onChange={event => setcaption(event.target.value)}
              />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography>Isi Artikel</Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="file-editor"
                multiple
                type="file"
                // onChange={e => handleImage(e)}
              />
              <Box className={classes.editorToolbar}>
                <Editor
                  
                  apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
                  value={editorState}
                  init={{
                      file_picker_callback: function (
                          callback,
                          value,
                          meta
                      ) {
                          if (meta.filetype == "image") {
                              var input =
                                  document.getElementById(
                                      "file-editor"
                                  )
                              input.click()
                              input.onchange = function () {
                                  var reader = new FileReader()
                                  var file = input.files[0]
                                  var options = {
                                      maxSizeMB: 0.3,
                                      maxWidthOrHeight: 3000,
                                      useWebWorker: true
                                  }
                                  imageCompression(
                                      file,
                                      options
                                  ).then(function (
                                      compressedFile
                                  ) {
                                      reader.onload = function (
                                          e
                                      ) {
                                          callback(
                                              e.target.result,
                                              {
                                                  alt: compressedFile.name
                                              }
                                          )
                                      }
                                      reader.readAsDataURL(
                                          compressedFile
                                      )
                                  })
                              }
                          }
                      },
                      paste_data_images: true,
                      mobile: true,
                      images_upload_handler: function (
                          blobInfo,
                          success,
                          failure
                      ) {
                          success(
                              "data:" +
                                  blobInfo.blob().type +
                                  ";base64," +
                                  blobInfo.base64()
                          )
                      },
                      branding: false,
                      height: 500,
                      menubar: true,
                      plugins: [
                          "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                          "searchreplace wordcount visualblocks visualchars code fullscreen",
                          "insertdatetime media table paste code help fullpage",
                          "directionality",
                          "image"
                      ],
                      toolbar:
                          "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help "
                  }}
                  // onDragDrop={true}
                  // onChange={(e) => handleEditorChange(e)}
                  onEditorChange={(newValue, editor) =>
                      handleEditorChange(newValue, editor)
                  }
                 />
              </Box>
            </Grid>

            {/* <Grid item md={6} xs={12}>
              <Typography>articles Content</Typography>
              <input
                accept="image/*"
                className={classes.input}
                id="file-editor-en"
                multiple
                type="file"
                // onChange={e => handleImage(e)}
              />
              <Box className={classes.editorToolbar}>
                <Editor
                
                apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
                value={editorENState}
                init={{
                    file_picker_callback: function (
                        callback,
                        value,
                        meta
                    ) {
                        if (meta.filetype == "image") {
                            var input =
                                document.getElementById(
                                    "file-editor"
                                )
                            input.click()
                            input.onchange = function () {
                                var reader = new FileReader()
                                var file = input.files[0]
                                var options = {
                                    maxSizeMB: 0.3,
                                    maxWidthOrHeight: 3000,
                                    useWebWorker: true
                                }
                                imageCompression(
                                    file,
                                    options
                                ).then(function (
                                    compressedFile
                                ) {
                                    reader.onload = function (
                                        e
                                    ) {
                                        callback(
                                            e.target.result,
                                            {
                                                alt: compressedFile.name
                                            }
                                        )
                                    }
                                    reader.readAsDataURL(
                                        compressedFile
                                    )
                                })
                            }
                        }
                    },
                    paste_data_images: true,
                    mobile: true,
                    images_upload_handler: function (
                        blobInfo,
                        success,
                        failure
                    ) {
                        success(
                            "data:" +
                                blobInfo.blob().type +
                                ";base64," +
                                blobInfo.base64()
                        )
                    },
                    branding: false,
                    height: 500,
                    menubar: true,
                    plugins: [
                        "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                        "searchreplace wordcount visualblocks visualchars code fullscreen",
                        "insertdatetime media table paste code help fullpage",
                        "directionality",
                        "image"
                    ],
                    toolbar:
                        "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help "
                }}
                // onDragDrop={true}
                // onChange={(e) => handleEditorChange(e)}
                onEditorChange={(newValue, editor) =>
                    handleEditorENChange(newValue, editor)
                }
                />
              </Box>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div style={{ float: "right" }}>
            <Button
              style={{
                width: 120,
                border: "2px solid gray",
                marginBottom: 10,
                marginRight: 10,
              }}
              onClick={() => {
                props.history.push("/app/articles/content");
              }}
            >
              Batal
            </Button>
            <Button
              style={{
                width: 120,
                border: "2px solid #bf272b",
                color: "#bf272b",
                marginBottom: 10,
              }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("title", title);
                sendData.append("titleEN", titleEN);
                sendData.append("category", category);
                sendData.append("caption", caption);
                sendData.append("tag", tag);
                sendData.append("picture", img);
                sendData.append("content", editorState);
                sendData.append("contentEN", editorENState);
                sendData.append("articles_status", "Konten Baru");
                sendData.append("create_by", localStorage.id);
                console.log(JSON.stringify(sendData));
                if (title === "") {
                  setopenAlert(true);
                  setmessageError("Data Title wajib diisikan");
                } else if (category === "") {
                  setopenAlert(true);
                  setmessageError("Data Kategori wajib diisikan");
                } else if (img === "") {
                  setopenAlert(true);
                  setmessageError("Data Image Foto wajib dimasukkan");
                } else {
                    axios({
                      method: 'post',
                      url: url + "/private/articles/create",
                      data: sendData, 
                      headers:headers,
                      onUploadProgress: function (progressEvent) {
                        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        console.log('test progress upload',percentCompleted)
                      },
                    })
                    .then(() => {
                      props.history.push("/app/articles/content");
                    })
                    .catch(err => {
                      setopenAlert(true);
                      setmessageError(err);
                    });
                }
              }}
            >
              Simpan
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
