import axios from "axios";
import StaticVar from "../Config/StaticVar";

// ===> api create
const api = axios.create({
  baseURL: StaticVar.URL_API,
  // timeout: 100000,
  headers: {},
  // json: true
});

// ===> api interceptors
api.interceptors.request.use(
  async (config) => {
    config.headers["x-access-token"] = await localStorage.token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// ===> api list function request

const getDocument = (query) => api.get("/private/document", query);
const getNews = (query) => api.get("/private/news", query);
const getNote = (query) => api.get("/private/note", query);
const postNote = (body) => api.post("/private/note/create", body);
const putNote = (id, data) => api.put("/private/note/" + id, data);
const deleteNote = (id) => api.delete("/private/note/" + id);
const getNewsClassification = (query) =>
  api.get("/private/news_classification", query);
const getNewsCategory = (query) => api.get("/private/news_categories", query);
const getNewsCount = (query) => api.get("/news/count", query);
const getDocumentById = (id) => api.get("/private/document/" + id);
const postDocument = (data) => api.post("/private/document/create", data);
const putDocument = (id, data) => api.put("/private/document/" + id, data);
const postUploadDocument = (data) => api.post("/private/document_upload", data);
const getUsers = () => api.get("/private/users");

export const apis = {
  getDocument,
  getDocumentById,
  postDocument,
  putDocument,
  postUploadDocument,
  getUsers,
  getNews,
  getNewsClassification,
  getNewsCategory,
  getNewsCount,
  getNote,
  postNote,
  putNote,
  deleteNote,
};

export default apis;
