import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import { Editor } from "@tinymce/tinymce-react";
import _ from "lodash";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
import NoImg from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";

export default function EditPage(props) {
  var classes = useStyles();

  const url = StaticVar.URL_API;
  const id = props.match.params._id;

  let token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  let [name, setname] = useState("");
  let [nameEN, setnameEN] = useState("");
  var [status, setstatus] = useState("");
  var [imgPreview, setImgPreview] = useState(NoImg);
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");
  var [openAlert, setopenAlert] = useState(false);
  var [messageError, setmessageError] = useState("");
  var [editorState, seteditorState] = useState("");
  var [editorENState, seteditorENState] = useState("");
  var [dataParent, setDataParent] = useState([]);
  var [parent, setparent] = useState("");
  var [fullWidth, setFullWidth] = useState(false);

  async function getData() {
    let res = await axios.get(url + "/private/pages/" + id, { headers });
    let response = await res.data[0];
    setImgPreview(url + "/repo/" + response.picture);
    setname(response.name);
    setnameEN(response.nameEN);
    setstatus(response.page_status);
    setparent(response.parent);
    setFullWidth(response.fullwidth);

    const datasample = response.content;
    seteditorState(datasample);

    const datasampleEN = response.contentEN;
    seteditorENState(datasampleEN);
  }

  useEffect(() => {
    getData();
    // getDataParent();
    // return () => {
    //   getData();
    //   // getDataParent();
    // };
  }, []);

  const getDataParent = async () => {
    let res = await axios.get(url + "/private/pages", { headers });
    let response = await res.data;

    var dataparent = _.filter(response, function (item) {
      return item.parent == "Tanpa Parent";
    });
    setDataParent(dataparent);
  };

  function handleEditorChange(value) {
    seteditorState(value);
    console.log("Content was updated:", editorState);
  }

  function handleEditorENChange(value) {
    seteditorENState(value);
    console.log("Content EN was updated:", editorENState);
  }

  // function handleImage(e) {
  //   let reader = new FileReader();
  //   let file = e.target.files[0],
  //     pattern = /image-*/;

  //   if (!file.type.match(pattern)) {
  //     setIsImgValid(true);
  //     setImgErrorMsg("Format File tidak sesuai");
  //     return;
  //   }

  //   reader.onloadend = () => {
  //     setIsImgValid(false);
  //     setImgErrorMsg("");
  //     setImg(file);
  //     setImgPreview(reader.result);
  //   };

  //   reader.readAsDataURL(file);
  // }

  return (
    <>
      <PageTitle title="Edit Laman" />

      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Box>
            <Typography color="textSecondary">Nama Laman : </Typography>
            <TextField
              className={classes.formContainer}
              margin="normal"
              value={name}
              onChange={(event) => setname(event.target.value)}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box>
            <Typography color="textSecondary">Page Title : </Typography>
            <TextField
              className={classes.formContainer}
              margin="normal"
              value={nameEN}
              onChange={(event) => setnameEN(event.target.value)}
            />
          </Box>
        </Grid>
        {/* <Grid item xs={12} style={{ marginBottom: 10 }}>
          <InputLabel htmlFor="contributor-helper">Parent</InputLabel>
          <Select
            className={classes.formContainer}
            value={parent}
            onChange={(event) => {
              setparent(event.target.value);
            }}
          >
            <MenuItem value={"Tanpa Parent"}>Tanpa Parent</MenuItem>
            {dataParent.map((item) => (
              <MenuItem value={item._id}>{item.name}</MenuItem>
            ))}
          </Select>
        </Grid> */}
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <InputLabel htmlFor="contributor-helper">Layar Penuh</InputLabel>
          <Select
            className={classes.formContainer}
            value={fullWidth}
            onChange={(event) => {
              setFullWidth(event.target.value);
            }}
          >
            <MenuItem value={true}>YA, Layar Penuh</MenuItem>
            <MenuItem value={false}>Tidak, layar default biasa</MenuItem>
          </Select>
        </Grid>
        {/* <Grid item xs={12} style={{ marginTop: 20 }}>
          <InputLabel htmlFor="contributor-helper">Foto Laman</InputLabel>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={e => handleImage(e)}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="raised"
              component="span"
              className={classes.btnUpload}
            >
              <Typography style={{ color: "#ffffff" }}>File Upload</Typography>
            </Button>
          </label>
          <br />
          <img src={imgPreview} style={{ height: 200 }} />
        </Grid> */}
        <Grid item md={6} xs={12}>
          <Typography>Isi Laman</Typography>
          <input
            accept="image/*"
            className={classes.input}
            id="file-editor"
            multiple
            type="file"
            // onChange={e => handleImage(e)}
          />
          <Box className={classes.editorToolbar}>
            <Editor
              apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
              value={editorState}
              init={{
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype == "image") {
                    var input = document.getElementById("file-editor");
                    input.click();
                    input.onchange = function () {
                      var reader = new FileReader();
                      var file = input.files[0];
                      var options = {
                        maxSizeMB: 0.3,
                        maxWidthOrHeight: 3000,
                        useWebWorker: true,
                      };
                      imageCompression(file, options).then(function (
                        compressedFile,
                      ) {
                        reader.onload = function (e) {
                          callback(e.target.result, {
                            alt: compressedFile.name,
                          });
                        };
                        reader.readAsDataURL(compressedFile);
                      });
                    };
                  }
                },
                paste_data_images: true,
                mobile: true,
                images_upload_handler: function (blobInfo, success, failure) {
                  success(
                    "data:" +
                      blobInfo.blob().type +
                      ";base64," +
                      blobInfo.base64(),
                  );
                },
                branding: false,
                height: 500,
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                  "searchreplace wordcount visualblocks visualchars code fullscreen",
                  "insertdatetime media table paste code help fullpage",
                  "directionality",
                  "image",
                ],
                toolbar:
                  "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help ",
              }}
              // onDragDrop={true}
              // onChange={(e) => handleEditorChange(e)}
              onEditorChange={(newValue, editor) =>
                handleEditorChange(newValue, editor)
              }
            />
          </Box>

          {/* <textarea 
              disabled
              value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            /> */}
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography>Content Page</Typography>
          <input
            accept="image/*"
            className={classes.input}
            id="file-editor-en"
            multiple
            type="file"
            // onChange={e => handleImage(e)}
          />
          <Box className={classes.editorToolbar}>
            <Editor
              apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
              value={editorENState}
              init={{
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype == "image") {
                    var input = document.getElementById("file-editor");
                    input.click();
                    input.onchange = function () {
                      var reader = new FileReader();
                      var file = input.files[0];
                      var options = {
                        maxSizeMB: 0.3,
                        maxWidthOrHeight: 3000,
                        useWebWorker: true,
                      };
                      imageCompression(file, options).then(function (
                        compressedFile,
                      ) {
                        reader.onload = function (e) {
                          callback(e.target.result, {
                            alt: compressedFile.name,
                          });
                        };
                        reader.readAsDataURL(compressedFile);
                      });
                    };
                  }
                },
                paste_data_images: true,
                mobile: true,
                images_upload_handler: function (blobInfo, success, failure) {
                  success(
                    "data:" +
                      blobInfo.blob().type +
                      ";base64," +
                      blobInfo.base64(),
                  );
                },
                branding: false,
                height: 500,
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                  "searchreplace wordcount visualblocks visualchars code fullscreen",
                  "insertdatetime media table paste code help fullpage",
                  "directionality",
                  "image",
                ],
                toolbar:
                  "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help ",
              }}
              // onDragDrop={true}
              // onChange={(e) => handleEditorChange(e)}
              onEditorChange={(newValue, editor) =>
                handleEditorENChange(newValue, editor)
              }
            />
          </Box>

          {/* <textarea 
              disabled
              value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            /> */}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <div style={{ float: "right" }}>
            <Button
              variant="outlined"
              onClick={() => {
                props.history.push("/app/pages");
              }}
            >
              <Typography className={classes.txtAction}>Batal</Typography>
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 1.5 }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("name", name);
                sendData.append("nameEN", nameEN);
                sendData.append("parent", parent);
                sendData.append("fullwidth", fullWidth);
                sendData.append("content", editorState);
                sendData.append("contentEN", editorENState);
                sendData.append("edit_by", localStorage.id);
                sendData.append("page_status", "Telah diedit");
                console.log("sendData", sendData);
                axios
                  .put(url + "/private/pages/" + id, sendData, { headers })
                  .then((res) => {
                    console.log("res", res.data);
                    props.history.push("/app/pages");
                  });
              }}
            >
              <Typography className={classes.txtAction}>
                Simpan Konten
              </Typography>
            </Button>
            <Button
              variant="outlined"
              color="success"
              sx={{ ml: 1.5 }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("name", name);
                sendData.append("nameEN", nameEN);
                sendData.append("parent", parent);
                sendData.append("fullwidth", fullWidth);
                sendData.append("content", editorState);
                sendData.append("contentEN", editorENState);
                sendData.append("publish_by", localStorage.id);
                sendData.append("page_status", "Publish");
                axios
                  .put(url + "/private/pages/" + id, sendData, { headers })
                  .then((res) => {
                    console.log(res);
                    props.history.push("/app/pages");
                  });
              }}
            >
              <Typography className={classes.txtAction}>Publish</Typography>
            </Button>
          </div>
        </Grid>

        <Dialog
          open={openAlert}
          close={() => setopenAlert(false)}
          title={"Periksa Data isian Anda ??"}
          content={<Typography>{messageError}</Typography>}
          cancel={() => setopenAlert(false)}
          confirm={() => setopenAlert(false)}
          valueConfirm={"Oke"}
          valueCancel={"Tutup"}
        />
      </Grid>
    </>
  );
}
