import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import imageCompression from "browser-image-compression";

import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Typography,
  TablePagination,
} from "@mui/material";
import ReactCrop from "react-image-crop";

// styles
import useStyles from "./styles";
import axios from "axios";

import PageTitle from "../Components/PageTitle/PageTitle";
import Dialog from "../Components/Dialog/Dialog";

import NoImg from "../../Assets/Images/no-image.png";
import InboxIcon from "@mui/icons-material/Inbox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import StaticVar from "../../Config/StaticVar";
import { getCroppedImage } from "../../Utils/getCroppedImage";

export default function Sponsor(props) {
  var classes = useStyles();

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  var [idSponsor, setIdSponsor] = useState("");
  var [nameSponsor, setNameSponsor] = useState("");
  var [category, setcategory] = useState("");
  var [baseUrl, setbaseUrl] = React.useState("");
  var [position, setposition] = React.useState("");
  var [link, setLink] = React.useState("");
  var [dataPages, setDataPages] = React.useState([]);

  var [imgPreview, setImgPreview] = useState(NoImg);
  // var [imgEditPreview, setImgEditPreview ] = useState();
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");

  const [openModalAddSponsor, setOpenModalAddSponsor] = useState(false);

  const [data, setData] = useState([]);

  var [index, setIndex] = useState(0);

  const [crop, setCrop] = useState();
  const imgCropperRef = useRef();

  const handleClickOpenAdd = () => {
    setOpenModalAdd(true);
    setIdSponsor("");
    setNameSponsor("");
    setImg("");
    setImgPreview("");
    setcategory("");
    setposition("");
    setLink("");
  };

  const handleClickOpen = () => {
    setOpenModalAddSponsor(true);
  };

  function handleClickOpenEdit(id, name, logo, category, position, link) {
    setIdSponsor(id);
    setNameSponsor(name);
    setImgPreview(url + "/repo/" + logo);
    setcategory(category);
    setposition(position);
    setLink(link);
    setOpenModalEdit(true);
  }

  function handleClickOpenDelete(id, name) {
    setIdSponsor(id);
    setNameSponsor(name);
    setOpenModalDelete(true);
  }

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalAdd(false);
    setOpenModalEdit(false);
  };

  const getData = async () => {
    let res = await axios.get(url + "/private/sponsor", { headers });
    let response = await res.data;
    setData(response);
    setRows(response);
  };

  //   let date = new Date();

  function postData() {
    let sendData = new FormData();
    sendData.append("name", nameSponsor);
    sendData.append("status_sponsor", "Tidak Aktif");
    sendData.append("logo", img);
    sendData.append("category", category);
    sendData.append("position", position);
    sendData.append("link", link);
    sendData.append("create_by", localStorage.id);
    sendData.append("base_URL", "/pages/" + baseUrl);

    axios
      .post(url + "/private/sponsor/create", sendData, { headers })
      .then((res) => {
        setOpenModalAdd(false);
        getData();
        setImgPreview("");
        setImg(null);
      });
  }

  //for reload page javascript  window.location.reload();

  function editData(id) {
    let editData = new FormData();
    editData.append("name", nameSponsor);
    editData.append("status_sponsor", "Tidak Aktif");
    editData.append("logo", img);
    editData.append("category", category);
    editData.append("position", position);
    editData.append("link", link);
    editData.append("create_by", localStorage.id);
    editData.append("base_URL", "/pages/" + baseUrl);

    axios
      .put(url + "/private/sponsor/" + id, editData, { headers })
      .then(() => {
        setOpenModalEdit(false);
        getData();
        setImgPreview("");
        setImg(null);
      });
  }

  const getDataPages = async () => {
    let res = await axios.get(url + "/private/pages", { headers });
    let response = await res.data;
    setDataPages(response);
  };

  function editDataForPublish(id) {
    let EditPublish = new FormData();
    EditPublish.append("name", nameSponsor);
    EditPublish.append("status_sponsor", "Publish");
    EditPublish.append("logo", img);
    EditPublish.append("category", category);
    EditPublish.append("position", position);
    EditPublish.append("link", link);
    EditPublish.append("create_by", localStorage.id);
    EditPublish.append("base_URL", "/pages/" + baseUrl);

    axios
      .put(url + "/private/sponsor/" + id, EditPublish, { headers })
      .then(() => {
        setOpenModalEdit(false);
        getData();
      });
  }

  function deleteData(id) {
    axios.delete(url + "/private/sponsor/" + id, { headers }).then((res) => {
      setOpenModalDelete(false);
      getData();
    });
  }

  useEffect(() => {
    getData();
    getDataPages();
    return () => {
      getData();
      getDataPages();
    };
  }, []);

  const imgRef = useRef(null);

  function handleInputFile() {
    // menghubungkan ke button input
    imgRef.current.click();
  }

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    console.log("originalFile instanceof Blob", file instanceof Blob); // true
    console.log("originalFile size", file.size);

    var options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob,
      ); // true
      console.log("compressedFile size ", compressedFile); // smaller than maxSizeMB

      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result);
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onCrop = async () => {
    if (imgCropperRef.current && crop?.width && crop?.height) {
      const croppedImage = await getCroppedImage(
        imgCropperRef.current,
        crop,
        img.name,
        img.type,
      );

      setImg(croppedImage);
      setImgPreview(croppedImage.preview);
      setCrop(null);
    }
  };

  return (
    <>
      <PageTitle title="Sponsor" button="Tambah" click={handleClickOpen} />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "center",
                      width: "3%",
                    }}
                  >
                    No
                  </TableCell>
                  <TableCell style={{ textAlign: "center", width: "20%" }}>
                    Logo
                  </TableCell>
                  <TableCell style={{ textAlign: "center", width: "20%" }}>
                    Nama
                  </TableCell>
                  <TableCell style={{ textAlign: "center", width: "15%" }}>
                    Kategori
                  </TableCell>
                  <TableCell style={{ textAlign: "center", width: "20%" }}>
                    Base_URL
                  </TableCell>
                  <TableCell style={{ textAlign: "center", width: "10%" }}>
                    status
                  </TableCell>
                  <TableCell style={{ textAlign: "center", width: "15%" }}>
                    Aksi
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell style={{ width: "3%", textAlign: "center" }}>
                        <Typography className={classes.txtContentTable}>
                          {page * rowsPerPage + (index + 1)}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: "20%" }}>
                        <center>
                          <img
                            src={url + "/repo/" + item.logo}
                            style={{ width: 170, height: 160 }}
                          />
                        </center>
                      </TableCell>
                      <TableCell style={{ width: "20%", textAlign: "center" }}>
                        <Typography style={{ fontWeight: 300, fontSize: 15 }}>
                          {item.name}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ textAlign: "center", width: "15%" }}>
                        <Typography className={classes.txtContentTable}>
                          {item.category}
                        </Typography>
                      </TableCell>
                      {item.link === "" ? (
                        <TableCell
                          style={{ textAlign: "center", width: "20%" }}
                        >
                          {item.base_URL === "" ? (
                            <Typography className={classes.txtContentTable}>
                              Tidak Ada Base URL/Link
                            </Typography>
                          ) : (
                            <Typography className={classes.txtContentTable}>
                              {item.base_URL}
                            </Typography>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell
                          style={{ textAlign: "center", width: "20%" }}
                        >
                          <Typography className={classes.txtContentTable}>
                            {item.link}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell style={{ width: "10%", textAlign: "center" }}>
                        <Typography style={{ fontWeight: 300, fontSize: 15 }}>
                          {item.status_sponsor}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>
                        <Button
                          variant="contained"
                          sx={{ width: 100, mb: 1 }}
                          color="warning"
                          onClick={() =>
                            handleClickOpenEdit(
                              item?._id,
                              item?.name,
                              item?.logo,
                              item?.category,
                              item?.position,
                              item?.link,
                            )
                          }
                        >
                          <Typography className={classes.txtAction}>
                            Edit
                          </Typography>
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ width: 100, mb: 1 }}
                          color="error"
                          onClick={() =>
                            handleClickOpenDelete(item._id, item.name)
                          }
                        >
                          <Typography className={classes.txtAction}>
                            Hapus
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ overflow: "visible" }}
            />
          </Paper>
        </Grid>
      </Grid>

      {/* <==========================================================================================================================================> */}

      {/* modal add */}
      <Dialog
        open={openModalAdd}
        close={handleClose}
        title={"Form Tambah Sponsor"}
        content={
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginBottom: 10 }}
            >
              <TextField
                label="Nama Sponsor"
                className={classes.formContainer}
                margin="normal"
                value={nameSponsor}
                onChange={(event) => setNameSponsor(event.target.value)}
                name="name"
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <InputLabel htmlFor="contributor-helper">Kategori</InputLabel>
              <Select
                className={classes.formContainer}
                value={category}
                onChange={(event) => {
                  setcategory(event.target.value);
                }}
              >
                <MenuItem value={"Laman"}>Laman</MenuItem>
                <MenuItem value={"Redirect"}>Redirect</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <InputLabel htmlFor="contributor-helper">Posisi</InputLabel>
              <Select
                className={classes.formContainer}
                value={position}
                onChange={(event) => {
                  setposition(event.target.value);
                }}
              >
                <MenuItem value={"Home"}>Home</MenuItem>
                <MenuItem value={"NewsCenter"}>Center of News</MenuItem>
                <MenuItem value={"Top"}>Top</MenuItem>
                <MenuItem value={"Left"}>Left</MenuItem>
                <MenuItem value={"Right"}>Right</MenuItem>
                <MenuItem value={"Center"}>Center</MenuItem>
                <MenuItem value={"Bottom"}>Bottom</MenuItem>
              </Select>
            </Grid>
            {category === "Laman" ? (
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">
                  Pilih Laman
                </InputLabel>
                <Select
                  className={classes.formContainer}
                  value={baseUrl}
                  onChange={(event) => {
                    setbaseUrl(event.target.value);
                  }}
                >
                  {dataPages.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : null}
            {category === "Redirect" ? (
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Base URL atau Link"
                  className={classes.formContainer}
                  margin="normal"
                  value={link}
                  onChange={(event) => {
                    setLink(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
            ) : null}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              Upload Image:
              <input
                accept="image/*"
                className={classes.input}
                ref={imgRef}
                multiple
                type="file"
                onChange={(e) => handleImage(e)}
              />
              <Button
                className={classes.btnUpload}
                onClick={handleInputFile}
                sx={{ ml: 1.25 }}
              >
                <Typography style={{ color: "#ffffff" }}>Upload</Typography>
              </Button>
              {!!img && (
                <Box sx={{ mt: 1.25 }}>
                  <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                    <Box
                      ref={imgCropperRef}
                      component="img"
                      src={imgPreview}
                      alt=""
                      sx={{ width: "100%", height: "auto" }}
                    />
                  </ReactCrop>
                  <Button
                    variant="outlined"
                    sx={{ mt: 1.5, width: "100%", mb: 2 }}
                    onClick={onCrop}
                    disabled={!crop?.width && !crop?.width}
                  >
                    <Typography className={classes.txtAction}>
                      Crop image
                    </Typography>
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        }
        cancel={handleClose}
        valueCancel={"Batalkan"}
        confirm={() => postData()}
        valueConfirm={"Tambah"}
      />

      {/* modal edit */}
      <Dialog
        open={openModalEdit}
        close={handleClose}
        title={"Form Edit Sponsor"}
        content={
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Nama Sponsor"
                className={classes.formContainer}
                margin="normal"
                value={nameSponsor}
                onChange={(event) => setNameSponsor(event.target.value)}
                name="name"
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <InputLabel htmlFor="contributor-helper">Kategori</InputLabel>
              <Select
                className={classes.formContainer}
                value={category}
                onChange={(event) => {
                  setcategory(event.target.value);
                }}
              >
                <MenuItem value={"Laman"}>Laman</MenuItem>
                <MenuItem value={"Redirect"}>Redirect</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <InputLabel htmlFor="contributor-helper">Posisi</InputLabel>
              <Select
                className={classes.formContainer}
                value={position}
                onChange={(event) => {
                  setposition(event.target.value);
                }}
              >
                <MenuItem value={"Home"}>Home</MenuItem>
                <MenuItem value={"NewsCenter"}>Center of News</MenuItem>
                <MenuItem value={"Top"}>Top</MenuItem>
                <MenuItem value={"Left"}>Left</MenuItem>
                <MenuItem value={"Right"}>Right</MenuItem>
                <MenuItem value={"Center"}>Center</MenuItem>
                <MenuItem value={"Bottom"}>Bottom</MenuItem>
              </Select>
            </Grid>
            {category === "Laman" ? (
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <InputLabel htmlFor="contributor-helper">
                  Pilih Laman
                </InputLabel>
                <Select
                  className={classes.formContainer}
                  value={baseUrl}
                  onChange={(event) => {
                    setbaseUrl(event.target.value);
                  }}
                >
                  {dataPages.map((item) => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : null}
            {category === "Redirect" ? (
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  label="Base URL atau Link"
                  className={classes.formContainer}
                  margin="normal"
                  value={link}
                  onChange={(event) => {
                    setLink(event.target.value);
                  }}
                  name="name"
                />
              </Grid>
            ) : null}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              Upload Image:
              <input
                accept="image/*"
                className={classes.input}
                ref={imgRef}
                multiple
                type="file"
                onChange={(e) => handleImage(e)}
              />
              <Button
                className={classes.btnUpload}
                onClick={handleInputFile}
                sx={{ ml: 1.25 }}
              >
                <Typography style={{ color: "#ffffff" }}>Upload</Typography>
              </Button>
              {!img ? (
                <Box
                  component="img"
                  src={imgPreview}
                  alt=""
                  sx={{ width: "100%", height: "auto", mt: 1.25 }}
                />
              ) : (
                <Box sx={{ mt: 1.25 }}>
                  <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                    <Box
                      ref={imgCropperRef}
                      component="img"
                      src={imgPreview}
                      alt=""
                      sx={{ width: "100%", height: "auto" }}
                    />
                  </ReactCrop>
                  <Button
                    variant="outlined"
                    sx={{ mt: 1.5, width: "100%", mb: 2 }}
                    onClick={onCrop}
                    disabled={!crop?.width && !crop?.width}
                  >
                    <Typography className={classes.txtAction}>
                      Crop image
                    </Typography>
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid
              item
              lg={12}
              sx={{
                mt: 2.5,
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ marginRight: 1.5 }}
              >
                <Typography className={classes.txtAction}>Batalkan</Typography>
              </Button>
              <Button
                variant="contained"
                onClick={() => editData(idSponsor)}
                sx={{ marginRight: 1.5 }}
              >
                <Typography className={classes.txtAction}>
                  Simpan Konten
                </Typography>
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={() => editDataForPublish(idSponsor)}
              >
                <Typography className={classes.txtAction}>Publish</Typography>
              </Button>
            </Grid>
          </Grid>
        }
      />

      {/* modal delete */}
      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography>{nameSponsor}</Typography>
            </Grid>
          </Grid>
        }
        cancel={handleClose}
        confirm={() => deleteData(idSponsor, index)}
        valueConfirm={"Hapus"}
      />

      <Dialog
        open={openModalAddSponsor}
        close={() => setOpenModalAdd(false)}
        title={"Pilih Tipe Sponsor"}
        content={
          <Box sx={{ mb: 3 }}>
            <Button
              variant="contained"
              onClick={() =>
                props.history.push("/app/sponsor/content/form-content/photo")
              }
              sx={{ width: "100%", textTransform: "none" }}
            >
              Sponsor dengan Foto
            </Button>
            <Button
              variant="contained"
              sx={{
                mt: 1.5,
                display: "block",
                width: "100%",
                textTransform: "none",
              }}
              onClick={() =>
                props.history.push("/app/sponsor/content/form-content/video")
              }
            >
              Sponsor dengan Video
            </Button>
          </Box>
        }
        valueCancel={"Batalkan"}
        cancel={() => setOpenModalAddSponsor(false)}
      />
    </>
  );
}
