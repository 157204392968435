import React, { useState, useEffect, useRef } from "react"
import { 
  Box, 
  FormControl, 
  Grid, 
  OutlinedInput, 
  Typography,
  Stack,
  Button,
  Select,
  MenuItem
} from "@mui/material";

import PageTitle from "../Components/PageTitle/PageTitle";
import NoFile from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";
import Api from "../../Services/Api"
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const FormOrganization = () => {

  const [level, setLevel] = useState("")

  return (
    <>
    <PageTitle title='Organisasi' />
    <Box component='form'>
      <Grid container spacing={2}>
        <Grid item xs={2} alignItems='center'>Nama Organisasi</Grid>
        <Grid item xs={10 }>
          <FormControl size="small" fullWidth>
            <OutlinedInput />
          </FormControl>
        </Grid>
        <Grid item xs={2}>Pilih Level</Grid>
        <Grid item xs={10}>
          <Box component='div' sx={{ display: 'flex', alignItems: 'center'}}>
            <ToggleButtonGroup
              // colo r="secondary"
              value={level}
              exclusive
              onChange={(e) => setLevel(e.target.value)}
            >

              <ToggleButton color="secondary" value='kepala'>Bidang</ToggleButton>
              <ToggleButton color="secondary" value='departement'>Bidang</ToggleButton>
              <ToggleButton value='section'>Seksi</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>Pilih Divisi</Box>
        </Grid>
        <Grid item xs={10}>
          <FormControl variant="outlined" size="small">
            <Select>
              <MenuItem>1</MenuItem>
              <MenuItem>2</MenuItem>
              <MenuItem>3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Button color="error" variant="outlined">Batalkan</Button>
            </Grid>
            <Grid item>
              <Button disableElevation color="primary" variant="contained">Tambah</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    </>
  )
}

export default FormOrganization