export async function getCroppedImage(
  sourceImage,
  cropConfig,
  fileName,
  fileType,
) {
  const canvas = document.createElement("canvas");
  const scaleX = sourceImage.naturalWidth / sourceImage.width;
  const scaleY = sourceImage.naturalHeight / sourceImage.height;
  canvas.width = cropConfig.width * scaleX;
  canvas.height = cropConfig.height * scaleY;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    sourceImage,
    cropConfig.x * scaleX,
    cropConfig.y * scaleY,
    cropConfig.width * scaleX,
    cropConfig.height * scaleY,
    0,
    0,
    cropConfig.width * scaleX,
    cropConfig.height * scaleY,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      // returning an error
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }

      const file = Object.assign(
        new File([blob], fileName, { type: fileType }),
        {
          preview: URL.createObjectURL(blob),
        },
      );
      resolve(file);
    }, "image/jpeg");
  });
}
