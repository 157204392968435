import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";

// pages
import Events from "../Events/Events";
import Dashboard from "../Dashboard/Dashboard";
import Category from "../News/Category";
import Classification from "../News/Classification";
import NewsContent from "../News/Content";
import EditorContent from "../News/EditorContent";
import AddContent from "../News/AddContent";
import ArticlesCategory from "../Articles/Category";
import ArticlesContent from "../Articles/Content";
import EditorArticlesContent from "../Articles/EditorContent";
import AddArticlesContent from "../Articles/AddContent";
import Document from "../Document/Document";
import FormDocument from "../Document/FormDocument";
import Organization from "../Organization/Organization";
import FormOrganization from "../Organization/FormOrganization";
import GalleryPhoto from "../Gallery/Photos";
import AddPhoto from "../Gallery/AddPhotos";
import AddVideo from "../Gallery/AddVideos";
import PhotosCategories from "../Gallery/PhotosCategories";
import VideosCategories from "../Gallery/VideosCategories";
import GalleryVideo from "../Gallery/Videos";
import Pages from "../Pages/Pages";
import Sitemap from "../Sitemap/Sitemap";
import Highlight from "../Highlight/Highlight";
import Users from "../Users/Users";
import Access from "../Users/Access";
import Faq from "../Faq/index";
import Sponsor from "../Sponsor/Sponsor";
import Comments from "../Comments/Comments";
import Member from "../Member/index";

// context
import { useLayoutState } from "../../Context/LayoutContext";
import AddPage from "../Pages/AddPage";
import EditPage from "../Pages/EditPage";
import Popup from "../Popup/Popup";
import Note from "../Note/Note";
import Survey from "../Survey/Survey";
import AddContentSponsor from "../Sponsor/AddContentSponsor";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route exact path="/app/dashboard" component={Dashboard} />
            <Route exact path="/app/news/content" component={NewsContent} />
            <Route exact path="/app/news/category" component={Category} />
            <Route
              exact
              path="/app/news/classification"
              component={Classification}
            />
            <Route
              exact
              path="/app/news/content/form-content/:type"
              component={AddContent}
            />

            <Route
              exact
              path="/app/sponsor/content/form-content/:type"
              component={AddContentSponsor}
            />
            <Route
              exact
              path="/app/news/content/editor-content/:type/:_id"
              component={EditorContent}
            />
            <Route
              exact
              path="/app/articles/content"
              component={ArticlesContent}
            />
            <Route
              exact
              path="/app/articles/category"
              component={ArticlesCategory}
            />
            <Route
              exact
              path="/app/articles/content/form-content/"
              component={AddArticlesContent}
            />
            <Route
              exact
              path="/app/articles/content/editor-content/:_id"
              component={EditorArticlesContent}
            />
            {/* <Route exact path="/app/document" component={Document} />
            <Route
              exact
              path="/app/document/form/:_id"
              component={FormDocument}
            /> */}
            {/* <Route exact path="/app/organization" component={Organization} />
            <Route
              exact
              path="/app/organization/form/:_id"
              component={FormOrganization}
            /> */}

            <Route exact path="/app/highlight" component={Highlight} />
            <Route exact path="/app/gallery/photos" component={GalleryPhoto} />
            <Route
              exact
              path="/app/gallery/photos/add-photos/:category"
              component={AddPhoto}
            />
            <Route
              exact
              path="/app/gallery/videos/add-videos"
              component={AddVideo}
            />
            <Route exact path="/app/gallery/videos" component={GalleryVideo} />
            <Route
              exact
              path="/app/gallery/categories-photos"
              component={PhotosCategories}
            />
            <Route
              exact
              path="/app/gallery/categories-videos"
              component={VideosCategories}
            />
            <Route exact path="/app/pages" component={Pages} />
            <Route exact path="/app/pages/new_page" component={AddPage} />
            <Route
              exact
              path="/app/pages/edit_page/:_id"
              component={EditPage}
            />
            <Route exact path="/app/sitemap" component={Sitemap} />
            <Route exact path="/app/sponsor" component={Sponsor} />
            <Route exact path="/app/users/list" component={Users} />
            <Route exact path="/app/member" component={Member} />
            <Route exact path="/app/users/access" component={Access} />
            <Route exact path="/app/faq" component={Faq} />

            <Route exact path="/app/events" component={Events} />
            {/* <Route exact path="/app/popup" component={Popup} />
            <Route exact path="/app/popup" component={Survey} /> */}
            <Route exact path="/app/comments" component={Comments} />
            <Route exact path="/app/note" component={Note} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
