import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  InputAdornment,
  IconButton,
  Collapse,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { withRouter } from "react-router-dom";
// styles
import useStyles from "./styles";

// logo
import kabarinLogo from "./logo-website.svg";

// context
import {
  useUserDispatch,
  useUserState,
  loginUser,
} from "../../Context/UserContext";
import { WidgetsSharp } from "@mui/icons-material";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  var { message } = useUserState();
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [emailValue, setEmailValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [isPasswordOpen, setPasswordOpen] = useState(false);

  console.log("ini p", message);

  const handleSubmit = async (e) => {
    e.preventDefault();
    loginUser(
      userDispatch,
      emailValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError,
    );
  };

  const handleClickShowPassword = () => {
    setPasswordOpen(!isPasswordOpen);
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <Box
          component={"img"}
          src={kabarinLogo}
          className={classes.logotypeImage}
        />
        <div className={classes.header}>
          <Typography className={classes.greeting}>
            Login Admin Portal
          </Typography>
        </div>
        <form className={classes.form} onSubmit={handleSubmit}>
          <React.Fragment>
            <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
            </div>
            <Collapse in={message !== "" && message !== undefined}>
              <Typography color="error" className={classes.errorMessage}>
                {
                  {
                    "Invalid email!!!": "Email tidak ditemukan!",
                    "Invalid password!!!": "Password yang anda masukan salah!",
                  }[message]
                }
              </Typography>
            </Collapse>
            <TextField
              id="email"
              name="email"
              variant="outlined"
              label="Email"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              margin="normal"
              placeholder="name@mail.com"
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              variant="outlined"
              label="Password"
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              type={isPasswordOpen ? "text" : "password"}
              name="password"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {isPasswordOpen ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.formButtons}>
              <LoadingButton
                disabled={emailValue.length === 0 || passwordValue.length === 0}
                loading={isLoading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                Login
              </LoadingButton>
              {/* <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                >
                  Forget Password
                </Button> */}
            </div>
          </React.Fragment>
        </form>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
