import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/styles";
import { StyledEngineProvider } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Themes from "./Themes";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./Context/LayoutContext";
import { UserProvider } from "./Context/UserContext";
import NewsProvider from "./Context/NewsContext";

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <LayoutProvider>
      <UserProvider>
        <NewsProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={Themes.default}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        </NewsProvider>
      </UserProvider>
    </LayoutProvider>
  </LocalizationProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
