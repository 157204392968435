import React from "react";
import { Button,Grid } from "@mui/material";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers/Wrappers";
import { orange } from '@mui/material/colors';

const buttonColor = orange[600];

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <Grid container spacing={1.5} className={classes.pageTitleContainer}>
      <Grid item xs={12} md={props.filter || props.button?7:12}>
        <Typography className={classes.typo} variant="h1" size="sm">
          {props.title}
        </Typography>
      </Grid>
      {props.filter?
        <Grid item xs={7} md={3}>
          {props.filter}
        </Grid>
      :""}
      {props.status ? 
        <Grid item xs={12}>
          <Typography className={classes.statustxt} variant="h3" size="sm">
            {props.status}
          </Typography>
        </Grid>
      :""}
        {props.button && (
      <Grid item xs={5} md={2}>
          <Button
            classes={{ root: classes.button }}
            variant="contained"
            size="large"
            color="primary"
            onClick={props.click}
          >
            {props.button}
          </Button>
      </Grid>
        )}
    </Grid>
  );
}
