import React, { useContext, createContext, useState } from "react"
import Api from "../Services/Api"

export const NewsContext = createContext({})

export default function NewsProvider(props) {
    const [news, setNews] = useState([])
    const [newsOnPage, setNewsOnPage] = useState({})
    const [filterNews, setFilterNews] = useState([])
    const [newsCategory, setNewsCategory] = useState([])
    const [filterNewsCategory, setFilterNewsCategory] = useState([])
    const [newsClassification, setNewsClassification] = useState([])
    const [filterNewsClassification, setFilterNewsClassification] = useState([])
    const [countNews, setCountNews] = useState({})

    const getDataNews = async (query) => {
        if (news.length === 0) {
            Api.getNews(query ? { params: query } : {}).then(result => {
                setNews(result.data)
                setFilterNews(result.data)
                return { status: "OK", result }
            }).catch(error => {
                return { status: "Failed", error }
            })
        }
    }

    const getDataNewsOnPage = async (query) => {
        Api.getNews(query ? { params: query } : {}).then(result => {
            setNewsOnPage(result.data)
            return { status: "OK", result }
        }).catch(error => {
            return { status: "Failed", error }
        })
    }

    const getDataNewsCategory = async (query) => {
        if (newsCategory.length === 0) {
            Api.getNewsCategory(query ? { params: query } : {}).then(result => {
                setNewsCategory(result.data)
                setFilterNewsCategory(result.data)
                return { status: "OK", result }
            }).catch(error => {
                return { status: "Failed", error }
            })
        }
    }

    const getDataNewsClassification = async (query) => {
        if (newsClassification.length === 0) {
            Api.getNewsClassification(query ? { params: query } : {}).then(result => {
                setNewsClassification(result.data)
                setFilterNewsClassification(result.data)
                return { status: "OK", result }
            }).catch(error => {
                return { status: "Failed", error }
            })
        }
    }

    const getDataNewsCount = async (query) => {
        Api.getNewsCount(query ? { params: query } : {}).then(result => {
            console.log("result.data", result.data)
            setCountNews(result.data[0])
            return { status: "OK", result }
        }).catch(error => {
            return { status: "Failed", error }
        })
    }

    return (
        <NewsContext.Provider
            value={{
                setNews,
                news,
                filterNews,
                setFilterNews,
                getDataNewsOnPage, newsOnPage,
                getDataNews, getDataNewsCategory,
                getDataNewsClassification,
                getDataNewsCount,
                newsCategory, setNewsCategory,
                filterNewsCategory, setFilterNewsCategory,
                newsClassification, setNewsClassification,
                filterNewsClassification, setFilterNewsClassification,
                countNews, setCountNews
            }}
            {...props}
        />
    )
}
