import React, { useState, useEffect } from "react";
import {
  useAnalyticsApi,
  useAuthorize,
  useDataChart,
  useSignOut,
  useViewSelector,
} from "react-use-analytics-api";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import Widget from "../Components/Widget/Widget";
import PageTitle from "../Components/PageTitle/PageTitle";
import { Typography } from "../Components/Wrappers/Wrappers";
import Dot from "../Components/Sidebar/components/Dot";
import Table from "./components/Table/Table";
import BigStat from "./components/BigStat/BigStat";
import axios from "axios";
import { useUserDispatch, signExpired, signOut, useUserState } from "../../Context/UserContext";
import { NewsContext } from "../../Context/NewsContext";
import StaticVar from "../../Config/StaticVar";
import { useContext } from "react";

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var userDispatch = useUserDispatch();
  var classes = useStyles();
  var theme = useTheme();
  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };
  const [photo_count,setphoto_count] = useState(0);
  const [useradmin,setuseradmin] = useState(0);
  const [usermember,setusermember] = useState(0);
  const [userall,setuserall] = useState(0);
  const [photo_countedit,setphoto_countedit] = useState(0);
  const [photo_countpublish,setphoto_countpublish] = useState(0);

  const [video_count, setvideo_count] = useState(0)
  const [video_countedit,setvideo_countedit] = useState(0);
  const [video_countpublish,setvideo_countpublish] = useState(0);

  const { getDataNewsCount,getDataNewsCategory,getDataNewsClassification,countNews } = useContext(NewsContext)

  
  var getDataUser = async () => {
    try {
      let res = await axios.get(url + "/private/users", { headers });
      let response = await res.data;
      console.log("response", JSON.stringify(response));

      if (response.hasOwnProperty("status")) {
        console.log("error");
        signExpired(userDispatch, props.history);
      } else {
        setuserall(response.length)
        setusermember(response.filter(item=>item.user_access === "member").length)
        setuseradmin(response.filter(item=>item.user_access !== "member").length)
      }
    } catch (error) {
      signExpired(userDispatch, props.history);
    }
  };

  var getDataPhoto = async() => {
    try{
      let res = await axios.get(url+"/private/photos",{headers});
      let response = await res.data;
      //console.log('response', JSON.stringify(response))

      if(response.hasOwnProperty("photo_status")){
        console.log('error')
        signExpired(userDispatch,props.history);
      }else
      {
        setphoto_count(response.length);
        var dataedit = []
        var datapublish = []
        response.forEach(element=>{
          if(element.photo_status ==="Telah diedit"){
            dataedit.push(element);
          }
        })
        response.forEach(element=>{
          if(element.photo_status ==="Publish"){
            datapublish.push(element);
          }
        })

        setphoto_countedit(dataedit.length);
        setphoto_countpublish(datapublish.length);
        
      }
    }
    
    catch(error){
      signExpired(userDispatch,props.history);
    }
  }


  var getDataVideo = async() => {
    try{
      let res = await axios.get(url+"/private/videos",{headers});
      let response = await res.data;
      //console.log('response', JSON.stringify(response))

      if(response.hasOwnProperty("video_status")){
        console.log('error')
        signExpired(userDispatch,props.history);
      }else
      {
        setvideo_count(response.length);
        var dataedit = []
        var datapublish = []
        response.forEach(element=>{
          if(element.video_status ==="Telah diedit"){
            dataedit.push(element);
          }
        })
        response.forEach(element=>{
          if(element.video_status ==="Publish"){
            datapublish.push(element);
          }
        })

        setvideo_countedit(dataedit.length);
        setvideo_countpublish(datapublish.length);
        
      }
    }
    
    catch(error){
      signExpired(userDispatch,props.history);
    }
  }

  useEffect(()=>{
    const fetchData = async () => {
      await getDataNewsCount()
      await getDataNewsCategory()
      await getDataNewsClassification()
      getDataPhoto();
      getDataVideo();
      getDataUser()
    }
    fetchData()
  },[])

  
  // const { ready, gapi, authorized, error } = useAnalyticsApi();
  // const [viewId, setViewId] = React.useState();
  // const viewSelectorContainerId = "view-selector-container";
  // useViewSelector(
  //   authorized ? gapi : undefined,
  //   viewSelectorContainerId,
  //   (viewId) => setViewId(viewId)
  // );
  // const query = {
  //   ids: viewId,
  //   "start-date": "28daysAgo",
  //   "end-date": "today",
  //   metrics: "ga:sessions",
  //   dimensions: "ga:date",
  // };
  // const chart = {
  //   container: "data-chart-container",
  //   type: "LINE",
  //   options: {
  //     title: "Sessions (28 Days)",
  //   },
  // };
  // useDataChart(authorized ? gapi : undefined, query, chart);

  // // Workaround for API limitation - see useAuthorize docs
  // const authDiv = React.useRef(null);
  // const [authorizeCalled, setAuthorizeCalled] = React.useState(false);
  // const hasAuthElements =
  //   authDiv.current && authDiv?.current?.children?.length > 0;

  // const clientId = '470101665971-um2tqb6cm3muitbc7593rj10ucv2rqem.apps.googleusercontent.com'

  // const authorize = useAuthorize(gapi, {
  //   clientId,
  //   container: "authorize-container-id",
  // });
  // const signOut = useSignOut(gapi);

  // React.useEffect(() => {
  //   if (ready && !error && !authorizeCalled) {
  //     authorize();
  //     setAuthorizeCalled(true);
  //   }
  // }, [ready, error, authorizeCalled, authorize]);

  return <>
    <PageTitle title="Dashboard"/>
    <Grid container spacing={4}>
      <Grid item lg={4} md={12} sm={4} xs={12}>
        <Widget
          title="Konten Berita"
          upperTitle
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
          <div className={classes.visitsNumberContainer}>
            <Typography size="xl" weight="medium">
              {countNews.all}
            </Typography>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Jumlah Konten
              </Typography>
              <Typography size="md">{countNews.all}</Typography>
            </Grid>
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Telah diedit
              </Typography>
              <Typography size="md">{countNews.edited}</Typography>
            </Grid>
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Publish
              </Typography>
              <Typography size="md">{countNews.publish}</Typography>
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      
      <Grid item lg={4} md={12} sm={4} xs={12}>
        <Widget
          title="Foto"
          upperTitle
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
          <div className={classes.visitsNumberContainer}>
            <Typography size="xl" weight="medium">
              {photo_count}
            </Typography>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Jumlah Konten
              </Typography>
              <Typography size="md">{photo_count}</Typography>
            </Grid>
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Telah diedit
              </Typography>
              <Typography size="md">{photo_countedit}</Typography>
            </Grid>
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Publish
              </Typography>
              <Typography size="md">{photo_countpublish}</Typography>
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      
      <Grid item lg={4} md={12} sm={4} xs={12}>
        <Widget
          title="Video"
          upperTitle
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
          <div className={classes.visitsNumberContainer}>
            <Typography size="xl" weight="medium">
              {video_count}
            </Typography>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Jumlah Konten
              </Typography>
              <Typography size="md">{video_count}</Typography>
            </Grid>
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Telah diedit
              </Typography>
              <Typography size="md">{video_countedit}</Typography>
            </Grid>
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Publish
              </Typography>
              <Typography size="md">{video_countpublish}</Typography>
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      
      <Grid item lg={6} md={12} sm={6} xs={12}>
        <Widget
          title="Total User"
          upperTitle
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
          <div className={classes.visitsNumberContainer}>
            <Typography size="xl" weight="medium">
              {userall}
            </Typography>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                User Admin
              </Typography>
              <Typography size="md">{useradmin}</Typography>
            </Grid>
            <Grid item>
              <Typography color="text" colorBrightness="secondary">
                Member
              </Typography>
              <Typography size="md">{usermember}</Typography>
            </Grid>
          </Grid>
        </Widget>
      </Grid>
      <Grid item lg={6} md={12} sm={6} xs={12}>
         
        <Widget
          title="Google Analytics Kabarin News"
          upperTitle
          bodyClass={classes.fullHeightBody}
          className={classes.card}
        >
          <a href="https://analytics.google.com/analytics/web/#/p338236726/reports/intelligenthome" target="_blank">
          <Button variant="contained" sx={{textTransform:'none',width:200}}>
            Tinjau
          </Button>
          </a>
        </Widget>
      </Grid>
      
      {/* <Grid item xs={12} style={{display:'none'}}>
        <Widget
          bodyClass={classes.mainChartBody}
          header={
            <div className={classes.mainChartHeader}>
              <Typography
                variant="h5"
                color="text"
                colorBrightness="secondary"
              >
                Tren Kunjungan Website
              </Typography>
              <div className={classes.mainChartHeaderLabels}>
                <div className={classes.mainChartHeaderLabel}>
                  <Dot color="warning" />
                  <Typography className={classes.mainChartLegentElement}>
                    Tablet
                  </Typography>
                </div>
                <div className={classes.mainChartHeaderLabel}>
                  <Dot color="primary" />
                  <Typography className={classes.mainChartLegentElement}>
                    Mobile
                  </Typography>
                </div>
                <div className={classes.mainChartHeaderLabel}>
                  <Dot color="primary" />
                  <Typography className={classes.mainChartLegentElement}>
                    Desktop
                  </Typography>
                </div>
              </div>
              <Select
                value={mainChartState}
                onChange={e => setMainChartState(e.target.value)}
                input={
                  <OutlinedInput
                    // label={0}
                    classes={{
                      notchedOutline: classes.mainChartSelectRoot,
                      input: classes.mainChartSelect,
                    }}
                  />
                }
                autoWidth
              >
                <MenuItem value="daily">Harian</MenuItem>
                <MenuItem value="weekly">Mingguan</MenuItem>
                <MenuItem value="monthly">Bulanan</MenuItem>
              </Select>
            </div>
          }
        >
          <ResponsiveContainer width="100%" minWidth={500} height={350}>
            <ComposedChart
              margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
              data={mainChartData}
            >
              <YAxis
                ticks={[0, 2500, 5000, 7500]}
                tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                stroke={theme.palette.text.hint + "80"}
                tickLine={false}
              />
              <XAxis
                tickFormatter={i => i + 1}
                tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                stroke={theme.palette.text.hint + "80"}
                tickLine={false}
              />
              <Area
                type="natural"
                dataKey="desktop"
                fill={theme.palette.background.light}
                strokeWidth={0}
                activeDot={false}
              />
              <Line
                type="natural"
                dataKey="mobile"
                stroke={theme.palette.primary.main}
                strokeWidth={2}
                dot={false}
                activeDot={false}
              />
              <Line
                type="linear"
                dataKey="tablet"
                stroke={theme.palette.warning.main}
                strokeWidth={2}
                dot={{
                  stroke: theme.palette.warning.dark,
                  strokeWidth: 2,
                  fill: theme.palette.warning.main,
                }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </Widget>
      </Grid> */}

      {/* <div>
      {!ready && <div>Loading...</div>}
      {ready && (
        <div>
          {authorized && (
            <div>
              <div style={{ marginTop: "30px" }}>
                <div className="data-chart" id="data-chart-container" />
              </div>
              <div id={viewSelectorContainerId} />
              <div>
                <button onClick={() => signOut()}>Sign Out</button>
              </div>
            </div>
          )}
          {!authorized && (
            <div>
              <div ref={authDiv} id="authorize-container-id"></div>
              {!hasAuthElements && <div>🔄 Refresh the page to sign in.</div>}
            </div>
          )}
        </div>
      )}
      {error && <div>{error.toString()}</div>}
    </div> */}
      
    </Grid>
  </>;
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
