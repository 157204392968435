import React, { useState, useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";
import { Editor } from "@tinymce/tinymce-react";
import ReactCrop from "react-image-crop";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
// import { Editor } from "react-draft-wysiwyg";
import NoImg from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";
import { getCroppedImage } from "../../Utils/getCroppedImage";

export default function AddContentSponsor(props) {
  var classes = useStyles();

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");
  const imgRef = useRef(null);
  const imgCropperRef = useRef();

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  var [idSponsor, setIdSponsor] = useState("");
  var [nameSponsor, setNameSponsor] = useState("");
  var [category, setcategory] = useState("");
  var [baseUrl, setbaseUrl] = React.useState("");
  var [position, setposition] = React.useState("");
  var [link, setLink] = React.useState("");
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  var [imgPreview, setImgPreview] = useState(NoImg);
  var [img, setImg] = useState("");
  var [dataPages, setDataPages] = React.useState([]);
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");
  const [crop, setCrop] = useState();

  const getData = async () => {
    let res = await axios.get(url + "/private/sponsor", { headers });
    let response = await res.data;
    setData(response);
    setRows(response);
  };

  function postData() {
    let sendData = new FormData();
    sendData.append("name", nameSponsor);
    sendData.append("status_sponsor", "Tidak Aktif");
    sendData.append("logo", img);
    sendData.append("category", category);
    sendData.append("position", position);
    sendData.append("link", link);
    sendData.append("create_by", localStorage.id);
    sendData.append("base_URL", "/pages/" + baseUrl);

    axios
      .post(url + "/private/sponsor/create", sendData, { headers })
      .then((res) => {
        getData();
        setImgPreview("");
        setImg(null);
      });
  }

  const getDataPages = async () => {
    let res = await axios.get(url + "/private/pages", { headers });
    let response = await res.data;
    setDataPages(response);
  };

  useEffect(() => {
    getData();
    getDataPages();
    return () => {
      getData();
      getDataPages();
    };
  }, []);

  function handleInputFile() {
    // menghubungkan ke button input
    imgRef.current.click();
  }

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    console.log("originalFile instanceof Blob", file instanceof Blob); // true
    console.log("originalFile size", file.size);

    var options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob,
      ); // true
      console.log("compressedFile size ", compressedFile); // smaller than maxSizeMB

      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result);
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  const onCrop = async () => {
    if (imgCropperRef.current && crop?.width && crop?.height) {
      const croppedImage = await getCroppedImage(
        imgCropperRef.current,
        crop,
        img.name,
        img.type,
      );

      setImg(croppedImage);
      setImgPreview(croppedImage.preview);
      setCrop(null);
    }
  };


  return (
    <>
      <PageTitle title="Sponsor Editor" status={"Status : Sponsor Baru"} />
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 10 }}>
          <TextField
            label="Nama Sponsor"
            className={classes.formContainer}
            margin="normal"
            value={nameSponsor}
            onChange={(event) => setNameSponsor(event.target.value)}
            name="name"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <InputLabel htmlFor="contributor-helper">Kategori</InputLabel>
          <Select
            className={classes.formContainer}
            value={category}
            onChange={(event) => {
              setcategory(event.target.value);
            }}
          >
            <MenuItem value={"Laman"}>Laman</MenuItem>
            <MenuItem value={"Redirect"}>Redirect</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <InputLabel htmlFor="contributor-helper">Posisi</InputLabel>
          <Select
            className={classes.formContainer}
            value={position}
            onChange={(event) => {
              setposition(event.target.value);
            }}
          >
            <MenuItem value={"Home"}>Home</MenuItem>
            <MenuItem value={"NewsCenter"}>Center of News</MenuItem>
            <MenuItem value={"Top"}>Top</MenuItem>
            <MenuItem value={"Left"}>Left</MenuItem>
            <MenuItem value={"Right"}>Right</MenuItem>
            <MenuItem value={"Center"}>Center</MenuItem>
            <MenuItem value={"Bottom"}>Bottom</MenuItem>
          </Select>
        </Grid>
        {category === "Laman" ? (
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <InputLabel htmlFor="contributor-helper">Pilih Laman</InputLabel>
            <Select
              className={classes.formContainer}
              value={baseUrl}
              onChange={(event) => {
                setbaseUrl(event.target.value);
              }}
            >
              {dataPages.map((item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>
        ) : null}
        {category === "Redirect" ? (
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <TextField
              label="Base URL atau Link"
              className={classes.formContainer}
              margin="normal"
              value={link}
              onChange={(event) => {
                setLink(event.target.value);
              }}
              name="name"
            />
          </Grid>
        ) : null}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          Upload Image:
          <input
            accept="image/*"
            className={classes.input}
            ref={imgRef}
            multiple
            type="file"
            onChange={(e) => handleImage(e)}
          />
          <Button
            className={classes.btnUpload}
            onClick={handleInputFile}
            sx={{ ml: 1.25 }}
          >
            <Typography style={{ color: "#ffffff" }}>Upload</Typography>
          </Button>
          {!!img && (
            <Box sx={{ mt: 1.25 }}>
              <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                <Box
                  ref={imgCropperRef}
                  component="img"
                  src={imgPreview}
                  alt=""
                  sx={{ width: "100%", height: "auto" }}
                />
              </ReactCrop>
              <Button
                variant="outlined"
                sx={{ mt: 1.5, width: "100%", mb: 2 }}
                onClick={onCrop}
                disabled={!crop?.width && !crop?.width}
              >
                <Typography className={classes.txtAction}>
                  Crop image
                </Typography>
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <div style={{ float: "right" }}>
            <Button
              variant="outlined"
              sx={{
                width: 120,
              }}
              onClick={() => {
                props.history.push("/app/news/content");
              }}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              sx={{
                width: 120,
                marginLeft: 1.25,
              }}
              onClick={() => postData()}
            >
              Simpan
            </Button>
          </div>
        </Grid>
    </>
  );
}
