import { 
  Box, 
  Table, 
  TableCell, 
  TableContainer, 
  TableRow,
  TableHead,
  TableBody 
} from '@mui/material'
import React from 'react'
import DialogModal from '../Components/Dialog/Dialog'
import PageTitle from '../Components/PageTitle/PageTitle'

const Events = () => {
  return (
    <>
    <PageTitle title='Kegiatan' button='Tambah Kegiatan' />
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Kegiatan</TableCell>
              <TableCell>Mulai Tanggal</TableCell>
              <TableCell>Berakhir Tanggal</TableCell>
              <TableCell>Aksi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    {/* <DialogModal /> */}
    </>
  )
}

export default Events