import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  formContainer: {
    margin: 0,
    width: "100%",
    marginBottom: 5,
  },
  dense: {
    marginTop: 19,
  },
  txtContentTable: {
    fontWeight: 200,
    fontSize: 15,
    textAlign: "center",
  },
  searchField: {
    // padding: '0 5px',
    marginRight: 20,
    background: "white",
    width: "100%",
    fontWeight: 400,
    borderRadius: 5,
    borderColor: "white",
    boxShadow: "2.5px 2.5px 2.5px 1.5px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("md")]: {
      borderRadius: 10,
    },
  },
  textSearch: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: "#6B6B6B",
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      padding: "13px 10px",
    },
  },
  txtAction: {
    textTransform: "none",
    fontWeight: 200,
    fontSize: 15,
  },
  btnAction: {
    width: "100%",
    marginBottom: 10,
    border: "2px solid #bf272b",
  },

  chooseUserBtn: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: "#ffffff",
    border: "1px solid #a2aab8",
  },

  chooseUserTxt: {
    textTransform: "none",
    fontSize: 14,
  },

  btnIconDelete: {
    position: "relative",
    right: 10,
  },
  btnActionAdd: {
    width: 120,
    marginRight: 10,
    border: "2px solid #bf272b",
    color: "#bf272b",
    marginBottom: 10,
  },
  input: {
    display: "none",
  },
  editorToolbar: {
    marginTop: 10,
    width: "-webkit-fill-available",
    backgroundColor: "#ffffff",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    border: "1px solid #a8a8a8",
  },
  containerEditor: {
    backgroundColor: "#ffffff",
  },
  btnUpload: {
    backgroundColor: "gray",
    textTransform: "none",
    marginTop: 10,
    marginBottom: 10,
  },
  containerCard: {
    paddingLeft: 10,
    paddingTop: 20,
    paddingRight: 10,
    paddingBottom: 20,
  },
  listUser: {
    backgroundColor: "#dbdbdb",
    padding: 0,
    width: 300,
  },
}));
