import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@mui/material";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  AccountTree as AccountTreeIcon,
  PhotoAlbumOutlined as PhotoAlbumOutlinedIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  LiveHelp as LiveHelpIcon,
  AnnouncementOutlined as AnnouncementOutlinedIcon,
  Pages as PagesIcon,
  BurstMode as BurstModeIcon,
  AccountTreeRounded as OrganizationIcon,
  AnnouncementRounded as AnnouncementRoundedIcon,
  BrandingWatermarkOutlined as BrandingWatermarkOutlinedIcon,
  Person as PersonIcon,
  PanoramaHorizontal as PopupIcon,
  EventNote as EventsIcon,
  Comment as CommentIcon,
  StickyNote2 as StickyNote2Icon,
} from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../../Context/LayoutContext";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Berita",
    link: "/app/news/content",
    icon: <AnnouncementOutlinedIcon />,
    children: [
      { label: "Kategori Berita", link: "/app/news/category" },
      { label: "Klasifikasi Berita", link: "/app/news/classification" },
      { label: "Isi Berita", link: "/app/news/content" },
    ],
  },
  {
    id: 5,
    label: "Galeri",
    link: "/app/gallery/photos",
    icon: <PhotoAlbumOutlinedIcon />,
    children: [
      { label: "Galeri Foto", link: "/app/gallery/photos" },
      { label: "Kategori Foto", link: "/app/gallery/categories-photos" },
      { label: "Galeri Video", link: "/app/gallery/videos" },
      { label: "Kategori Video", link: "/app/gallery/categories-videos" },
    ],
  },
  {
    id: 6,
    label: "Laman",
    link: "/app/pages",
    icon: <PagesIcon />,
  },
  {
    id: 7,
    label: "Peta Situs",
    link: "/app/sitemap",
    icon: <AccountTreeIcon />,
  },
  {
    id: 12,
    label: "Sponsor",
    link: "/app/sponsor",
    icon: <BrandingWatermarkOutlinedIcon />,
  },
  { id: 14, label: "User", icon: <PersonIcon />, link: "/app/users/list" },
  { id: 15, label: "Member", icon: <PersonIcon />, link: "/app/member" },
  { id: 15, label: "Catatan", icon: <StickyNote2Icon />, link: "/app/note" },
  {
    id: 16,
    label: "Komen",
    icon: <CommentIcon />,
    link: "/app/comments",
  },
];

const structure_contributor = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Berita",
    link: "/app/news/content",
    icon: <AnnouncementOutlinedIcon />,
  },
  {
    id: 4,
    label: "Foto",
    link: "/app/gallery/photos",
    icon: <PhotoAlbumOutlinedIcon />,
  },
  {
    id: 5,
    label: "Video",
    link: "/app/gallery/videos",
    icon: <PhotoAlbumOutlinedIcon />,
  },
];

const structure_redaktur = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Berita",
    link: "/app/news",
    icon: <AnnouncementOutlinedIcon />,
    children: [
      { label: "Kategori Berita", link: "/app/news/category" },
      { label: "Isi Berita", link: "/app/news/content" },
    ],
  },
  {
    id: 3,
    label: "Galeri",
    link: "/app/gallery",
    icon: <PhotoAlbumOutlinedIcon />,
    children: [
      { label: "Galeri Foto", link: "/app/gallery/photos" },
      { label: "Kategori Foto", link: "/app/gallery/categories-photos" },
      { label: "Galeri Video", link: "/app/gallery/videos" },
      { label: "Kategori Video", link: "/app/gallery/categories-videos" },
    ],
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  var [datastructure, setdatastructure] = useState([]);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    var user_access = localStorage.getItem("user_access");
    console.log("user_access", user_access);
    setdatastructure(structure);
    if (
      user_access === "editor" ||
      user_access === "kontributor" ||
      user_access === "redaktor"
    ) {
      setdatastructure(structure_contributor);
    }

    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)} size="large">
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {datastructure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
