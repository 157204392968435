import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import { Editor } from "@tinymce/tinymce-react";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
// styles
import useStyles from "./styles";
import PageTitle from "../Components/PageTitle/PageTitle";
import NoImg from "../../Assets/Images/no-image.png";
import Dialog from "../Components/Dialog/Dialog";
import StaticVar from "../../Config/StaticVar";

export default function AddPage(props) {
  var classes = useStyles();

  const url = StaticVar.URL_API;

  let token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  let [name, setname] = useState("");
  let [nameEN, setnameEN] = useState("");
  let [dataParent, setDataParent] = useState([]);
  var [parent, setparent] = useState("");
  var [imgPreview, setImgPreview] = useState(NoImg);
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");
  var [editorState, seteditorState] = useState("");
  var [editorENState, seteditorENState] = useState("");
  var [openAlert, setopenAlert] = useState(false);
  var [fullWidth, setFullWidth] = useState(false);
  var [messageError, setmessageError] = useState("");

  // useEffect(() => {
  //   getDataParent();
  //   return () => {
  //     getDataParent();
  //   };
  // }, []);

  function handleEditorChange(value) {
    seteditorState(value);
    console.log("Content was updated:", editorState);
  }

  function handleEditorENChange(e) {
    var value = e.target.getContent();
    seteditorENState(value);
    console.log("Content was updated:", editorENState);
  }

  const getDataParent = async () => {
    let res = await axios.get(url + "/private/pages", { headers });
    let response = await res.data;

    var dataparent = _.filter(response, function (item) {
      return item.parent == "Tanpa Parent";
    });
    setDataParent(dataparent);
  };

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    var options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);

      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result);
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <PageTitle title="Tambah Laman" />
      <Grid container spacing={3} style={{ paddingLeft: 5, paddingRight: 20 }}>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Box>
            <Typography color="textSecondary">Nama Laman : </Typography>
            <TextField
              className={classes.formContainer}
              margin="normal"
              value={name}
              onChange={(event) => setname(event.target.value)}
            />
          </Box>
        </Grid>
        {/* <Grid item xs={12} style={{ marginBottom: 10 }}>
          <InputLabel htmlFor="contributor-helper">Parent</InputLabel>
          <Select
            className={classes.formContainer}
            value={parent}
            onChange={(event) => {
              setparent(event.target.value);
            }}
          >
            <MenuItem value={"Tanpa Parent"}>Tanpa Parent</MenuItem>
            {dataParent.map((item) => (
              <MenuItem value={item._id}>{item.name}</MenuItem>
            ))}
          </Select>
        </Grid> */}
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <InputLabel htmlFor="contributor-helper">Layar Penuh</InputLabel>
          <Select
            className={classes.formContainer}
            value={fullWidth}
            onChange={(event) => {
              setFullWidth(event.target.value);
            }}
          >
            <MenuItem value={true}>YA, Layar Penuh</MenuItem>
            <MenuItem value={false}>Tidak, layar default biasa</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Typography>Isi Laman</Typography>
          <input
            accept="image/*"
            className={classes.input}
            id="file-editor"
            multiple
            type="file"
            // onChange={e => handleImage(e)}
          />
          <Box className={classes.editorToolbar}>
            <Editor
              // apiKey="2x3gj3d5upqimvljmbb1xbiir8ghe5v1rhuo4jfot8oikxpk <= api key in tinymce free trial in 30 days"
              // initialValue={editorState}
              apiKey="c4rwz40oxbgix44r7hljkoge388lw4b74iyvsyu52k3phyuw"
              value={editorState}
              init={{
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype == "image") {
                    var input = document.getElementById("file-editor");
                    input.click();
                    input.onchange = function () {
                      var reader = new FileReader();
                      var file = input.files[0];
                      var options = {
                        maxSizeMB: 0.4,
                        maxWidthOrHeight: 3000,
                        useWebWorker: true,
                      };
                      imageCompression(file, options).then(function (
                        compressedFile,
                      ) {
                        reader.onload = function (e) {
                          callback(e.target.result, {
                            alt: compressedFile.name,
                          });
                        };
                        reader.readAsDataURL(compressedFile);
                      });
                    };
                  }
                },
                paste_data_images: true,
                images_upload_handler: function (blobInfo, success, failure) {
                  success(
                    "data:" +
                      blobInfo.blob().type +
                      ";base64," +
                      blobInfo.base64(),
                  );
                },
                branding: false,
                height: 500,
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor hr pagebreak",
                  "searchreplace wordcount visualblocks visualchars code fullscreen",
                  "insertdatetime media table paste code help",
                ],
                toolbar:
                  "undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | imageupload image media | bullist numlist outdent indent | code | removeformat help",
              }}
              // onDragDrop={true}
              onEditorChange={(newValue, editor) =>
                handleEditorChange(newValue, editor)
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div style={{ float: "right" }}>
            <Button
              variant="outlined"
              sx={{ mb: 1.5, ml: 1.5 }}
              onClick={() => {
                props.history.push("/app/pages");
              }}
            >
              <Typography className={classes.txtAction}>Batal</Typography>
            </Button>
            <Button
              variant="contained"
              sx={{ mb: 1.5, ml: 1.5 }}
              onClick={() => {
                let sendData = new FormData();
                sendData.append("name", name);
                sendData.append("nameEN", nameEN);
                // sendData.append("parent", parent);
                sendData.append("fullwidth", fullWidth);
                sendData.append("content", editorState);
                sendData.append("contentEN", editorENState);
                sendData.append("page_status", "Laman Baru");
                sendData.append("create_by", localStorage.id);
                if (name === "") {
                  setopenAlert(true);
                  setmessageError("Data Title wajib diisikan");
                }
                // else if (img === "") {
                //   setopenAlert(true);
                //   setmessageError("Data Foto Laman wajib dimasukkan");
                // }
                else {
                  axios({
                    method: "post",
                    url: url + "/private/pages/create",
                    data: sendData,
                    headers: headers,
                    onUploadProgress: function (progressEvent) {
                      var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                      );
                      console.log("test progress upload", percentCompleted);
                    },
                  })
                    .then(() => {
                      props.history.push("/app/pages");
                    })
                    .catch((err) => {
                      setopenAlert(true);
                      setmessageError(err);
                    });
                }
              }}
            >
              <Typography className={classes.txtAction}>Simpan</Typography>
            </Button>
          </div>
        </Grid>
        <Dialog
          open={openAlert}
          close={() => setopenAlert(false)}
          title={"Periksa Data isian Anda ??"}
          content={<Typography>{messageError}</Typography>}
          cancel={() => setopenAlert(false)}
          confirm={() => setopenAlert(false)}
          valueConfirm={"Oke"}
          valueCancel={"Tutup"}
        />
      </Grid>
    </>
  );
}
