import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  DialogTitle,
  Button,
} from "@mui/material";

export default function DialogModal(props) {
  return (
    <>
      {/* modal  */}
      <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{
            paddingBottom: 10,
            paddingTop: 10,
            paddingRight: 24,
            paddingLeft: 24,
          }}
        >
          {props.title}
        </DialogTitle>
        <DialogContent sx={{ mt: 4 }}>{props.content}</DialogContent>
        <DialogActions>
          {props.valueCancel && (
            <Button variant="outlined" onClick={props.cancel}>
              <Typography style={{ textTransform: "none" }}>
                {props.valueCancel === "" ? "Batal" : props.valueCancel}
              </Typography>
            </Button>
          )}
          {props.valueConfirm && (
            <LoadingButton
              variant="contained"
              onClick={props.confirm}
              loading={props.isConfirming}
            >
              <Typography style={{ textTransform: "none" }}>
                {props.valueConfirm}
              </Typography>
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
