import React, { useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import imageCompression from "browser-image-compression";
import ReactCrop from "react-image-crop";

import { Grid, Typography, Button, TextField, Box } from "@mui/material";

import axios from "axios";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";

import StaticVar from "../../Config/StaticVar";
import { getCroppedImage } from "../../Utils/getCroppedImage";

export default function AddPhotos(props) {
  var classes = useStyles();

  const [files, setFiles] = useState([{ title: null }, { category: null }]);
  const [crops, setCrops] = useState([]);
  const imgRefs = useRef([]);

  const url = StaticVar.URL_API; //"http://localhost:301"//"StaticVar.URL_API";
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const category = props.match.params.category;

  function handleInput(type, i, event) {
    const titlePhotos = [...files];
    const captionPhotos = [...files];
    const categoryPhotos = [...files];
    if (type === "title") {
      titlePhotos[i].title = event.target.value;
    } else {
      captionPhotos[i].caption = event.target.value;
    }
    categoryPhotos[i].category = category;
    setFiles(titlePhotos);
    setFiles(categoryPhotos);
    console.log("data target : ", files);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",

    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            category,
          }),
        ),
      );
      setCrops(acceptedFiles.map(() => ({})));
      console.log("value : ", acceptedFiles);
    },
  });

  async function postData() {
    var options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 3000,
      useWebWorker: true,
    };

    files.map(async (file, index) => {
      try {
        const compressedFile = await imageCompression(file, options);
        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob,
        ); // true
        console.log("compressedFile size ", compressedFile); // smaller than maxSizeM
        console.log("data file", file.caption);
        const formData = new FormData();
        formData.append("title", file.title);
        formData.append("picture", compressedFile);
        formData.append("category", file.category);
        formData.append("caption", file.caption);
        formData.append("create_by", localStorage.id);
        formData.append("photo_status", "Foto Baru");
        axios({
          method: "post",
          url: url + "/private/photos/createMany",
          data: formData,
          headers: headers,
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            console.log("test progress upload", percentCompleted);
            console.log("test progress loaded", progressEvent.loaded);
          },
        }).then(() => {
          props.history.push("/app/gallery/photos");
        });
      } catch (error) {
        console.log(error);
      }
    });
  }

  const thumbs = files.map((file, index) => {
    if (file.title !== null && file.category !== null) {
      const onCrop = async () => {
        if (
          imgRefs.current[index] &&
          crops[index]?.width &&
          crops[index]?.height
        ) {
          const croppedImage = await getCroppedImage(
            imgRefs.current[index],
            crops[index],
            file.name,
            file.type,
          );

          setFiles((prev) =>
            prev.map((file) => {
              if (file.name !== croppedImage.name) {
                return file;
              }
              return Object.assign(croppedImage, {
                category: file.category,
                title: file.title,
                caption: file.caption,
              });
            }),
          );

          setCrops((prev) =>
            prev.map((crop, cropIndex) => {
              if (cropIndex === index) return null;
              return crop;
            }),
          );
        }
      };

      const onCropChange = (c) => {
        setCrops((prev) =>
          prev.map((crop, cropIndex) => {
            if (cropIndex === index) return c;
            return crop;
          }),
        );
      };

      return (
        <Grid container key={index} spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <TextField
              className={classes.formContainer}
              onChange={(e) => handleInput("title", index, e)}
              id="standard-full-width"
              label="Judul"
              style={{ width: "100%" }}
              placeholder="Judul Foto atau Gambar"
              fullWidth
              margin="normal"
            />
            <TextField
              className={classes.formContainer}
              onChange={(e) => handleInput("caption", index, e)}
              id="standard-full-width"
              label="Keterangan Foto atau Gambar"
              style={{ width: "100%", height: 50 }}
              placeholder="Keterangan Foto atau Gambar"
              fullWidth
              margin="normal"
              multiline={3}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ReactCrop crop={crops[index]} onChange={onCropChange}>
              <Box
                ref={(element) => {
                  imgRefs.current[index] = element;
                }}
                component="img"
                src={file.preview}
                alt=""
                sx={{ width: "100%", height: "auto" }}
              />
            </ReactCrop>
            <Button
              variant="outlined"
              sx={{ mt: 1.5, width: "100%" }}
              onClick={onCrop}
              disabled={!crops[index]?.width && !crops[index]?.width}
            >
              <Typography className={classes.txtAction}>Crop image</Typography>
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <>
      <PageTitle title="Galeri Foto" />
      <Box>
        {!imgRefs.current.length && (
          <Box
            {...getRootProps()}
            sx={{
              width: "100%",
              border: "2px solid #e0e0e0",
              marginTop: "-1rem",
              borderRadius: 1,
            }}
          >
            <input {...getInputProps()} />
            <Typography
              p={6}
              sx={{
                textAlign: "center",
                cursor: "pointer",
                "&:hover": { background: "#f5f5f5" },
              }}
            >
              Drag 'n' drop Foto atau Gambar dibawah ini atau Klik beberapa Foto
            </Typography>
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {thumbs}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              sx={{
                marginRight: 1.5,
              }}
              onClick={() => {
                props.history.push("/app/gallery/photos");
              }}
            >
              <Typography className={classes.txtAction}>Batal</Typography>
            </Button>
            <Button variant="contained" onClick={() => postData()}>
              <Typography className={classes.txtAction}>Tambah</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
