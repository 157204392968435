import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  InputLabel,
  Avatar,
  Select,
  MenuItem,
  Tab,
  IconButton
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";
import axios from "axios";
import { useUserDispatch, signExpired } from "../../Context/UserContext";
import Dialog from "../Components/Dialog/Dialog";
import NoImg from "../../Assets/Images/no-image.png";
import { InputAdornment } from "@mui/material";
import _ from "lodash";
import StaticVar from "../../Config/StaticVar";

export default function Faq(props) {
  // global
  var userDispatch = useUserDispatch();

  var classes = useStyles();
  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");
  const headers = {
    'Content-Type': 'application/json',
    'x-access-token': token
  };

  const getData = async () => {
    try {
      let res = await axios.get(url + "/private/faq", { headers });
      let response = await res.data;

      var grouped = _.mapValues(_.groupBy(response, 'category'),list => list.map(item => _.omit(item, 'category')));
      let newData = []
      Object.entries(grouped).map(item=>{
        newData = [...newData,
          {...item[1][0],branch:item[1]}
        ]
      })

      if (newData.hasOwnProperty("category")) {
        console.log('error')
        signExpired(userDispatch, props.history);
      } else {
        setRows(newData)
        setData(newData)
      }
    }

    catch (error) {
      // signExpired(userDispatch, props.history);
    }
  }

  const [open, setOpen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [data, setData] = useState([])
  const [action, setaction] = useState("")
  const [title, settitle] = useState([]);
  const [expand, setExpand] = useState(false);
  const [category, setcategory] = useState("");
  const [titleEn, settitleEn] = useState([]);
  const [categoryEn, setcategoryEn] = useState("");
  const [idfaq, setidfaq] = useState("");
  const [img, setImg] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const handleClickOpen = () => {
    setaction("add");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModalDelete(false);
  };

  function handleClickOpenDelete(id, name) {
    setidfaq(id);
    settitle(name);
    setOpenModalDelete(true);
  };

  function handleClickOpenEdit(id) {
    setidfaq(id);
    setOpen(true);
    setaction("edit");
    axios.get(url + "/private/faq/" + id, { headers }).then(res => {
      if (res.data.length > 0) {
        settitle(res.data[0].title)
        setcategory(res.data.category)
      }
    })
  };

  let sendData = {}

  function postData() {
    if (action === "add") {
      axios.post(url + "/faq/register", sendData).then(res => {
        getData();
        setOpen(false);
      })
    }
    if (action === "edit") {
      axios.put(url + "/private/faq/" + idfaq, sendData, { headers }).then(res => {
        getData();
        setOpen(false);
      })
    }

  }

  function deleteData(id) {
    axios.delete(url + "/private/faq/" + id, { headers }).then(res => {
      setOpenModalDelete(false);
      getData();
    })
  }

  /** Table Pagination */
  const [order, setOrder] = React.useState('');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const [search, setSearch] = useState("")

  const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    let listdata = ['title', 'category'].map((x, i) => {
      return data.filter((el) => {
        if (el[x]) {
          return el[x].toString().toLowerCase().indexOf(searchQuery) !== -1;
        }
      });
    });
    var dataset = _.maxBy(listdata, function (o) {
      return o.length;
    });
    setRows(dataset)
    setSearch(searchQuery)
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return <>
    <PageTitle title="FAQ" button="Tambah FAQ" click={handleClickOpen}
      filter={
        <TextField
          variant="outlined"
          onChange={handleSearch}
          value={search}
          className={classes.searchField}
          placeholder="Cari.."
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            classes: { input: classes.textSearch }
          }}
        />
      }
    />

    <Dialog
      open={open}
      close={handleClose}
      title={"Form " + action + " Users"}
      content={
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              label="Judul"
              className={classes.formContainer}
              margin="normal"
              value={title}
              onChange={(event) => settitle(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Judul"
              className={classes.formContainer}
              margin="normal"
              value={title}
              onChange={(event) => settitleEn(event.target.value)}
            />
          </Grid>
        </Grid>
      }

      cancel={handleClose}
      valueCancel={"Batal"}
      confirm={() => postData()}
      valueConfirm={"Simpan"}
    />

    {/* modal delete */}
    <Dialog
      open={openModalDelete}
      close={handleClose}
      title={"Apakah anda yakin menghapus ??"}
      content={
        <Typography>{title}</Typography>
      }
      cancel={handleClose}
      valueCancel={"Tidak"}
      confirm={() => deleteData(idfaq)}
      valueConfirm={"Ya, Hapus"}
    />

    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper>
          <Table style={{width:"100%"}}>
              {
                stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableBody key={index}>
                      <TableRow>
                      <TableCell colSpan={4}>
                        <div>
                          <Grid container justifyContent="space-between">
                            <div>
                              <Grid container>
                                <div style={{ marginRight: 10, paddingRight: 10, borderRight: "2px solid #b3b3b3" }}>
                                  <Typography className={classes.txtTitleTable}>Kategori</Typography>
                                </div>
                                <div>
                                  <Typography className={classes.txtTitle2Table}>ID : {item.category}</Typography>
                                  <Typography className={classes.txtTitle2Table}>EN : {item.categoryEN}</Typography>
                                </div>
                              </Grid>
                            </div>
                            <div>
                            <Grid container>
                            <IconButton
                              variant="contained"
                              style={{ marginBottom: 10,marginRight:10,backgroundColor:"#d9d9d9" }}
                              onClick={() => setExpand(!expand)}
                              size="large">
                              {
                                expand ? 
                                <ExpandMoreIcon/> :
                                <ExpandLessIcon/>
                              }
                            </IconButton>
                            <Button variant="contained" className={classes.btnActionCategory} style={{ marginBottom: 10 }} onClick={() => handleClickOpenEdit(item._id)} >
                              <Typography className={classes.txtActionCategory}>Tambah Kategori</Typography>
                            </Button>
                            </Grid>
                            </div>
                          </Grid>
                        </div>
                      </TableCell>
                      </TableRow>
                      {
                        item.branch.map((val,index)=>(
                        <TableRow>
                          <TableCell>
                            <Typography className={classes.txtContentTable}>{(page * rowsPerPage) + (index + 1)}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.txtContentTable}>ID : {val.title}</Typography>
                            <Typography className={classes.txtContentTable}>EN : {val.titleEN}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className={classes.txtContentTable}>ID : {val.desc}</Typography>
                            <Typography className={classes.txtContentTable}>EN : {val.descEN}</Typography>
                          </TableCell>
                          <TableCell style={{ width: 100 }}>
                            <Button className={classes.btnAction} style={{ marginBottom: 10 }} onClick={() => handleClickOpenEdit(val._id)} ><Typography className={classes.txtAction}>Ubah</Typography></Button>
                            <Button className={classes.btnAction} onClick={() => handleClickOpenDelete(val._id, val.title)} ><Typography className={classes.txtAction}>Hapus</Typography></Button>
                          </TableCell>
                        </TableRow>
                        ))
                      }
                    </TableBody>
                    );
                  })
              }

            
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </Paper>
      </Grid>
    </Grid>
  </>;
}
