import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  InputLabel,
  Avatar,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";
import axios from "axios";
import {
  useUserDispatch,
  signExpired,
  signOut,
  useUserState,
} from "../../Context/UserContext";
import Dialog from "../Components/Dialog/Dialog";
import NoImg from "../../Assets/Images/no-image.png";
import { InputAdornment } from "@mui/material";
import _ from "lodash";
import StaticVar from "../../Config/StaticVar";

export default function Users(props) {
  // global
  var userDispatch = useUserDispatch();

  var classes = useStyles();
  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  var getData = async () => {
    try {
      let res = await axios.get(url + "/private/users", { headers });
      let response = await res.data;
      console.log("response", JSON.stringify(response));

      if (response.hasOwnProperty("status")) {
        console.log("error");
        signExpired(userDispatch, props.history);
      } else {
        setRows(response);
        setData(response);
      }
    } catch (error) {
      signExpired(userDispatch, props.history);
    }
  };

  const [open, setOpen] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [data, setData] = useState([]);
  const [action, setaction] = useState("");
  const [username, setusername] = useState([]);
  var [iduser, setiduser] = useState("");
  var [imgPreview, setImgPreview] = useState(NoImg);
  // var [imgEditPreview, setImgEditPreview ] = useState();
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");

  useEffect(() => {
    getData();
    return () => {
      getData();
    };
  }, []);

  var [form_name, setform_name] = useState("");
  var [form_gender, setform_gender] = useState("");
  var [form_telp, setform_telp] = useState("");
  var [form_address, setform_address] = useState("");
  var [form_photo, setform_photo] = useState("");
  var [form_email, setform_email] = useState("");
  var [form_password, setform_password] = useState("");
  var [form_user_access, setform_user_access] = useState("");

  const handleClickOpen = () => {
    setaction("add");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModalDelete(false);
  };

  function handleClickOpenDelete(id, name) {
    setiduser(id);
    setusername(name);
    setOpenModalDelete(true);
  }

  function handleClickOpenEdit(id) {
    setiduser(id);
    setOpen(true);
    setaction("edit");
    axios.get(url + "/private/users/" + id, { headers }).then((res) => {
      console.log("data", JSON.stringify(res.data));
      setform_name(res.data[0].name);
      setform_gender(res.data[0].gender);
      setform_telp(res.data[0].telp);
      setform_address(res.data[0].address);
      setform_photo(res.data[0].photo);
      setform_email(res.data[0].email);
      setform_user_access(res.data[0].user_access);
      setImgPreview(url + "/repo/" + res.data[0].photo);
    });
  }

  function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    reader.onloadend = () => {
      setIsImgValid(false);
      setImgErrorMsg("");
      setImg(file);
      setImgPreview(reader.result);
    };

    reader.readAsDataURL(file);
  }

  let sendData = new FormData();
  sendData.append("name", form_name);
  sendData.append("gender", form_gender);
  sendData.append("telp", form_telp);
  sendData.append("address", form_address);
  sendData.append("photo", img);
  sendData.append("email", form_email);
  sendData.append("password", form_password);
  sendData.append("user_access", form_user_access);

  let sendDataEdit = new FormData();
  sendDataEdit.append("name", form_name);
  sendDataEdit.append("gender", form_gender);
  sendDataEdit.append("telp", form_telp);
  sendDataEdit.append("address", form_address);
  sendDataEdit.append("photo", img);
  sendDataEdit.append("email", form_email);
  sendDataEdit.append("user_access", form_user_access);

  function postData() {
    if (action === "add") {
      axios.post(url + "/users/register", sendData).then((res) => {
        getData();
        setOpen(false);
      });
    }
    if (action === "edit") {
      axios
        .put(url + "/private/users/" + iduser, sendDataEdit, { headers })
        .then((res) => {
          getData();
          setOpen(false);
        });
    }
  }

  function deleteData(id) {
    axios.delete(url + "/private/users/" + id, { headers }).then((res) => {
      setOpenModalDelete(false);
      getData();
    });
  }

  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    var searchQuery = event.target.value.toString().toLowerCase();
    let listdata = ["name", "email", "user_access", "telp"].map((x, i) => {
      return data.filter((el) => {
        if (el[x]) {
          return el[x].toString().toLowerCase().indexOf(searchQuery) !== -1;
        }
      });
    });
    var dataset = _.maxBy(listdata, function (o) {
      return o.length;
    });
    setRows(dataset);
    setSearch(searchQuery);
  };

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <PageTitle
        title="Users"
        button="Tambah Users"
        click={handleClickOpen}
        filter={
          <TextField
            variant="outlined"
            onChange={handleSearch}
            value={search}
            className={classes.searchField}
            placeholder="Cari.."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              classes: { input: classes.textSearch },
            }}
          />
        }
      />

      <Dialog
        open={open}
        close={handleClose}
        title={"Form " + action + " Users"}
        content={
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <TextField
                label="Nama"
                className={classes.formContainer}
                margin="normal"
                value={form_name}
                onChange={(event) => setform_name(event.target.value)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Jenis Kelamin"
                className={classes.formContainer}
                margin="normal"
                value={form_gender}
                onChange={(event) => setform_gender(event.target.value)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Telp"
                className={classes.formContainer}
                margin="normal"
                value={form_telp}
                onChange={(event) => setform_telp(event.target.value)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                label="Alamat"
                className={classes.formContainer}
                margin="normal"
                value={form_address}
                onChange={(event) => setform_address(event.target.value)}
              />
            </Grid>
            <Grid item sm={12}>
              <InputLabel htmlFor="contributor-helper">Foto</InputLabel>
              <Avatar src={imgPreview} />
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImage(e)}
              />
            </Grid>
            {action === "add" ? (
              <Grid item sm={6}>
                <TextField
                  label="Email"
                  className={classes.formContainer}
                  margin="normal"
                  value={form_email}
                  onChange={(event) => setform_email(event.target.value)}
                />
              </Grid>
            ) : (
              <Grid item sm={12}>
                <TextField
                  label="Email"
                  className={classes.formContainer}
                  margin="normal"
                  value={form_email}
                  onChange={(event) => setform_email(event.target.value)}
                />
              </Grid>
            )}
            {action === "add" ? (
              <Grid item sm={6}>
                <TextField
                  label="Password"
                  className={classes.formContainer}
                  margin="normal"
                  value={form_password}
                  onChange={(event) => setform_password(event.target.value)}
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <InputLabel htmlFor="contributor-helper">User Access</InputLabel>
              <Select
                className={classes.formContainer}
                value={form_user_access}
                onChange={(event) => {
                  setform_user_access(event.target.value);
                }}
                inputProps={{
                  name: "contributor",
                  id: "contributor-helper",
                }}
              >
                <MenuItem value={"kontributor"}>kontributor</MenuItem>
                <MenuItem value={"editor"}>editor</MenuItem>
                <MenuItem value={"redaktor"}>redaktor</MenuItem>
                <MenuItem value={"admin"}>admin</MenuItem>
              </Select>
            </Grid>
          </Grid>
        }
        cancel={handleClose}
        valueCancel={"Batal"}
        confirm={() => postData()}
        valueConfirm={"Simpan"}
      />

      {/* modal delete */}
      <Dialog
        open={openModalDelete}
        close={handleClose}
        title={"Apakah anda yakin menghapus ??"}
        content={<Typography>{username}</Typography>}
        cancel={handleClose}
        valueCancel={"Tidak"}
        confirm={() => deleteData(iduser)}
        valueConfirm={"Ya, Hapus"}
      />

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper sx={{ overflowX: "auto" }}>
            <Table sx={{ minWidth: "max-content" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography className={classes.txtContentTableHead}>
                      No
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.txtContentTableHead}>
                      Foto
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.txtContentTableHead}>
                      Identitas
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.txtContentTableHead}>
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.txtContentTableHead}>
                      User Akses
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.txtContentTableHead}>
                      Aksi
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(
                  rows.filter((item) => item.user_access !== "member"),
                  getSorting(order, orderBy),
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Typography className={classes.txtContentTable}>
                            {page * rowsPerPage + (index + 1)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Avatar src={url + "/repo/" + item.photo} />
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.txtContentTable}>
                            {item.name}
                          </Typography>
                          <Typography className={classes.txtContentTable}>
                            {item.gender}
                          </Typography>
                          <Typography className={classes.txtContentTable}>
                            {item.telp}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.txtContentTable}>
                            {item.email}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={classes.txtContentTable}>
                            {item.user_access}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ width: 200 }}>
                          {/* <Button color="warning" variant="contained" onClick={() => handleClickOpenEdit(item._id)} ><Typography className={classes.txtAction}>Ubah Password</Typography></Button> */}
                          <Button
                            color="warning"
                            variant="contained"
                            onClick={() => handleClickOpenEdit(item._id)}
                          >
                            <Typography className={classes.txtAction}>
                              Edit
                            </Typography>
                          </Button>
                          <Button
                            color="error"
                            variant="contained"
                            sx={{ ml: 1 }}
                            onClick={() =>
                              handleClickOpenDelete(item._id, item.name)
                            }
                          >
                            <Typography className={classes.txtAction}>
                              Hapus
                            </Typography>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                rows.filter((item) => item.user_access !== "member").length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page",
              }}
              nextIconButtonProps={{
                "aria-label": "next page",
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ overflow: "visible" }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
