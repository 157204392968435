import React, { useState, useEffect, useRef } from "react";
import imageCompression from 'browser-image-compression';

import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TablePagination,
} from "@mui/material";

import axios from "axios";

// styles
import useStyles from "./styles";

import PageTitle from "../Components/PageTitle/PageTitle";

import Dialog from "../Components/Dialog/Dialog";
import NoImg from "../../Assets/Images/no-image.png";

import _ from "lodash";
import StaticVar from "../../Config/StaticVar";

export default function Highlight(props) {
  var classes = useStyles();

  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  var [id, setId] = useState("");
  var [title, setTitle] = useState("");
  var [titleEN, setTitleEN] = useState("");
  var [link, setLink] = useState("");
  var [content, setContent] = useState("");
  var [contentEN, setContentEN] = useState("");

  const [data, setData] = useState([]);
  var [openAlert, setopenAlert] = useState(false);
  var [titleAlert, setTitleAlert] = useState(false);
  var [messageError, setmessageError] = useState("");

  var [imgPreview, setImgPreview] = useState(NoImg);
  // var [imgEditPreview, setImgEditPreview ] = useState();
  var [img, setImg] = useState("");
  var [isImgValid, setIsImgValid] = useState("");
  var [imgErrorMsg, setImgErrorMsg] = useState("");

  var [index, setIndex] = useState(0);

  useEffect(() => {
    getData();
    return () => {
      getData();
    };
  }, []);

  const url = StaticVar.URL_API;
  const token = localStorage.getItem("token");

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": token,
  };

  const getData = async () => {
    let res = await axios.get(url + "/private/highlight", { headers });
    let response = await res.data;
    console.log('data response', response)
    setRows(response);
    setData(response);

  };

  const handleClickOpenAdd = () => {
    setId("");
    setTitle("");
    setTitleEN("");
    setContent("");
    setContentEN("");
    setImg("");
    setImgPreview(NoImg);
    setOpenModalAdd(true);
  };

  function handleClickOpenEdit(id, title, titleEN, content, contentEN, link, picture) {

    setId(id);
    setTitle(title);
    setTitleEN(titleEN);
    setContent(content);
    setContentEN(contentEN);
    setLink(link);
    setImgPreview(url + "/repo/" + picture);
    setOpenModalEdit(true);
  };

  const handleClose = () => {
    setOpenModalDelete(false);
    setOpenModalAdd(false);
    setOpenModalEdit(false);
  };

  const imgRef = useRef(null);

  function handleInputFile() {
    imgRef.current.click();
  }

  async function handleImage(e) {
    let reader = new FileReader();
    let file = e.target.files[0],
      pattern = /image-*/;

    if (!file.type.match(pattern)) {
      setIsImgValid(true);
      setImgErrorMsg("Format File tidak sesuai");
      return;
    }

    var options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 3000,
      useWebWorker: true
    }

    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log('compressedFile size ',compressedFile); // smaller than maxSizeMB
   
      reader.onloadend = () => {
        setIsImgValid(false);
        setImgErrorMsg("");
        setImg(compressedFile);
        setImgPreview(reader.result)
      };
  
      reader.readAsDataURL(compressedFile);

    } catch (error) {
      console.log(error);
    }
  }

  let sendData = new FormData();
  sendData.append('title', title);
  sendData.append('titleEN', titleEN);
  sendData.append('content', content);
  sendData.append('contentEN', contentEN);
  sendData.append('picture', img);
  sendData.append('link', link);
  sendData.append('create_by', localStorage.id);
  sendData.append('approved', []);
  sendData.append('status', "Baru");

  function postData() {
    if (title === "") {
      setopenAlert(true)
      setTitleAlert("Periksa Data isian Anda !!");
      setmessageError("Data Title wajib diisikan")
    }
    else if (img === "") {
      setopenAlert(true);
      setTitleAlert("Periksa Data isian Anda !!");
      setmessageError("Data Image Foto wajib dimasukkan")
    }
    else {
      axios.post(url + "/private/highlight/create", sendData, { headers }).then(res => {
        setOpenModalAdd(false);
        getData();
      })
    }
  }
  //for reload page javascript  window.location.reload();

  function editData(id) {
    let editData = new FormData();
    editData.append("title", title);
    editData.append("titleEN", titleEN);
    editData.append("content", content);
    editData.append("contentEN", contentEN);
    editData.append("picture", img);
    editData.append('link', link);
    editData.append("create_by", localStorage.id);
    editData.append("approved", []);
    editData.append("status", "Telah diedit");

    axios.put(url + "/private/highlight/" + id, editData, { headers }).then((res) => {
      console.log('res', res.data)
      setOpenModalEdit(false);
      getData();
    });
  }

  function editDataForPublish(id) {

    const status = _.filter(data,['status','Publish']);
    
    if (status.length >= 3) {
      setopenAlert(true)
      setTitleAlert("Periksa Jumlah Data Yang Sudah di Publish !!");
      setmessageError("Maksimal 3 Data yang di Publish")
    }
    else {
      let dataPublish = new FormData();
      dataPublish.append("title", title);
      dataPublish.append("titleEN", titleEN);
      dataPublish.append("content", content);
      dataPublish.append("contentEN", contentEN);
      dataPublish.append("picture", img);
      dataPublish.append('link', link);
      dataPublish.append("create_by", localStorage.id);
      dataPublish.append("approved", []);
      dataPublish.append("status", "Publish");

      axios.put(url + "/private/highlight/" + id, dataPublish, { headers }).then(() => {
        setOpenModalEdit(false);
        getData();
      });
    }
  }

  function deleteData(id) {
    axios.delete(url + "/private/highlight/" + id, { headers }).then(res => {
      setOpenModalEdit(false);
      getData();
    });
  }

  function maxInputContent(e){
    var valueInput = e.target.value;
    if(valueInput.length >= 160){
      setopenAlert(true);
      setTitleAlert('Periksa isi Judul!!');
      setmessageError('Jumlah Maksimum 160 karakter')
    }
    else{
      setContent(e.target.value)
    }
  }

  function maxInputTitle(e){
    var valueInput = e.target.value;
    if(valueInput.length >= 70){
      setopenAlert(true);
      setTitleAlert('Periksa isi konten!!');
      setmessageError('Jumlah Maksimum 70 karakter')
    }
    else{
      setTitle(e.target.value)
    }
  }

  /** Table Pagination */
  const [order, setOrder] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useState([]);

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return <>
    <PageTitle
      title="Highlight"
      button="Tambah Highlight"
      click={handleClickOpenAdd}
    />

    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{width:'2%'}}>No</TableCell>
                <TableCell style={{width:'10%'}}>Gambar</TableCell>
                <TableCell style={{width:'50%'}}>Judul</TableCell>
                <TableCell style={{width:'20%'}}>Kategori</TableCell>
                {/* <TableCell style={{width:'10%'}}>Link</TableCell> */}
                <TableCell style={{width:'10%'}}>Status</TableCell>
                <TableCell style={{width:'5%'}}>Aksi</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <Typography>
                        {page * rowsPerPage + (index + 1)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <img
                        src={url + "/repo/" + item.picture}
                        style={{ width: 80, height: 75 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography>
                        Judul : {item.title}
                      </Typography>
                      <Typography>
                        Title : {item.titleEN}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        Indonesia : {item.content}
                      </Typography>
                      <Typography>
                        English : {item.contentEN}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Typography>
                        {item.link}
                      </Typography>
                    </TableCell> */}
                    <TableCell>
                      <Typography>
                        {item.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.btnAction}
                        onClick={() =>
                          handleClickOpenEdit(
                            item._id,
                            item.title,
                            item.titleEN,
                            item.content,
                            item.contentEN,
                            item.link,
                            item.picture,
                          )
                        }
                      >
                        <Typography className={classes.txtAction}>
                          Detail
                        </Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>

    {/* <==========================================================================================================================================> */}

    {/* modal add */}
    <Dialog
      open={openModalAdd}
      close={handleClose}
      title={"Form Tambah"}
      valueCancel="Batalkan"
      content={
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Judul"
              style={{width:'100%'}}
              margin="normal"
              value={title}
              onChange={e =>{
                if(e.target.value.length >= 160){
                  setopenAlert(true);
                  setTitleAlert('Periksa isi Konten!!');
                  setmessageError('Jumlah Maksimum 160 karakter')
                }
                else{
                  setTitle(e.target.value)
                }
              }}
              name="title"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Title"
              style={{width:'100%'}}
              margin="normal"
              value={titleEN}
              onChange={e =>{
                if(e.target.value.length >= 160){
                  setopenAlert(true);
                  setTitleAlert('Periksa isi Konten!!');
                  setmessageError('Jumlah Maksimum 160 karakter')
                }
                else{
                  setTitleEN(e.target.value)
                }
              }}
              name="title"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              InputProps={{
                id: 'announcementText',
                multiline: true,
                rows: 4,
              }}
              label="Konten"
              style={{width:'100%'}}
              margin="normal"
              value={content}
              onChange={e =>{
                if(e.target.value.length >= 160){
                  setopenAlert(true);
                  setTitleAlert('Periksa isi Konten!!');
                  setmessageError('Jumlah Maksimum 160 karakter')
                }
                else{
                  setContent(e.target.value)
                }
              }}
              name="content"
              // inputProps={{ maxLength: 10 }}
              // onInput = {}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              InputProps={{
                id: 'announcementText',
                multiline: true,
                rows: 4,
              }}
              label="Content"
              style={{width:'100%'}}
              margin="normal"
              value={contentEN}
              onChange={e =>{
                if(e.target.value.length >= 160){
                  setopenAlert(true);
                  setTitleAlert('Periksa isi Konten!!');
                  setmessageError('Jumlah Maksimum 160 karakter')
                }
                else{
                  setContentEN(e.target.value)
                }
              }}
              name="content"
              // inputProps={{ maxLength: 10 }}
              // onInput = {}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              label="Link"
              style={{width:'100%'}}
              margin="normal"
              value={link}
              onChange={event => setLink(event.target.value)}
              name="content"
            />
          </Grid>
          {/* =======  */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            Upload Image :
            <input
              accept="image/*"
              className={classes.input}
              ref={imgRef}
              multiple
              type="file"
              onChange={e => handleImage(e)}
            />
            <Button className={classes.btnUpload} onClick={handleInputFile}>
              <Typography style={{ color: '#ffffff' }}>Upload</Typography>
            </Button>
            <img
              alt="img"
              src={imgPreview}
              width="96px"
              height="86px"
              style={{ marginLeft: 20 }}
            />
            {img.name}
          </Grid>
        </Grid>
      }
      cancel={handleClose}
      confirm={() => postData()}
      valueConfirm={"Tambah"}
    />

    {/* modal edit */}
    <Dialog
      open={openModalEdit}
      close={handleClose}
      title={"Form Edit"}
      content={
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Judul"
              style={{width:'100%'}}
              margin="normal"
              value={title}
              onChange={event => setTitle(event.target.value)}
              name="name"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              label="Title"
              style={{width:'100%'}}
              margin="normal"
              value={titleEN}
              onChange={event => setTitleEN(event.target.value)}
              name="name"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              InputProps={{
                id: 'announcementText',
                multiline: true,
                rows: 5,
              }}
              style={{width:'100%'}}
              label="Konten"
              margin="normal"
              value={content}
              onChange={event => setContent(event.target.value)}
              name="content"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              InputProps={{
                id: 'announcementText',
                multiline: true,
                rows: 5,
              }}
              style={{width:'100%'}}
              label="Content"
              margin="normal"
              value={contentEN}
              onChange={event => setContentEN(event.target.value)}
              name="content"
            />
          </Grid>
          <Grid item lg={12}>
            <TextField
              label="Link"
              style={{width:'100%'}}
              margin="normal"
              value={link}
              onChange={event => setLink(event.target.value)}
              name="link"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <img
              alt="img"
              src={imgPreview}
              width="96px"
              height="86px"
              style={{ marginLeft: 20 }}
            />
            {img.name}
            <br/>
            Upload Image :
            <input
              accept="image/*"
              className={classes.input}
              ref={imgRef}
              multiple
              type="file"
              onChange={e => handleImage(e)}
            />
            <Button className={classes.btnUpload} onClick={handleInputFile}>
              <Typography style={{ color: '#ffffff' }}>Upload</Typography>
            </Button>
            
          </Grid>
          <Grid item lg={12}>
              <Button onClick={() => editData(id)} style={{ border: '2px solid #78f056', marginRight: 10 }}>
                <Typography style={{ textTransform: 'none' }}>Simpan</Typography>
              </Button>
              <Button onClick={() => editDataForPublish(id)} style={{ border: '2px solid #78f056', marginRight: 10 }}>
                <Typography style={{ textTransform: 'none' }}>Publish</Typography>
              </Button>
              <Button onClick={handleClose} style={{ border: '2px solid #6f9ae8', marginRight: 10 }}>
                <Typography style={{ textTransform: 'none' }}>Batalkan</Typography>
              </Button>
              <Button onClick={() => deleteData(id)} style={{ border: '2px solid #de1200', marginRight: 10 }}>
                <Typography style={{ textTransform: 'none' }}>Hapus</Typography>
              </Button>
              
          </Grid>
        </Grid>
      }
    />

    <Dialog
      open={openAlert}
      close={() => setopenAlert(false)}
      title={titleAlert}
      content={
        <Typography>{messageError}</Typography>
      }
      cancel={() => setopenAlert(false)}
      confirm={() => setopenAlert(false)}
      valueConfirm={"Oke"}
      valueCancel={"Tutup"}
    />
  </>;
}
